import React, { useEffect, useRef, useState } from "react";
import { IoCloudUpload, IoDownload, IoEye } from "react-icons/io5";
import apiendpoints from "../../apiendpoints.json";
import { toast, ToastContainer } from "react-toastify";
import Remarks from "../Remarks";
import PDFViewver from "../PDFViewer";
import { fileTypeVaidation } from "../../service/fileTypeValidation";

const KycApproval = () => {
  const fileRef = useRef(null);
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remarkScreen, setremarkScreen] = useState(false);
  const [remarkData, setRemarkData] = useState("");
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [signedAgreement, setsignedAgreement] = useState("");
  // const itemsPerPage = 10;
  const excelDownloadRef = useRef(null);
  const downloadSAgreementRef = useRef(null);
  const downloadAgreementRef = useRef(null);
  const handleClick = () => {
    if (downloadAgreementRef.current) {
      downloadAgreementRef.current.click();
    }
  };
  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await fetch(
          `${apiendpoints.equityUrl}/api/KYCGet/-1?_kycstatus=1`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              entityId: "equity",
            },
          }
        );

        if (!response.ok) {
          toast.error("Failed to fetch KYCs");
        }

        const data = await response.json();
        //console.log("kyc:", data);
        setEnquiries(data);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchEnquiries();
  }, []);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(enquiries.length / itemsPerPage);

  const filteredEnquiries = enquiries.filter((kyc) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (kyc.emailId?.toLowerCase().includes(searchLower) ?? false) ||
      (kyc.firstName?.toLowerCase().includes(searchLower) ?? false)
    );
  });

  const currentEnquiries = filteredEnquiries.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleApprove = async (id, email, remarks) => {
    // console.log(email);
    try {
      const response = await fetch(
        `${apiendpoints.equityUrl}/api/ApproveKycDate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            action: "approve",
            userId: id,
          },
          body: JSON.stringify({ toEmail: email, id: id, _remarks: remarks }),
        }
      );
      if (!response.ok) {
        toast.error("Failed to Approve KYC");
      } else {
        toast.success("Success");
      }
      setEnquiries((prev) => prev.filter((enq) => enq.id !== id));
    } catch (error) {}
  };
  const handleExcelDownload = async () => {
    if (excelDownloadRef.current) {
      excelDownloadRef.current.click();
    }
  };

  function formatDate(date) {
    if (!date) return ""; // Return empty string if date is null or undefined

    const d = new Date(date); // Create a Date object from the input
    let year = d.getFullYear(); // Get full year
    let month = (d.getMonth() + 1).toString().padStart(2, "0"); // Get month and pad it to 2 digits
    let day = d.getDate().toString().padStart(2, "0"); // Get day and pad it to 2 digits

    return `${year}-${month}-${day}`; // Return formatted string
  }
  const docWindow = (path) => {
    let url = new URL(path, `${apiendpoints.equityUrl}`);
    window.open(
      url,
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };
  const downloadFile = (path, ref) => {
    try {
      let url = new URL(path, apiendpoints.equityUrl);
      ref.current.href = url.href;
      ref.current.click();
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  const handleReject = async (id, email, remarks) => {
    try {
      const response = await fetch(
        `${apiendpoints.equityUrl}/api/ApproveKycDate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            action: "reject",
            userId: id,
          },
          body: JSON.stringify({ toEmail: email, id: id, _remarks: remarks }),
        }
      );
      if (!response.ok) {
        toast.error("Failed To Reject");
      } else {
        toast.success("Successfully Rejected");
      }
      setEnquiries((prev) => prev.filter((enq) => enq.id !== id));
    } catch (error) {}
  };
  const handleAction = async (opt, data) => {
    setremarkScreen(true);
    setRemarkData({ opt, ...data });

    // console.log(remarkData);
  };
  const fetchfilteredKyc = async (e) => {
    setLoading(true);
    let val = e.target.value;
    try {
      const response = await fetch(
        `${apiendpoints.equityUrl}/api/KYCGet/-1?_kycstatus=${val}`,
        {
          headers: {
            "Content-Type": "application/json",
            entityId: "equity",
          },
        }
      );
      if (!response.ok) {
        toast.error("Failed to fetch KYCs");
      }

      const data = await response.json();
      setEnquiries(data);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const uploadSignedAgreement = async (e, id) => {
    let agree = await fileTypeVaidation(e, "pdf");
    let response = await fetch(`${apiendpoints.equityUrl}/api/KYCUpdate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        entityId: "equity",
      },
      body: JSON.stringify({ signedKYCAgreementPath: agree, id: id }),
    });
    if (!response.ok) {
      toast.error("Could Not Upload ");
    }
    setsignedAgreement(response.signedAgreementPath);
  };
  return (
    <>
      <h4 className=" mt-2 text-center font-bold">KYC Pending for Approval</h4>
      {!!remarkScreen && (
        <section className="w-full min-h-screen bg-black/50 absolute left-0 top-0 grid place-items-center  overflow-hidden z-30">
          <Remarks
            setremarkScreen={setremarkScreen}
            approve={handleApprove}
            reject={handleReject}
            data={remarkData}
            approvemessage={"KYC Approved"}
            rejectmessage={"KYC Rejected"}
          />
        </section>
      )}
      <div className="h-[70vh] xl:h-[80vh] text-sm  overflow-scroll scollbar p-2 rounded-md mt-5 relative  ">
        <div className="my-1">
          <span>Filter</span>
          <select
            onChange={fetchfilteredKyc}
            className="px-3 py-1 mx-1 border rounded"
          >
            <option value={1}>Pending</option>
            <option value={2}>Approved</option>
            <option value={3}>Rejected</option>
            <option value={4}>All</option>
          </select>
        </div>

        <table className="min-w-full   bg-white border border-gray-200  ">
          <thead className="bg-gray-100 text-md uppercase font-semibold text-gray-600">
            <tr>
              <th className="py-3 px-4 border border-gray-200">User ID</th>
              <th className="py-3 px-4 border border-gray-200">Date</th>
              <th className="py-3 px-4 border border-gray-200">Name</th>
              <th className="py-3 px-4 border border-gray-200">Mobile</th>
              <th className="py-3 px-4 border border-gray-200">Email</th>
              <th className="py-3 px-4 border border-gray-200">Pan</th>
              <th className="py-3 px-4 border border-gray-200">Aadhaar</th>
              <th className="py-3 px-4 border border-gray-200">Address</th>

              <th className="py-3 px-4 border border-gray-200">
                Download Agreement
              </th>
              <th className="py-3 px-4 border border-gray-200">
                Upload signed Agreement
              </th>

              <th className="py-3 px-4 border border-gray-200">Action</th>
            </tr>
          </thead>
          <tbody className="text-xs text-gray-700 ">
            {!!loading && <div className="loader "></div>}
            {currentEnquiries.map((kyc) => (
              <>
                <tr key={kyc.id} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border border-gray-200 h-max">
                    {kyc.id}
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max">
                    {formatDate(kyc.submitdDate)}
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max text-wrap whitespace-break-spaces break-words">
                    {kyc.firstName + kyc.middleName + kyc.lastName}
                  </td>

                  <td className="py-2 px-4 border border-gray-200 h-max">
                    {kyc.mobileNumber}
                  </td>
                  <td className="py-2 px-4 border border-gray-200  h-max">
                    {kyc.emailId}
                  </td>

                  <td className="py-2 px-4 border border-gray-200 h-max ">
                    <p className="flex flex-row  items-center gap-2">
                      {kyc.panno}

                      <span
                        className="hover:text-primary-light cursor-pointer text-lg"
                        onClick={() => docWindow(kyc.panpath)}
                      >
                        <IoEye />
                      </span>
                    </p>
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max ">
                    <p className="flex flex-row  items-center gap-2">
                      {kyc.aadharId}

                      <span
                        className="hover:text-primary-light cursor-pointer text-lg"
                        onClick={() => docWindow(kyc.aadharPath)}
                      >
                        <IoEye />
                      </span>
                    </p>
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max ">
                    <p className="flex flex-row  items-center gap-2">
                      {kyc.address}

                      <span
                        className="hover:text-primary-light cursor-pointer text-lg"
                        onClick={() => docWindow(kyc.addressprof)}
                      >
                        <IoEye />
                      </span>
                    </p>
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max ">
                    <div className="flex flex-row  items-center gap-2">
                      <a
                        hidden
                        target="_blank"
                        download={`${kyc.firstName + kyc.middleName + kyc.lastName}-agreement`}
                        ref={downloadAgreementRef} // Reference to the anchor element
                      ></a>
                      <button
                        onClick={() =>
                          downloadFile(kyc.agreementPath, downloadAgreementRef)
                        }
                      >
                        <abbr title="Download Service Agreement">Download</abbr>
                      </button>

                      <span
                        className="hover:text-primary-light cursor-pointer text-lg"
                        onClick={() => docWindow(kyc.agreementPath)}
                      >
                        <IoEye />
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-row  items-center gap-2">
                      <button onClick={() => fileRef.current.click()}>
                        <abbr title="Upload Signed Service Agreement">
                          Upload
                        </abbr>
                      </button>
                      <a
                        href={kyc.signedAgreementPath}
                        download={`${
                          kyc.firstName + kyc.middleName + kyc.lastName
                        }-signed-agreement`}
                        hidden
                        ref={downloadSAgreementRef}
                        target="_blank"
                      ></a>
                      <button
                        onClick={() =>
                          downloadFile(
                            kyc.signedAgreementPath,
                            downloadSAgreementRef
                          )
                        }
                      >
                        <abbr title="Download Service Agreement">Download</abbr>
                      </button>

                      <input
                        type="file"
                        onChange={(e) => {
                          uploadSignedAgreement(e, kyc.id);
                        }}
                        ref={fileRef}
                        required
                        hidden
                      />
                      <span
                        className="hover:text-primary-light cursor-pointer text-lg"
                        onClick={() =>
                          docWindow(kyc.signedAgreementPath || signedAgreement)
                        }
                      >
                        <IoEye />
                      </span>
                    </div>
                  </td>
                  <td className=" border border-gray-200 h-max">
                    <select
                      className="w-max h-full"
                      onChange={(e) => {
                        handleAction(e.target.value, kyc);
                      }}
                    >
                      <option value="0">In-progress</option>
                      <option value="2">Reject</option>
                      <option value="1">Approve</option>
                    </select>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`px-3 py-1 mx-1 border rounded ${
            currentPage === 1 ? "bg-gray-200 cursor-not-allowed" : "bg-white"
          }`}
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 mx-1 border rounded ${
            currentPage === totalPages
              ? "bg-gray-200 cursor-not-allowed"
              : "bg-white"
          }`}
        >
          Next
        </button>
        <select onChange={(e) => setitemsPerPage(Number(e.target.value))}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
        <ToastContainer   position="top-right"
      autoClose={3000}
      limit={2}
      hideProgressBar={true}
      newestOnTop={true} />
      </div>
    </>
  );
};

export default KycApproval;
