import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import apiendpoints from "../../apiendpoints.json";

const Customer = () => {
  const { id } = useParams();
  const [customer, setCustomer] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    //console.log(id);
    setloading(true);
    const fetchCustomer = async () => {
      try {
        const response = await fetch(
          `${apiendpoints.equityUrl}/api/GetCustomerDetail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ kyc_Id: id }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to Fetch Customer");
        }

        const data = await response.json();
        // console.log("customer", data[0]._lstPurchaseDetails);

        setCustomer(data);
        setloading(false);
      } catch (error) {
        if (error instanceof Error) {
          console.log(error);
        } else {
          toast.error("error");
        }
      }
    };
    setloading(false);
    fetchCustomer();
  }, []);
  function formatDate(date) {
    if (!date) return ""; // Return empty string if date is null or undefined

    const d = new Date(date); // Create a Date object from the input
    let year = d.getFullYear(); // Get full year
    let month = (d.getMonth() + 1).toString().padStart(2, "0"); // Get month and pad it to 2 digits
    let day = d.getDate().toString().padStart(2, "0"); // Get day and pad it to 2 digits

    return `${year}-${month}-${day}`; // Return formatted string
  }
  return (
    <section className="w-full border mt-10 max-w-7xl mx-auto rounded">
           <header className="flex gap-10 items-center justify-start  w-full border border-b-2 p-2  ">

        <span
          className="text-black hover:scale-110 cursor-pointer w-max  "
          onClick={() => navigate("/equity-admin-panel/customers")}
        >
          <IoArrowBack size={30} />
        </span>
        <h4 className="underline">Customer Details</h4>
      </header>
      <div className="p-6 overflow-scroll">
        <div className="flex flex-wrap items-center gap-5 ">
          <p>
            Name:{" "}
            {customer[0]?.firstName +
              " " +
              customer[0]?.middleName +
              " " +
              customer[0]?.lastName}
          </p>
          <p>Aadhaar: {customer[0]?.aadharId}</p>
          <p>Pan No: {customer[0]?.panno}</p>
          <p>Mobile: {customer[0]?.mobileNumber}</p>
          <p>KYC: {customer[0]?.kycId}</p>
        </div>
        <table className="border text-sm">
          <thead className="font-semibold bg-gray-100 ">
            <th>S No.</th>
            <th>Id</th>
            <th>Service</th>
            <th>Duration</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Price</th>
          </thead>
          <tbody>
            {loading && <div className="loader "></div>}
            {!loading &&
              customer[0]?._lstPurchaseDetails.map((service, i) => {
                return (
                  <tr key={i} className="text-black">
                    <td>{i + 1}</td>
                    <td>{service.id}</td>
                    <td>{service.service}</td>
                    <td>{service.service_duration}</td>
                    <td>{formatDate(service.serviceStartDate)}</td>
                    <td>{formatDate(service.serviceEndDate)}</td>
                    <td>{service.service_price}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Customer;
