import React from "react";
import { motion } from "framer-motion";
import invest from "../../assets/invest.jpg";
import expert from "../../assets/expert.jpg";
import ethics from "../../assets/ethics.jpg";

const WhyChooseUS = () => {
  return (
    <div className="w-full min-h-[90vh] my-10 p-6 bg-gradient-to-b from-[#01295c] via-slate-900 to-[#000000] text-white">
      <h1 className="text-3xl font-bold sm:text-5xl text-center text-slate-100 mb-10 mt-6">
      <span className="text-primary">Why </span> Choose{" "}
        <span className="underline underline-offset-8 decoration-green-500">
          US
        </span>
      </h1>

      <div className="flex flex-wrap justify-between gap-8 lg:gap-16">
        <div className="lg:w-[30%] flex flex-col items-center text-center">
          <motion.img
            src={expert}
            className="w-full max-w-[250px] aspect-square object-cover rounded-full"
            initital={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            loading="lazy"
            viewport={{ once: true }}
          />
          <motion.div
            className="mt-5"
            initial={{ y: "100%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
            viewport={{ once: true }}
          >
            <h2 className="text-2xl font-semibold">Expert-Driven Research</h2>
            <p className="mt-3 text-lg">
              Our research report are backed by extensive research.
              
            </p>
          </motion.div>
        </div>

        <div className="lg:w-[30%] flex flex-col items-center text-center">
          <motion.img
            src={invest}
            className="w-full max-w-[250px] aspect-square object-cover rounded-full"
            initital={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            loading="lazy"
          />
          <motion.div
            className="mt-5"
            initial={{ y: "100%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
            viewport={{ once: true }}
          >
            <h2 className="text-2xl font-semibold">
              Tailored Investment Solutions
            </h2>
            <p className="mt-3 text-lg">
              We offer personalized services that cater to both new and
              experienced investors.
            </p>
          </motion.div>
        </div>

        <div className="lg:w-[30%] flex flex-col items-center text-center">
          <motion.img
            src={ethics}
            className="w-full max-w-[250px] aspect-square object-cover rounded-full"
            initital={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            loading="lazy"
          />
          <motion.div
            className="mt-5"
            initial={{ y: "100%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
            viewport={{ once: true }}
          >
            <h2 className="text-2xl font-semibold">
              Ethical and Transparent Practices
            </h2>
            <p className="mt-3 text-lg">
              We adhere to the highest standards of ethics and transparency.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUS;
