import React from "react";
import RefundPolicy from "../components/Policy Pages/RefundPolicy";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Warning from "../components/Warning";

function RefundPolicyPage() {
  return (
    <>
     <section className="hidden lg:block">
        <Navbar />
      <Warning/>

      </section>
      <section className="block lg:hidden">
        <Sidebar />
      </section>
      <RefundPolicy />
      <Footer/>
    </>
  );
}

export default RefundPolicyPage;
