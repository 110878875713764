import React, { useState } from "react";
import { IoClose, IoEye } from "react-icons/io5";
import { toast } from "react-toastify";
import apiendpoints from "../apiendpoints.json";
import { Link } from "react-router-dom";

const PurchaseScreen = ({
  setremarkScreen,
  approve,
  reject,
  data,
  approvemessage,
  rejectmessage,
}) => {
  // console.log("remarks ", data);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [remarks, setremarks] = useState("");

  const handleAction = async (opt) => {
    try {
      if (opt == 1) {
        // First API call
        const response1 = await fetch(`${apiendpoints.equityUrl}/api/UpdateService`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            serviceStartDate: startDate,
            serviceEndDate: endDate,
            panno:data.panno,
          }),
        });
  
        if (!response1.ok) {
          throw new Error('Error in updating service'); 
        }
  
        // Second API call
        await approve(data.id, data.email || data.emailId, remarks);
        toast.success(approvemessage);
        setremarkScreen(false);
      }
  
      if (opt == 2) {
        // API call to reject
        await reject(data.id, data.email || data.emailId, remarks);
        toast.success(rejectmessage);
        setremarkScreen(false);
      }
    } catch (error) {
      console.error('Error during API calls:', error);
      toast.error('An error occurred, please try again.'); 
    }
  };
  
  function formatDate(date) {
    if (!date) return ""; // Return empty string if date is null or undefined

    const d = new Date(date); // Create a Date object from the input
    let year = d.getFullYear(); // Get full year
    let month = (d.getMonth() + 1).toString().padStart(2, "0"); // Get month and pad it to 2 digits
    let day = d.getDate().toString().padStart(2, "0"); // Get day and pad it to 2 digits

    return `${year}-${month}-${day}`; // Return formatted string
  }
  const docWindow = (path) => {
    let url = new URL(path, `${apiendpoints.equityUrl}`);
    window.open(
      url,
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };
  const calcExpiryDate = (e) => {
    let sdate = e.target.value;
    setStartDate(sdate);
    let endDate = new Date(sdate);
    endDate.setDate(endDate.getDate() + data.serviceDuration);
    setEndDate(formatDate(endDate));
    // console.log(formatDate(endDate));
  };
  return (
    <div className="mt-5 md:mt-0 p-4  z-30 w-11/12 md:w-4/5 rounded border shrink-0 mx-auto bg-white ">
      <div className="flex w-full justify-between">
        <h4>Add Remarks</h4>
        <span onClick={() => setremarkScreen(false)} className="cursor-pointer">
          <IoClose size={30} />
        </span>
      </div>
      {data.opt == 1 && (
        <div className="flex gap-5 flex-wrap">
          <div className="w-full md:w-auto">
            <label className="block mb-2 text-sm font-semibold text-gray-900">
              Service Name
              <input
                type="text"
                value={data.service}
                // onChange={(e) => setStartDate(e.target.value)}
                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                // placeholder="Start Date"
              />
            </label>
          </div>
          <div className="w-full md:w-auto">
            <label className="block mb-2 text-sm font-semibold text-gray-900">
              Service Duration
              <input
                type="text"
                value={data.serviceDuration}
                // onChange={(e) => setStartDate(e.target.value)}
                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                // placeholder="Start Date"
              />
            </label>
          </div>
          <div className="w-full md:w-auto">
            <label className="block mb-2 text-sm font-semibold text-gray-900">
              Purchase Date
              {/* <input
                  type="date"
                  // value={mname}
                  // onChange={(e) => setmname(e.target.value)}
                  className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                  placeholder="End Date"
                  
                /> */}
              <span className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none">
                {formatDate(data.createdAt)}
              </span>
            </label>
          </div>
          <div className="w-full md:w-auto">
            <label className="block mb-2 text-sm font-semibold text-gray-900">
              Transaction Id
              <input
                type="text"
                value={data.transactionId}
                // onChange={(e) => setEndDate(e.target.value)}
                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                // placeholder="End Date"
              />
            </label>
          </div>
          <div className="w-full md:w-auto">
            <label className="block mb-2 text-sm font-semibold text-gray-900">
              Payment Proof
              {/* <input
                type="text"
                value={data.transactionId}
                // onChange={(e) => setEndDate(e.target.value)}
                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                // placeholder="End Date"
              /> */}
              <span
                className="shadow-sm bg-primary border border-gray-300 text-white cursor-pointer hover:bg-primary-dark text-sm rounded-lg  block w-full p-2.5 mt-1 text-center"
                onClick={() => docWindow(data.paymentProof)}
              >
                View
              </span>
            </label>
          </div>
          <div className="w-full md:w-auto">
            <label className="block mb-2 text-sm font-semibold text-gray-900">
              Pan No.
              <input
                type="text"
                value={data?.panno}
                // onChange={(e) => setStartDate(e.target.value)}
                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                // placeholder="Start Date"
              />
            </label>
          </div>
          <div className="w-full md:w-auto">
            <label className="block mb-2 text-sm font-semibold text-gray-900">
              Start Date
              <input
                type="date"
                value={startDate}
                onChange={calcExpiryDate}
                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                placeholder="Start Date"
              />
            </label>
          </div>
          <div className="w-full md:w-auto">
            <label className="block mb-2 text-sm font-semibold text-gray-900">
              End Date
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                placeholder="End Date"
              />
            </label>
          </div>
        </div>
      )}
      {!data._isKYC && (
        <p className="text-sm text-red-500">
          Customer KYC is pending. Please Complete{" "}
          <Link
            className="font-bold underline"
            to={`/equity-admin-panel/kyc/${data.id}`}
          >
            KYC
          </Link>{" "}
          first.{" "}
        </p>
      )}
      <textarea
        className="bg-slate-100 text-black rounded border w-full h-full overflow-y-scroll p-2"
        value={remarks}
        rows={5}
        onChange={(e) => setremarks(e.target.value)}
      ></textarea>
      <button
        onClick={() => handleAction(data.opt)}
        className={
          // data._isKYC
             "rounded border p-1 text-lg bg-primary text-white"
            // : "rounded border border-red-900 p-1 text-lg bg-red-900 text-white cursor-not-allowed"
        }
        // disabled={!data._isKYC}
      >
        Submit
      </button>
    </div>
  );
};

export default PurchaseScreen;
