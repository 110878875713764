import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./components/AboutUs";
import Packages from "./components/Packages";
import CareersPage from "./pages/CareersPage";
import LegalDisclaimerPage from "./pages/LegalDisclaimerPage";
import RefundPolicyPage from "./pages/RefundPolicyPage";
import TermsServicesPage from "./pages/TermsServicesPage";
import ProcessPage from "./pages/ProcessPage";
import KYCPage from "./pages/KYCPage";
import GrievancePage from "./pages/GrievancePage";
import ServiceAgreementPage from "./pages/ServiceAgreementPage";
import ComplaintsPage from "./pages/ComplaintsPage";
import LoginPage from "./pages/LoginPage";
import AdminPanel from "./components/Admin Pages/AdminPanel";
import PaymentPage from "./pages/PaymentPage";
import Services from "./components/Services";
import ServicesPage from "./pages/ServicesPage";
import { useEffect, useState } from "react";

import { Navigate } from "react-router-dom";
import EnquiryPanel from "./components/Admin Pages/EnquiryPanel";
import AdminUserRegistration from "./components/Admin Pages/AdminUserRegistration";
import Complaints from "./components/Complaints";
import KycApproval from "./components/Admin Pages/KycApproval";
import PurchaseApproval from "./components/Admin Pages/PurchaseApproval";
import Customers from "./components/Admin Pages/Customers";
import ComplaintPanel from "./components/Admin Pages/ComplaintPanel";
import Customer from "./components/Admin Pages/Customer";
import CustomerKYC from "./components/Admin Pages/CustomerKYC";
import { Profiler } from 'react';
// import ProcessHtmlFile from "../service/PDFReader";

const onRenderCallback:any =(
  id:any, // the "id" prop of the Profiler tree that has just committed
  phase:any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration:any, // time spent rendering the committed update
  baseDuration:any, // estimated time to render the entire subtree without memoization
  startTime:any, // when React began rendering this update
  commitTime:any, // when React committed this update
  interactions:any // the Set of interactions belonging to this update
)=> {
  console.log(`${id} rendered in ${actualDuration}ms`);
}
function ProtectedRoute({ children }: { children: JSX.Element }) {
  const [user, setUser] = useState<any | null>(
    JSON.parse(localStorage.getItem("user") as string) || null
  );

  if (!user) {
    // Redirect the user to the login page if they are not authenticated
    // //console.log("user", user);
    return <Navigate to="/equity-login" />;
  }

  // Render the protected component if the user is authenticated
  //console.log("user", user);

  return children;
}

function App() {
  // useEffect(() => {
  //   const storedUser = localStorage.getItem("user");
  //   //console.log(storedUser);

  // }, []);

  return (
        // <Profiler id="MyComponent" onRender={onRenderCallback}>
    
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/equity-process" element={<ContactUs />} />
        <Route path="/equity-aboutus" element={<AboutUs />} />

        <Route
          path="/equity-policy/refund-policy"
          element={<RefundPolicyPage />}
        />
        <Route
          path="/equity-policy/termsandcondition"
          element={<TermsServicesPage />}
        />
        <Route
          path="/equity-policy/legal-disclaimer"
          element={<LegalDisclaimerPage />}
        />
        <Route path="/equity-service" element={<ServicesPage />} />
        <Route path="/equity-service/:serviceId" element={<ServicesPage />} />
        <Route path="/grievance" element={<GrievancePage />} />
        <Route path="/equity-kyc" element={<KYCPage />} />
        <Route
          path="/equity-service-agreement"
          element={<ServiceAgreementPage />}
        />
        <Route path="/equity-complaints" element={<ComplaintsPage />} />
        <Route path="/equity-login" element={<LoginPage />} />
        <Route
          path="/equity-admin-panel"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }
        >
          <Route
            path="enquiries" // relative path to "/equity-admin-panel/enquiries"
            element={<EnquiryPanel />}
          />
          <Route
            path="admin-registration"
            element={<AdminUserRegistration />}
          />
          <Route path="complaints" element={<ComplaintPanel />} />
          <Route path="kyc-approval" element={<KycApproval />} />
          <Route
            path="pending-purchase-approval"
            element={<PurchaseApproval />}
          />
           <Route path="kyc/:id" element={<CustomerKYC />} />
          <Route path="customers" element={<Customers />} />
          <Route path="customers/:id" element={<Customer />} />
        </Route>

        <Route path="/equity-careers" element={<CareersPage />} />
        <Route path="/equity-our-process" element={<ProcessPage />} />
        <Route path="/equity-packages" element={<Packages />} />
        <Route path="/equity-package-payment" element={<PaymentPage />} />
      </Routes>
    </>
  

  );
}

export default App;
