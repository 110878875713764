import React from "react";
import { toast, ToastContainer } from "react-toastify";

const KYCStep1 = ({
  fname,
  mname,
  lname,
  email,
  phone,
  setfname,
  setmname,
  setlname,
  setemail,
  setphone,
  handleOtp,
  handleOtpVerification,
  isVerified,
  verifyOtp,
  setverifyOtp,
}) => {
  return (
    <>
      <p className="text-2xl  md:col-span-3 text-center">
        Enter details to receive OTP
      </p>
      <div className="mb-4 w-full md:max-w-md">
        <label className="block mb-2 text-sm font-semibold text-gray-900">
          First Name <sup className="text-red-500">*</sup>
          <input
            type="text"
            value={fname}
            onChange={(e) => setfname(e.target.value)}
            className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
            placeholder="Your First Name"
            required
          />
        </label>
      </div>
      <div className="mb-4 w-full md:max-w-md">
        <label className="block mb-2 text-sm font-semibold text-gray-900">
          Middle Name
          <input
            type="text"
            value={mname}
            onChange={(e) => setmname(e.target.value)}
            className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
            placeholder="Your Middle Name"
          />
        </label>
      </div>
      <div className="mb-4 w-full md:max-w-md">
        <label className="block mb-2 text-sm font-semibold text-gray-900">
          Last Name <sup className="text-red-500">*</sup>
          <input
            type="text"
            value={lname}
            onChange={(e) => setlname(e.target.value)}
            className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
            placeholder="Your Last Name"
            required
          />
        </label>
      </div>
      <div className="mb-4 w-full md:max-w-md">
        <label className="block mb-2 text-sm font-semibold text-gray-900">
          Email <sup className="text-red-500">*</sup>
          <input
            type="email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
            className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
            placeholder="Your Email"
            required
          />
        </label>
      </div>
      <div className="mb-4 w-full md:max-w-md">
        <label className="block mb-2 text-sm font-semibold text-gray-900">
          Phone <sup className="text-red-500">*</sup>
          <input
            type="tel"
            value={phone}
            pattern="^(0|[1-9][0-9]*)$"
            onChange={(e) => {
              const regex = /^[0-9]*$/;
              const value = e.target?.value;
              if (!regex.test(value)) {
                // toast.error("Please Enter Numbers Only");
                return
              } else if (value.length <= 10) {
                setphone(value);
              } else {
                // toast.error("Please Enter a maximum of 10 digits");
                return
              }
            }}
            className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
            placeholder="Your Phone"
            required
          />
        </label>
      </div>

      <button
        className="py-1  px-2 place-self-center   border border-primary text-primary rounded  hover:border-accent hover:text-accent "
        // type="submit"
        onClick={handleOtp}
      >
        Get OTP On Email
      </button>
      {/* OTP Verification Section */}
      <div className="grid grid-cols-2 gap-2  my-4 justify-center ">
        <input
          type="number"
          className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  placeholder:italic placeholder:font-normal focus:outline-none"
          placeholder="Enter OTP"
          value={verifyOtp}
          onChange={(e) => setverifyOtp(e.target.value)}
          required
        />
        <button
          className=" border border-primary text-primary rounded hover:border-accent hover:text-accent "
          onClick={handleOtpVerification}
        >
          Verify OTP
        </button>
      </div>
    </>
  );
};

export default KYCStep1;
