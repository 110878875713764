/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/_equitylogo.png";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

function Navbar() {
  const location = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isServicesDropdown, setIsServicesDropdown] = useState(false);
  const [isSerDropdown, setIsSerDropdown] = useState(false);
  const [isPolicyDropdown, setIsPolicyDropdown] = useState(false);
  const [isPolDropdown, setIsPolDropdown] = useState(false);
  const [isMoreDropdown, setIsMoreDropdown] = useState(false);
  const [isMDropdown, setIsMDropdown] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const isLinkActive = (pathname) => {
    return location.pathname === pathname;
  };

  const scrollToTop = () => {

    window.scrollTo(0, 0);closeDropDowns()
  };

  const mobileMenuRef = useRef(null);

  const toggleSerDropdown = () => {
    setIsSerDropdown(!isSerDropdown);
    setIsPolDropdown(false);
    setIsMoreDropdown(false);
    if (!isSerDropdown) {
      setIsServicesDropdown(false);
    }
  };

  const closeDropDowns = () => {
    setIsServicesDropdown(false);
    setIsSerDropdown(false);
  };

  const togglePolDropdown = () => {
    setIsPolDropdown(!isPolDropdown);
    setIsSerDropdown(false);
    setIsMoreDropdown(false);
    if (!isPolDropdown) {
      setIsPolicyDropdown(false);
    }
  };

  const closePolDropDowns = () => {
    setIsPolicyDropdown(false);
    setIsPolDropdown(false);
  };

  const toggleMoreDropdown = () => {
    setIsMoreDropdown(!isMoreDropdown);
    setIsSerDropdown(false);
    setIsPolDropdown(false);

    if (!isMoreDropdown) {
      setIsMDropdown(false);
    }
  };
  const closeMoreDropDowns = () => {
    setIsMoreDropdown(false);
    setIsMDropdown(false);
  };
  const services = [
    { ser: "ERM Holding Pack", id: "EHP" },
    { ser: "ERM WEALTH", id: "EW" },
    { ser: "ERM SWING PACK", id: "ESP" },
    { ser: "ERM Equity & Derivative Combo", id: "EEDC" },
    { ser: "ERM Stock OPTION PACK", id: "ESOP" },
    { ser: "ERM Index (Future & Option)", id: "EIFO" },
    { ser: "ERM COMMODITY (Base Metal & Energy)", id: "ECBME" },
    { ser: "ERM Full MCX Pack", id: "EFMP" },
    { ser: "ERM INDEX Option PACK", id: "EIOP" },
  ];
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains?.(event.target)
      ) {
        closeDropDowns();
        closePolDropDowns();
        closeMoreDropDowns();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [user]);

  return (
    <header className="  z-40 w-full bg-[#FFF] border-b shadow-md shadow-[#a2a2a2]/40">
      <nav className="bg-[#FFF] border-b h-auto relative shadow-md shadow-[#a2a2a2]/40 ">
        <div className="max-w-screen-xl flex  items-center justify-between mx-auto ">
          <Link to={`/`} className="flex items-center">
            <img src={logo} className="h-24 mr-2" alt="Equity Research Mart" />
          </Link>
          <div
            ref={mobileMenuRef}
            className={`${
              isMobileMenuOpen ? "block" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-5 md:mt-0 md:border-0 text-sm xl:text-md items-center *:font-bold">
              <li
                className={`${
                  isLinkActive("/")
                    ? "font-bold text-[] underline-offset-2 bg-gray-200 px-2.5 py-1 rounded"
                    : ""
                }`}
              >
                <Link
                  onClick={scrollToTop}
                  to={`/`}
                  className="block  py-2 pl-3 pr-4 md:border-0 md:hover:text-accent md:p-0  font-bold text-lg tracking-normal text-gray-900 md:hover:transition md:hover:duration-1000 rounded"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>

              <li
                className={`${
                  isLinkActive("/equity-aboutus")
                    ? "font-bold underline text-primary underline-offset-2 bg-gray-200 p-1 rounded"
                    : ""
                }`}
              >
                <Link
                  onClick={scrollToTop}
                  to={`/equity-aboutus`}
                  className="block py-2 pl-3 pr-4  font-bold text-lg tracking-normal text-gray-900 md:border-0 md:hover:text-accent md:p-0  md:hover:transition md:hover:duration-1000 rounded"
                >
                  About us
                </Link>
              </li>

              <li
                className={`${
                  isLinkActive("/equity-service")
                    ? "font-bold underline text-primary underline-offset-2 bg-gray-200 p-1 rounded"
                    : ""
                }`}
              >
                <Link
                  href=""
                  className={`gap-2 items-center py-2 pl-3 pr-4  flex   hover:bg-gray-200md:hover:bg-transparent md:border-0 md:hover:text-accent md:p-0  font-bold text-lg tracking-normal text-gray-900 md:hover:transition md:hover:duration-1000 rounded ${
                    isSerDropdown ? "text-primary" : ""
                  }`}
                  onClick={toggleSerDropdown}
                >
                  <Link
                    to={"/equity-service"}
                    className="block py-2 pl-3 pr-4  font-bold text-lg tracking-normal text-gray-900 md:border-0 md:hover:text-accent md:p-0  md:hover:transition md:hover:duration-1000 rounded"
                  >
                    Services
                  </Link>
                  {isSerDropdown ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </Link>

                {/* Larger screen  */}
                {isSerDropdown && (
                  <div className="md:flex lg:flex xl:flex 2xl:flex hidden absolute top-4 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 rounded-sm shadow-md max-w-5xl mt-16 px-2 py-2 w-full z-50" >
                    <ul className="py-2 space-y-1 w-full max-w-lg">
                      {services.map((service, i) => (
                        <>
                          <li
                            className="flex gap-3 hover:bg-primary/20 rounded-lg hover:text-accent"
                            key={i}
                          >
                            <Link
                              onClick={scrollToTop}
                              to={`/equity-service/${service.id}`}
                              className="block px-4 py-2 "
                            >
                              <p className="text-sm xl:text-md font-bold tracking-[2px] ">
                                {service.ser}
                              </p>
                            </Link>
                          </li>
                          <hr className="mx-4" />
                        </>
                      ))}
                    </ul>
                    <ul className="py-2 ml-4 space-y-4 border-l-2">
                      {/* <li>
                                                <div className="py-4 px-2 w-full">
                                                    <video
                                                        className=" brightness-75 rounded-lg mx-8 max-w-2xl bg-blue-600"
                                                        autoPlay
                                                        muted
                                                        loop
                                                        playsInline
                                                    >
                                                        <source src={``} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </li> */}
                      <li className="py-2 mx-2 bg-gray-200/70">
                        <Link href="/advancements" className="block px-4 py-2 ">
                          <p className="text-gray-600 font-bold">
                            Specially crafted solutions that seamlessly align
                            with your goals, exemplifying excellence in every
                            facet of our services.
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              {/* 
                <li
                  className={`${
                    isLinkActive("/equity-careers")
                      ? "font-bold underline text-primary underline-offset-2"
                      : ""
                  }`}
                >
                  <Link
                    onClick={scrollToTop}
                    to={`/equity-careers`}
                    className="block py-2 pl-3 pr-4 md:border-0 md:hover:text-accent md:p-0 font-bold text-lg tracking-normal text-gray-900 md:hover:transition md:hover:duration-1000 rounded"
                  >
                    Careers
                  </Link>
                </li> */}

           
              <li
                className={`${
                  isLinkActive("/equity-packages")
                    ? "font-bold underline text-primary underline-offset-2 bg-gray-200 p-1 rounded"
                    : ""
                }`}
              >
                <Link
                  onClick={scrollToTop}
                  to={`/equity-packages`}
                  className="block py-2 pl-3 pr-4 md:border-0 md:hover:text-accent-light md:p-0 font-bold text-lg tracking-normal text-gray-900 md:hover:transition md:hover:duration-1000 rounded"
                >
                  <span className=" hover:text-accent-light md:hover:transition md:hover:duration-1000">
                   Pricing
                  </span>
                </Link>
              </li>
              <li
                className={`${
                  isLinkActive("/equity-package-payment")
                    ? "font-bold underline text-primary underline-offset-2 bg-gray-200 p-1 rounded"
                    : ""
                }`}
              >
                <Link
                  onClick={scrollToTop}
                  to={`/equity-package-payment`}
                  className="block py-2 pl-3 pr-4 md:border-0 md:hover:text-accent-light md:p-0 font-bold text-lg tracking-normal text-gray-900 md:hover:transition md:hover:duration-1000 rounded"
                >
                  <span className=" hover:text-accent-light md:hover:transition md:hover:duration-1000">
                   Payment
                  </span>
                </Link>
              </li>
              <li
                className={`${
                  isLinkActive("/equity-process")
                    ? "font-bold underline text-[] underline-offset-2 bg-gray-200 p-1 rounded"
                    : ""
                }`}
              >
                <Link
                  onClick={scrollToTop}
                  to={`/equity-process`}
                  className="block py-2 pl-3 pr-4 md:border-0 md:hover:text-accent-light md:p-0 font-bold text-lg tracking-normal text-gray-900 md:hover:transition md:hover:duration-1000 rounded"
                >
                  <span className=" hover:text-accent-light md:hover:transition md:hover:duration-1000">
                    Contact
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <ul className="flex gap-2">
            <li
              className={`${
                isLinkActive("/equity-login")
                  ? "font-bold underline text-[] underline-offset-2"
                  : ""
              }`}
            >
              <Link
                onClick={scrollToTop}
                to={user ? "/equity-admin-panel/enquiries" : `/equity-login`}
                className="block py-2 pl-3 pr-4  hover:bg-gray-200 md:hover:bg-transparent md:border-0 md:hover:text-[] md:p-0 md:font-medium tracking-normal md:hover:transition md:hover:duration-200 rounded"
              >
                <span className="py-2 px-4 font-bold bg-gray-100 border border-blue-500 hover:border-accent-light rounded-md text-blue-500 hover:text-accent-light md:hover:transition md:hover:duration-200">
                  {user ? "Admin" : `Login`}
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
