// import React, { useRef, useState } from "react";
// import { motion, AnimatePresence, delay } from "framer-motion";
// import ProcessStep from "./ProcessStep";
// import { IoArrowBack, IoArrowForward } from "react-icons/io5";
// import Stepper from "./Stepper";

// const OurProcess = () => {
//   const [currentStep, setCurrentStep] = useState(0);

//   const steps = ["", "", "", "", ""];

//   const handleNext = () => {
//     setCurrentStep((prevStep) => prevStep + 1);
//   };

//   const handleBack = () => {
//     setCurrentStep((prevStep) => prevStep - 1);
//   };

//   const indexVariants = {
//     initial: (custom) => ({
//       scale: 0.8,
//       opacity: 0,
//     }),
//     animate: (i) => ({
//       scale: 1,
//       opacity: 1,
//       transition: {
//         duration: 0.4,
//         delay: 0.5,
//         ease: "easeIn",
//       },
//     }),
//     exit: (i) => ({
//       scale: 0.8,
//       opacity: 0,
//       transition: {
//         duration: 0.2,
//         ease: "easeIn",
//       },
//     }),
//   };
//   const processes = [
//     {
//       title: "KYC",
//       description: (
//         <p className="text-lg">
//           {" "}
//           Here you need to fill your personal details: Name Pan No. Address etc,
//           with that you need to upload your passport size photo and then
//           register your mobile number by OTP verification.All fields are
//           mandatory.
//         </p>
//       ),
//     },
//     {
//       title: "READ SERVICE AGREEMENT",
//       description: (
//         <p className="text-lg">
//           Here you will see The Service Agreement click on that to expand : you
//           need to read properly and give your consent by clicking on I Agree or
//           I Accept, If you disagree with any single point of agreement you may
//           Stop the process in any step. "We Values Your Susceptibility".After
//           that you may uplaod you original signature and click to SUBMIT for
//           next Step{" "}
//         </p>
//       ),
//     },
//     {
//       title: "DOWNLOAD AGREEMENT",
//       description: (
//         <p className="text-lg">
//           {" "}
//           Here, You need to do three things. Download the PDF file (This is PDF
//           format of our 1st Step to 5th Step of New Service Enrollment Process)
//           Take a print of PDF file Named "Equity Research Service Agreement" and
//           Sign with blue pen on each 8 Pages and Scan the same.
//           <a href="" download="Service Agreement">
//             Download Agreement
//           </a>
//         </p>
//       ),
//     },
//     {
//       title: "UPLOAD SIGNED AGREEMENT TO VERIFY",
//       description: (
//         <p className="text-lg">
//           {" "}
//           Here, You will see the option to upload the SCANNED copy of Equity
//           Services Agreement, After successfully uploaded you will see a
//           messages on screen. that your Services Agreement has bent sent for
//           verification by Chief Investment Advisor, Once or If verified we will
//           notify you through message on your mob. no. After Approval you will
//           get notification for next Step of Making Service Charges.
//         </p>
//       ),
//     },
//     {
//       title: "GO FOR PAYMENT",
//       description: (
//         <p className="text-lg">
//           {" "}
//           Here, You will see the different bank details and UPI details for
//           making payment of services chargers. we are accepting only Net Banking
//           Transfer,UPI Transfer, Cheque, DD and NEFT/RTGS. Cash Deposit not
//           allowed. After Making Payment you need to fill details of the same and
//           click on submit. Once your payment will receive we will notify you
//           through Message.
//         </p>
//       ),
//     },
//     // {
//     //   title: "",
//     //   description: " his is a Service Portal specially designed for Client level
//     //             segregation and Arm's length Relationship. Here you will receive
//     //             all research support, services, recommendation, Alert
//     //             notification, Recorded chat room, FAQ's, Market Updates, Market
//     //             Prices, Price flow charts, You can track all rendered services
//     //             from your enrollment date to end date, Calculator, Feedback,
//     //             Dispute Rise, Rate to Services ETC you can also see your details
//     //             by clicking on My Account -KYC & Agreement. Thanks for Choosing
//     //             Equity Research Pvt. Ltd. "An Intensified Research for
//     //             Delighted Respond"",
//     // },
//   ];
//   return (
//     <>
//       <section className="min-h-screen">
//         <h1 className="font-bold text-center my-10 ">
//           <span className="text-primary">Our </span>
//           Flow
//         </h1>
//         <h3 className=" text-center font-black text-accent">
//           Just {processes.length} Simple Steps
//         </h3>
//         <section className=" w-[90%] md:w-3/4 mx-auto my-10 rounded-md h-[70vh] lg:h-[50vh] border-2 border-accent overflow-hidden p-4 pb-10">
//           <aside className=" w-full ">
//             <div className="w-full flex justify-center items-center ">
//               <button
//                 disabled={currentStep === 0}
//                 onClick={handleBack}
//                 className={`p-1 rounded-md   ${
//                   currentStep === 0
//                     ? "bg-gray-400 text-white cursor-not-allowed"
//                     : "cursor-pointer bg-primary text-white hover:bg-primary-ligh"
//                 }`}
//               >
//                 <IoArrowBack />
//               </button>
//               <Stepper
//                 steps={steps}
//                 currentStep={currentStep}
//                 setStep={setCurrentStep}
//               />

//               <button
//                 disabled={currentStep === steps.length - 1}
//                 onClick={handleNext}
//                 className={`p-1  rounded-md   ${
//                   currentStep === steps.length - 1
//                     ? "bg-gray-400 text-white cursor-not-allowed"
//                     : "bg-primary text-white hover:bg-primary-light cursor-pointer"
//                 }`}
//               >
//                 <IoArrowForward />
//               </button>
//             </div>
//           </aside>
//           {/* <motion.main className="space-y-6  p-4 w-full justify-around items-center overflow-hidden text-2xl h-full mx-auto  *:shrink-0 *:h-max *:w-full *:mx-auto"> */}
//           <AnimatePresence>
//             {processes.map(
//               (process, i) =>
//                 currentStep === i && (
//                   <ProcessStep
//                     key={i}
//                     indexVariants={indexVariants}
//                     title={process.title}
//                     description={process.description}
//                     i={i}
//                     currentStep={currentStep}
//                   />
//                 )
//             )}
//           </AnimatePresence>
//           {/* </motion.main> */}
//         </section>
//       </section>
//     </>
//   );
// };

// export default OurProcess;

import React from "react";

import { TbNumber1, TbNumber2, TbNumber3, TbNumber4, TbNumber5 } from "react-icons/tb";
const OurProcess = () => {
  const processes = [
    {
      title: "KYC",
      icon: <TbNumber1  />,
      description:
        "Here you need to fill your personal details: Name Pan Number, Address etc, with that you need to upload your passport size photo and then register your mobile number by OTP verification.All fields are mandatory.",
    },
    {
      title: "READ SERVICE AGREEMENT",
      icon: <TbNumber2 />,
      description:
        "Here you will see The Service Agreement click on that to expand : you need to read properly and give your consent by clicking on I Agree or I Accept, If you disagree with any single point of agreement you may Stop the process in any step. We Values Your Susceptibility. After that you may uplaod you original signature and click to SUBMIT for next Step",
    },
    {
      title: "DOWNLOAD AGREEMENT",
      icon: <TbNumber3  />,
      description:
        'Here, You need to do three things. Download the PDF file (This is PDF format of our 1st Step to 5th Step of New Service Enrollment Process) Take a print of PDF file Named "Equity Research Mart Service Agreement" and Sign with blue pen on each 8 Pages and Scan the same. <a href="" download="Service Agreement"> Download Agreement</a>. ',
    },
    {
      title: "UPLOAD SIGNED AGREEMENT TO VERIFY",
      icon: <TbNumber4   />,
      description:
        "Here, You will see the option to upload the SCANNED copy of Equity Services Agreement, After successfully uploaded you will see a messages on screen. that your Services Agreement has bent sent for verification, Once verified we will notify you through message/email on your mob. no. After Approval you will get notification for next Step of Making Service Charges.",
    },
    {
      title: "GO FOR PAYMENT",
      icon: <TbNumber5 />,
      description:
        "Here, You will see the different bank details and UPI details for making payment of services chargers. we are accepting only Net Banking Transfer,UPI Transfer, Cheque, DD and NEFT/RTGS. Cash Deposit not allowed. After Making Payment you need to fill details of the same and click on submit. Once your payment will be receive we will notify you through mail.",
    },
    // {
    //   title: "",
    //   description: " his is a Service Portal specially designed for Client level
    //             segregation and Arm's length Relationship. Here you will receive
    //             all research support, services, recommendation, Alert
    //             notification, Recorded chat room, FAQ's, Market Updates, Market
    //             Prices, Price flow charts, You can track all rendered services
    //             from your enrollment date to end date, Calculator, Feedback,
    //             Dispute Rise, Rate to Services ETC you can also see your details
    //             by clicking on My Account -KYC & Agreement. Thanks for Choosing
    //             Equity Research Pvt. Ltd. "An Intensified Research for
    //             Delighted Respond"",
    // },
  ];
  return (
    <section className="min-h-screen bg-gradient-to-b from-[#01295c]  via-slate-900 to-[#000000] flex flex-col items-center mx-auto px-1 py-10">
      {processes.map((process, i) => (
        <div className=" max-w-7xl my-10  relative flex items-center justify-center mx-auto " key={i}>
          <p className="bg-accent-light text-black w-max p-2 font-extrabold text-sm md:text-xl rounded leading-6 tracking-wide z-10 absolute left-0 -top-6 ">
            {process.title}
          </p>
          <div
            className="bg-white text-lg w-11/12 md:max-w-7xl p-6 rounded font-medium relative z-0  shadow-inner "
           
          >
            <p 
            dangerouslySetInnerHTML={{ __html: process.description }}
            >
            </p> 
            <br />
          <span className="font-extrabold text-5xl md:text-6xl b-0 p-0   absolute bottom-0 right-0 z-50 text-accent bg-[#01295c] rounded-tl-md ">{process.icon}</span>
          </div>
        </div>
      ))}
    </section>
  );
};

export default OurProcess;
