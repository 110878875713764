import React from 'react'
import { toast, ToastContainer } from 'react-toastify';

const KYCStep2 = ({name,fatherName,setfatherName,altNumber,setaltNumber,setnationality,setgender,age,dob,setAge,occupation,setoccupation,setdob}) => {
    // let age=0
    const handleDobChange = (e) => {
        const dobValue = e.target.value;  // Get the DOB value from the input
        setdob(dobValue);  // Set the DOB state
      
        const birthDate = new Date(dobValue);  // Create a date object from the input
        const currentYear = new Date().getFullYear();  // Get the current year
      
        // Calculate the difference in years
        const age = currentYear - birthDate.getFullYear();
  
        setAge(age);  // Update the age state with the calculated age
      };
      
  return (
    <>
     <div className="mb-4 w-full md:max-w-md">
              <label className="block mb-2 text-sm font-semibold text-gray-900">
                Name 
                <input
                  type="text"
                  value={name}
                  className="shadow-sm bg-gray-300 border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                  placeholder="Your Full Name"
                  readOnly
                />
              </label>
            </div>
            <div className="mb-4 w-full md:max-w-md">
              <label className="block mb-2 text-sm font-semibold text-gray-900">
                Father's Name <sup className="text-red-500">*</sup>
                <input
                  type="text"
                  value={fatherName}
                  onChange={(e) => setfatherName(e.target.value)}
                  className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                  placeholder="Your Father's Full Name"
                />
              </label>
            </div>
            <div className="mb-4 w-full md:max-w-md">
              <label className="block mb-2 text-sm font-semibold text-gray-900">
                Alternate Number <sup className="text-red-500">*</sup>
                <input
                  type="tel"
                  value={altNumber}
                  onChange={(e) => {
                    const regex =/^[0-9]*$/;
                    const value = e.target?.value
                    if (!regex.test(value)) {
                      // toast.error("Please Enter Numbers Only");
                      return
                    } else if (value.length <= 10) {
                      setaltNumber(value); // update form data if valid
                      //console.log(value);
                    } else {
                      // toast.error("Please Enter a maximum of 10 digits");
                      return
                    }
                  }}
                  className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                  placeholder="Your Full Name"
                />
              </label>
            </div>
            <div className="mb-4 w-full md:max-w-md">
              <label className="block mb-2 text-sm font-semibold text-gray-900">
                Nationality <sup className="text-red-500">*</sup>
                <select
                  className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                  onChange={(e) => setnationality(e.target.value)}
                >
                  <option value={""}>Choose Nationality</option>

                  <option value={"indian"}>Indian</option>
                  <option value={"other"}>Other</option>
                </select>
              </label>
            </div>
           
            <div className="mb-4 w-full md:max-w-md">
              <label className="block mb-2 text-sm font-semibold text-gray-900">
                Gender <sup className="text-red-500">*</sup>
                <select
                  className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                  onChange={(e) => setgender(e.target.value)}
                >
                  <option value={""}>Choose Gender</option>

                  <option value={"m"}>Male</option>
                  <option value={"f"}>Female</option>
                  <option value={"o"}>Other</option>
                </select>
              </label>
            </div>
            <div className="mb-4 w-full md:max-w-md">
              <label className="block mb-2 text-sm font-semibold text-gray-900">
                DOB <sup className="text-red-500">*</sup>
                <input
                  type="date"
                  value={dob}
                  onChange={handleDobChange}
                  className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                />
              </label>
            </div>
            <div className="mb-4 w-full md:max-w-md">
              <label className="block mb-2 text-sm font-semibold text-gray-900">
                Age <sup className="text-red-500">*</sup>
                <input
                  type="numer"
                  value={age}
                  className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                  readOnly
                />
              </label>
            </div>
            <div className="mb-4 w-full md:max-w-md">
              <label className="block mb-2 text-sm font-semibold text-gray-900">
                Occupation <sup className="text-red-500">*</sup>
                <input
                  type="text"
                  value={occupation}
                  onChange={(e) => setoccupation(e.target.value)}
                  className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                  placeholder="Your Occupation"
                />
              </label>
            </div>
    </>
  )
}

export default KYCStep2