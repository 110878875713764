import React from "react";
import { motion } from "framer-motion";
import image from "../../assets/makemoney.png";
import { Link } from "react-router-dom";

const WhatWeOffer = () => {
  const services = [
    {
      heading: "ERM Holding Pack",
      description:
        "A product designed for short term investors who are looking for a passive investment strategy. It follows the Classic Value Investing Strategy. Value investing is the process of doing detective work to find these secret sales on stocks and buying them at a discount compared to how the market values them. In return for buying and holding these value stocks for the long term, thereby delivering meaningful results over the long run.",
      id: "EHP",
    },
    {
      heading: "ERM WEALTH",
      id: "EW",
      description:
        "A product designed for medium to long term investors who are looking for a strategy which can beat index returns over time.\n While our expert analysts dive into the fundamentals and technical analytics to find the hidden gems in the market, all you need is to sit back and watch your investments grow manifold.",
    },
    {
      id: "ESP",
      heading: "ERM SWING PACK",
      description:
        "A product dedicated for traders who intend to grab potential trades from Intraday Cash market with swing movement with 2-3 days holding. Traders employ technical analysis to determine when conditions are right to enter either long or short, and then to exit. Short term investors who are looking for an opportunity in a volatile market. It follows the momentum stocks which can give movement by holding good stocks in cash segment.",
    },
    {
      id: "EEDC",
      heading: "ERM Equity & Derivative Combo",
      description:
        "A product designed for traders who wish to trade with a combination of Equity and Derivative Segment on their Cash investments. ERM Equity Derivative Combo is an exclusive product for speculators who want to trade in the whole equity segment.",
    },
    {
      heading: "ERM Stock OPTION PACK",
      id: "ESOP",
      description:
        "A product dedicated for traders who wish to trade only in Stock Options derivative segment. Options trading tips rely heavily on Options Greeks i.e. Delta, Gamma, Vega, & Theta which are integral to derivative trading. Call & Put tips are aspects of Bullish & Bearish market which helps rise in premium price of Option.",
    },
  ];
  return (
    <section className="w-full min-h-screen p-6 bg-gradient-to-br from-[#01295c] via-slate-900 to-[#000000] flex flex-col items-center space-y-8 border-b-8 border-[#2db928]">
      <div className="container max-w-xl mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
        <div className="text-center mt-8">
          <h2 className="text-3xl font-bold sm:text-6xl text-slate-100">
            What we of
            <span className="underline underline-offset-8 decoration-green-500">
              fer
            </span>
          </h2>
          <p className="max-w-3xl mx-auto mt-4 text-lg font-light text-white">
            Creating Wealth The Right Way
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row justify-end items-center mb-4">
        <motion.img
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          src={image}
          className="aspect-square mx-auto md:w-2/6 "
        />
        <motion.div className="grid grid-cols-1 md:grid-cols-3 gap-3  md:grid-rows-2 justify-center w-full ">
          {services.map((serve, i) => (
            <motion.div
              key={i}
              className="group text-white text-[13px] border border-b-2 overflow-hidden hover:border-b-accent-light  border-b-primary-light p-2 rounded-md  text-left md:hover:transition md:hover:duration-1000 hover:scale-95 mt-4 space-y-8  "
            >
              <h4 className="font-semibold">{serve.heading}</h4>
              <p>{serve.description.split(".")[0]}</p>
              <Link
                to={`/equity-service/${serve.id}`}
                className="group-hover:text-accent-light md:group-hover:transition md:group-hover:duration-1000  text-primary-light"
              >
                Learn More
              </Link>
            </motion.div>
          ))}
          <motion.div className="group border border-b-2  text-[13px] overflow-hidden hover:border-b-accent-light  border-b-primary-light p-2 rounded-md  text-left md:hover:transition md:hover:duration-1000 hover:scale-95 mt-4 space-y-8 text-white">
            <h4 className=" font-semibold">
              Find a Service that suits your need.{" "}
            </h4>
            <p className="">Find many more services by Equity Research Mart</p>
            <Link
              to={`/equity-service`}
              className="group-hover:text-accent-light md:group-hover:transition md:group-hover:duration-1000  text-primary-light"
            >
              View All
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default WhatWeOffer;
