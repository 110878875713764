// import React, { useState } from "react";
// import { motion } from "framer-motion";
// import menu from "../assets/openmenu.png";
// import logo from "../assets/_equitylogo.svg";
// import notification from "../assets/notfi2.png";
// import { NavLink } from "react-router-dom";
// import {
//   IoClipboardOutline,
//   IoClose,
//   IoHome,
//   IoInformation,
//   IoLogInOutline,
//   IoMenu,
// } from "react-icons/io5";
// import { AiOutlineStock } from "react-icons/ai";
// import { IoIosPricetags, IoMdBriefcase } from "react-icons/io";
// import { FcProcess } from "react-icons/fc";
// import { FaList, FaListOl, FaUserCheck } from "react-icons/fa";
// import { RiCustomerService2Fill } from "react-icons/ri";
// import { CiSquareMore } from "react-icons/ci";

// function Sidebar() {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isServicesOpen, setIsServicesOpen] = useState(false);
//   const [isPolicyOpen, setIsPolicyOpen] = useState(false);
//   const [isMoreOpen, setIsMoreOpen] = useState(false);
//   const services = [
//     { ser: "ERM Holding Pack", id: "EHP" },
//     { ser: "ERM WEALTH", id: "EW" },
//     { ser: "ERM SWING PACK", id: "ESP" },
//     { ser: "ERM Equity & Derivative Combo", id: "EEDC" },
//     { ser: "ERM Stock OPTION PACK", id: "ESOP" },
//     { ser: "ERM Index (Future & Option)", id: "EIFO" },
//     { ser: "ERM COMMODITY (Base Metal & Energy)", id: "ECBME" },
//     { ser: "ERM Full MCX Pack", id: "EFMP" },
//     { ser: "ERM INDEX Option PACK", id: "EIOP" },
//   ];
//   return (
//     <div>
//       {/* Top Banner */}
//       <div className="bg-white h-fit p-3 flex justify-between items-center ">
//         <div className="flex gap-3 p-0 items-center">
//           <button onClick={() => setIsOpen(!isOpen)}>
//             {/* <img src={menu} className="h-5" alt="side-menu" /> */}
//             <span>
//               <IoMenu size={30} />
//             </span>
//           </button>

//           <div className="flex gap-2 items-center">
//             <img src={logo} className="h-12" alt="logo" />
//           </div>
//         </div>

//         {/* <div className="flex gap-3 items-center">
//           <img className="h-4" src={notification} alt="notification" />
//         </div> */}
//       </div>

//       {/* Sidebar */}
//       <motion.div
//         className="absolute top-0 left-0 h-full w-64 bg-white shadow-lg z-50 rounded-tr-2xl rounded-br-2xl"
//         initial={{ x: "-100%" }}
//         animate={{ x: isOpen ? 0 : "-100%" }}
//         transition={{ duration: 0.3 }}
//       >
//         <div className="p-4 bg-white w-full">
//           <div className="flex justify-between items-center">
//             <div className="flex items-center gap-2">
//               <img src={logo} className="h-12" alt="logo" />
//             </div>
//             <button onClick={() => setIsOpen(false)}>
//               {/* <span className="text-3xl text-gray-600">&times;</span> */}
//               <IoClose size={30} />
//             </button>
//           </div>
//           <nav className="mt-3 p-2 bg-white w-full">
//             <ul className="space-y-10 p-2 ">
//                <li className="flex items-center gap-3 text-accent-light">
//                 {/* <img src={menu1} alt="dashboard" className="h-6 w-6" /> */}
//                 <IoHome size={20} />
//                 <NavLink to={"/"} className="text-gray-600">
//                   Home
//                 </NavLink>
//               </li>
//                <li className="flex items-center gap-3 text-accent-light">
//                 {/* <img src={menu2} alt="statistics" className="h-6 w-6" /> */}
//                 <IoInformation size={20} />

//                 <NavLink className="text-gray-600" to={"/equity-aboutus"}>
//                   About us
//                 </NavLink>
//               </li>
//               <li>
//                 <button
//                   className="flex items-center gap-3 text-accent-light  w-full text-left"
//                   onClick={() => setIsServicesOpen(!isServicesOpen)}
//                 >
//                   <AiOutlineStock size={20} />
//                   {/* <img src={menu2} alt="statistics" className="h-6 w-6" /> */}
//                   <NavLink to={`/equity-service`} className="text-gray-600">
//                     Service
//                   </NavLink>
//                 </button>
//                 {isServicesOpen && (
//                   <ul className="space-y-5 mt-5 pl-4">
//                     {services.map((service, i) => (
//                       <li className="flex items-center gap-3 ">
//                         <NavLink
//                           to={`/equity-service/${service.id}`}
//                           className="text-gray-600"
//                         >
//                           {service.ser}
//                         </NavLink>
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </li>
//               <li>
//                 <button
//                   className="flex items-center gap-3 text-accent-light  w-full text-left"
//                   onClick={() => setIsPolicyOpen(!isPolicyOpen)}
//                 >
//                   {/* <img src={menu2} alt="statistics" className="h-6 w-6" /> */}
//                   <IoClipboardOutline size={20} />
//                   Policy
//                 </button>
//                 {isPolicyOpen && (
//                   <ul className="space-y-5 mt-5 pl-4">
//                      <li className="flex items-center gap-3 text-accent-light">
//                       {/* <img src={menu2} alt="service-1" className="h-5 w-5" /> */}

//                       <NavLink
//                         to={"/equity-policy/legal-disclaimer"}
//                         className="text-gray-600"
//                       >
//                         Legal Disclaimer
//                       </NavLink>
//                     </li>
//                      <li className="flex items-center gap-3 text-accent-light">
//                       {/* <img src={menu2} alt="service-2" className="h-5 w-5" /> */}
//                       <NavLink
//                         to={"/equity-policy/refund-policy"}
//                         className="text-gray-600"
//                       >
//                         Refund Policy
//                       </NavLink>
//                     </li>
//                      <li className="flex items-center gap-3 text-accent-light">
//                       {/* <img src={menu2} alt="service-3" className="h-5 w-5" /> */}
//                       <NavLink
//                         to={"/equity-policy/termsandcondition"}
//                         className="text-gray-600"
//                       >
//                         Terms and Conditions
//                       </NavLink>
//                     </li>
//                   </ul>
//                 )}
//               </li>
//                <li className="flex items-center gap-3 text-accent-light">
//                 {/* <img src={menu2} alt="careers" className="h-6 w-6" /> */}
//                 <IoMdBriefcase size={20} />

//                 <NavLink to={"/equity-careers"} className="text-gray-600">
//                   Careers
//                 </NavLink>
//               </li>
//                <li className="flex items-center gap-3 text-accent-light">
//                 {/* <img src={menu2} alt="Process" className="h-6 w-6" /> */}
//                 {/* <span className="text-black"> */}
//                 <FaListOl size={20} />
//                 {/* </span> */}
//                 <NavLink to={"/equity-our-process"} className="text-gray-600">
//                   Our Process
//                 </NavLink>
//               </li>
//                <li className="flex items-center gap-3 text-accent-light">
//                 {/* <img src={menu2} alt="kyc" className="h-6 w-6" /> */}
//                 <FaUserCheck size={20} />
//                 <NavLink to={"/equity-kyc"} className="text-gray-600">
//                   KYC
//                 </NavLink>
//               </li>

//                <li className="flex items-center gap-3 text-accent-light">
//                 {/* <img src={menu2} alt="statistics" className="h-6 w-6" /> */}
//                 <IoIosPricetags size={20} />
//                 <NavLink to={"/equity-packages"} className="text-gray-600">
//                   Pricing
//                 </NavLink>
//               </li>
//                <li className="flex items-center gap-3 text-accent-light">
//                 {/* <img src={menu2} alt="statistics" className="h-6 w-6" /> */}
//                 <IoIosPricetags size={20} />
//                 <NavLink to={"/equity-packages"} className="text-gray-600">
//                   Payment
//                 </NavLink>
//               </li>
//               <li>
//                 <button
//                   className="flex items-center gap-3 text-accent-light  w-full text-left"
//                   onClick={() => setIsMoreOpen(!isMoreOpen)}
//                 >
//                   {/* <img src={menu2} alt="statistics" className="h-6 w-6" /> */}
//                   <CiSquareMore size={20} />
//                   More
//                 </button>
//                 {isMoreOpen && (
//                   <ul className="space-y-5 mt-5 pl-4">
//                      <li className="flex items-center gap-3 text-accent-light">
//                       {/* <img src={menu2} alt="service-1" className="h-5 w-5" /> */}
//                       <NavLink to={"/grievance"} className="text-gray-600">
//                         Grievance
//                       </NavLink>
//                     </li>
//                      <li className="flex items-center gap-3 text-accent-light">
//                       {/* <img src={menu2} alt="service-1" className="h-5 w-5" /> */}
//                       <NavLink
//                         to={"/equity-service-agreement"}
//                         className="text-gray-600"
//                       >
//                         Service Agreement
//                       </NavLink>
//                     </li>
//                      <li className="flex items-center gap-3 text-accent-light">
//                       {/* <img src={menu2} alt="service-1" className="h-5 w-5" /> */}
//                       <NavLink to={"/equity-complaints"} className="text-gray-600">
//                         Complaints
//                       </NavLink>
//                     </li>
//                   </ul>
//                 )}
//               </li>
//                <li className="flex items-center gap-3 text-accent-light">
//                 {/* <img src={menu2} alt="statistics" className="h-6 w-6" /> */}
//                 <RiCustomerService2Fill size={20} />
//                 <NavLink to={"/equity-process"} className="text-gray-600">
//                   Contact
//                 </NavLink>
//               </li>
//                <li className="flex items-center gap-3 text-accent-light">
//                 {/* <img src={menu2} alt="statistics" className="h-6 w-6" /> */}
//                 <IoLogInOutline size={20} />
//                 <NavLink to={"/equity-login"} className="text-gray-600">
//                   Login
//                 </NavLink>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </motion.div>
//     </div>
//   );
// }

// export default Sidebar;
import React, { useState, useMemo } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import {
  IoClipboardOutline,
  IoClose,
  IoHome,
  IoInformation,
  IoLogInOutline,
  IoMenu,
} from "react-icons/io5";
import { AiOutlineStock } from "react-icons/ai";
import {  IoIosPricetags, IoMdBriefcase } from "react-icons/io";
import { FaChevronDown, FaListOl, FaUserCheck } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";

import logo from "../assets/_equitylogo.svg";

function Sidebar() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);

  const primaryColor = "#3498db"; // Replace with your primary color
  const activeColor = "#ffffff";  // Color for active tab

  // Memoizing the services array to avoid re-initialization on every render
  const services = useMemo(
    () => [
      { ser: "ERM Holding Pack", id: "EHP" },
      { ser: "ERM WEALTH", id: "EW" },
      { ser: "ERM SWING PACK", id: "ESP" },
      { ser: "ERM Equity & Derivative Combo", id: "EEDC" },
      { ser: "ERM Stock OPTION PACK", id: "ESOP" },
      { ser: "ERM Index (Future & Option)", id: "EIFO" },
      { ser: "ERM COMMODITY (Base Metal & Energy)", id: "ECBME" },
      { ser: "ERM Full MCX Pack", id: "EFMP" },
      { ser: "ERM INDEX Option PACK", id: "EIOP" },
    ],
    []
  );

  // Memoizing services list to avoid unnecessary re-renders
  const servicesList = useMemo(() => {
    return services.map((service, i) => (
      <li className="flex items-center gap-3" key={i}>
        <NavLink
          to={`/equity-service/${service.id}`}
          className={({ isActive }) =>
            `text-gray-600 hover:text-accent-light ${
             isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
            }`
          }
          onClick={()=>setIsOpen(false)}
        >
          {service.ser}
        </NavLink>
      </li>
    ));
  }, [services, primaryColor, activeColor]);

  return (
    <div>
      {/* Top Banner */}
      <div className="bg-white h-fit p-3 flex justify-between items-center ">
        <div className="flex gap-3 p-0 items-center">
          <button onClick={() => setIsOpen(!isOpen)}>
            <IoMenu size={30} />
          </button>

          <div className="flex gap-2 items-center">
            <img src={logo} className="h-12" alt="logo" />
          </div>
        </div>
      </div>

      {/* Sidebar */}
      <motion.div
        className="absolute top-0 left-0 h-full w-64 bg-white shadow-lg z-50 rounded-tr-2xl rounded-br-2xl"
        initial={{ x: "-100%" }}
        animate={{ x: isOpen ? 0 : "-100%" }}
        transition={{ duration: 0.3 }}
        style={{ willChange: "transform" }} // Add will-change property
      >
        <div className="p-4 bg-white w-full">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <img src={logo} className="h-12" alt="logo" />
            </div>
            <button onClick={() => setIsOpen(false)}>
              <IoClose size={30} />
            </button>
          </div>
          <nav className="mt-3 p-2 bg-white w-full">
            <ul className="space-y-10 p-2 ">
               <li className="flex items-center gap-3 text-accent-light">
                <IoHome size={20} />
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `hover:text-accent-light ${
                     isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
                    }`
                  }
                >
                  Home
                </NavLink>
              </li>
               <li className="flex items-center gap-3 text-accent-light">
                <IoInformation size={20} />
                <NavLink
                  className={({ isActive }) =>
                    `hover:text-accent-light ${
                     isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
                    }`
                  }
                  to="/equity-aboutus"
                >
                  About us
                </NavLink>
              </li>
              <li>
                <button
                  className="flex items-center gap-3 text-accent-light  w-full text-left"
                  onClick={() => setIsServicesOpen(!isServicesOpen)}
                >
                  <AiOutlineStock size={20} />
                  <NavLink
                    to="/equity-service"
                    className={({ isActive }) =>
                      `hover:text-accent-light flex items-center gap-2 justify-center ${
                    isServicesOpen
                          ? `bg-primary-light text-white p-1 font-semibold rounded px-2`
                          : ""
                      }`
                    }
                  >
                    Service {isServicesOpen ?<span className="rotate-180"><FaChevronDown  /></span> :<FaChevronDown  />}
                  </NavLink>
                </button>
                {isServicesOpen && (
                  <ul className="space-y-5 mt-5 pl-4">{servicesList}</ul>
                )}
              </li>
              <li>
                <button
                  className="flex items-center gap-3 text-accent-light  w-full text-left"
                  onClick={() => setIsPolicyOpen(!isPolicyOpen)}
                >
                  <IoClipboardOutline size={20} />
                  <span className={ `flex items-center justify-center gap-2  ${isPolicyOpen ? "bg-primary-light text-white p-1 font-semibold rounded px-2 " : "text-primary-light"} `}>

                  Policy {isPolicyOpen ?<span className="rotate-180"><FaChevronDown  /></span> :<FaChevronDown  />}
                  </span>
                </button>
                {isPolicyOpen && (
                  <ul className="space-y-5 mt-5 pl-4">
                     <li className="flex items-center gap-3 text-accent-light">
                      <NavLink
                        to="/equity-policy/legal-disclaimer"
                        className={({ isActive }) =>
                          `hover:text-accent-light ${
                            isActive
                              ? `bg-primary-light text-white`
                              : ""
                          }`
                        }
                      >
                        Legal Disclaimer
                      </NavLink>
                    </li>
                     <li className="flex items-center gap-3 text-accent-light">
                      <NavLink
                        to="/equity-policy/refund-policy"
                        className={({ isActive }) =>
                          `hover:text-accent-light ${
                            isActive
                              ? `bg-primary-light text-white`
                              : ""
                          }`
                        }
                      >
                        Refund Policy
                      </NavLink>
                    </li>
                     <li className="flex items-center gap-3 text-accent-light">
                      <NavLink
                        to="/equity-policy/termsandcondition"
                        className={({ isActive }) =>
                          `hover:text-accent-light ${
                            isActive
                              ? `bg-primary-light text-white`
                              : ""
                          }`
                        }
                      >
                        Terms and Conditions
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
               <li className="flex items-center gap-3 text-accent-light">
                <IoMdBriefcase size={20} />
                <NavLink
                  to="/equity-careers"
                  className={({ isActive }) =>
                    `hover:text-accent-light ${
                     isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
                    }`
                  }
                >
                  Careers
                </NavLink>
              </li>
               <li className="flex items-center gap-3 text-accent-light">
                <FaListOl size={20} />
                <NavLink
                  to="/equity-our-process"
                  className={({ isActive }) =>
                    `hover:text-accent-light ${
                     isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
                    }`
                  }
                >
                  Our Process
                </NavLink>
              </li>
               <li className="flex items-center gap-3 text-accent-light">
                <FaUserCheck size={20} />
                <NavLink
                  to="/equity-kyc"
                  className={({ isActive }) =>
                    `hover:text-accent-light ${
                     isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
                    }`
                  }
                >
                  KYC
                </NavLink>
              </li>
               <li className="flex items-center gap-3 text-accent-light">
                <RiCustomerService2Fill size={20} />
                <NavLink
                  to="/equity-process"
                  className={({ isActive }) =>
                    `hover:text-accent-light ${
                     isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
                    }`
                  }
                >
                  Contact
                </NavLink>
              </li>
              <li className="flex items-center gap-3 text-accent-light">
                <IoIosPricetags size={20} />
                <NavLink to={"/equity-packages"} className={({ isActive }) =>
                    `hover:text-accent-light ${
                     isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
                    }`
                  }>
                  Pricing
                </NavLink>
              </li>
              <li className="flex items-center gap-3 text-accent-light">
                <IoIosPricetags size={20} />
                <NavLink to={"/equity-package-payment"} className={({ isActive }) =>
                    `hover:text-accent-light ${
                     isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
                    }`
                  }>
                  Payment
                </NavLink>
              </li>
             
              {/* <li>
                <button
                  className="flex items-center gap-3 text-gray-600 w-full text-left"
                  onClick={() => setIsMoreOpen(!isMoreOpen)}
                >
                  <CiSquareMore size={20} />
                  More
                </button>
                {isMoreOpen && (
                  <ul className="space-y-5 mt-5 pl-4">
                    <li className="flex items-center gap-3 text-gray-600">
                      <NavLink to={"/grievance"} className="text-gray-600">
                        Grievance
                      </NavLink>
                    </li>
                    <li className="flex items-center gap-3 text-gray-600">
                      <NavLink
                        to={"/equity-service-agreement"}
                        className="text-gray-600"
                      >
                        Service Agreement
                      </NavLink>
                    </li>
                    <li className="flex items-center gap-3 text-gray-600">
                      <NavLink to={"/equity-complaints"} className="text-gray-600">
                        Complaints
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li> */}
              <li className="flex items-center gap-3 text-accent-light">
                <IoLogInOutline size={20} />
                <NavLink
                  to={user ? "/equity-admin-panel/enquiries" : `/equity-login`}
                  className={({ isActive }) =>
                    `hover:text-accent-light ${
                      isActive ? `bg-primary-light text-white p-1 font-semibold rounded px-2` : ""
                    }`
                  }
                >
                   {user ? "Admin" : `Login`}
                </NavLink>
              </li>
           
            </ul>
          </nav>
        </div>
      </motion.div>
    </div>
  );
}

export default Sidebar;

