import { toast } from "react-toastify";
import convertTo64 from "./converToBase64";
import convertPDFToBase64 from "./convertPdfToBase64";
// import { Toast } from "react-toastify/dist/components";

export const fileTypeVaidation = async (e, type) => {
  const file = e.target.files[0];
  //console.log(file.type);
  const imageRegex = /image\//;
  const pdfRegex = /application\//;

//   //console.log(regex.test(file.type));

  switch (type) {
    case "image":
      if ((type = "image" && !imageRegex.test(file.type))) {
        toast.error("Please Only Upload img, jpg or png file")
        return;
      } else {
        const base64 = await convertTo64(e);
        return base64;
      }

    case "pdf":
      if ((type = "pdf" && !pdfRegex.test(file.type))) {
        toast.error("Please Only Upload pdf file");
        return;
      } else {
        const base64 = await convertPDFToBase64(e);
        return base64;
      }
  }
};
