import React from "react";
import { Link } from "react-router-dom";
import image from "../assets/gettoknow.jpg";

function GetToKnow() {
  return (
    <section className="bg-slate-200/50">
      <div class="relative flex flex-col items-center mx-auto lg:flex-row-reverse w-full lg:mt-12 lg:mb-12 max-w-7xl ">
        <div class="w-full h-64 lg:w-1/2 lg:h-auto">
          <img
            class="h-[75vh] w-full object-cover"
            src={image}
            alt="Trading Stocks"
          />
        </div>

        <div class="max-w-lg bg-white md:max-w-2xl md:z-10 md:shadow-lg md:absolute md:top-0 md:mt-48 lg:w-3/5 lg:left-0 lg:mt-20 lg:ml-20 xl:mt-24 xl:ml-12 border-l-8 border-green-500">
          <div class="flex flex-col p-12 md:px-16">
            <h2 class="text-2xl font-semibold uppercase text-green-700 lg:text-4xl">
              Get to know us
            </h2>
            <p class="mt-4">
              Equity Research Mart a SEBI-registered Research Analyst
              (INH000016700), offering stock recommendations based on rigorous
              fundamental and technical research. Our analysts are experts in
              equity analysis, ensuring thorough due diligence and a focus on
              valuations for optimal success. Committed to ethical and
              transparent practices, we prioritize our clients' interests
              through advanced research.
            </p>
            <Link to={"/equity-aboutus"}>
              <button class="mt-8 w-fit px-4 py-2 bg-blue-700 text-white rounded-lg">
                Know More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetToKnow;
