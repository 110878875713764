import React, { useEffect, useRef, useState } from "react";
import { IoEye } from "react-icons/io5";
import apiendpoints from "../../apiendpoints.json";
import { toast } from "react-toastify";
import Remarks from "../Remarks";
import PurchaseScreen from "../PurchaseScreen";

const PurchaseApproval = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState(10);
  // const itemsPerPage = 10;

  const [remarkScreen, setremarkScreen] = useState(false);
  const [remarkData, setRemarkData] = useState("");
  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await fetch(
          `${apiendpoints.equityUrl}/api/GetPayments/-1?_equitypaymentstatus=1`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch enquiries");
        }
        // const data = await response
        const data = await response.json();
        setEnquiries(data);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchEnquiries();
  }, []);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(enquiries.length / itemsPerPage);

  const filteredEnquiries = enquiries.filter((enquiry) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (enquiry.emailId?.toLowerCase().includes(searchLower) ?? false) ||
      (enquiry.name?.toLowerCase().includes(searchLower) ?? false)
    );
  });
  const excelDownloadRef = useRef(null);

  const currentEnquiries = filteredEnquiries.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleExcelDownload = async () => {
    if (excelDownloadRef.current) {
      excelDownloadRef.current.click();
    }
  };
  function formatDate(date) {
    if (!date) return ""; // Return empty string if date is null or undefined

    const d = new Date(date); // Create a Date object from the input
    let year = d.getFullYear(); // Get full year
    let month = (d.getMonth() + 1).toString().padStart(2, "0"); // Get month and pad it to 2 digits
    let day = d.getDate().toString().padStart(2, "0"); // Get day and pad it to 2 digits

    return `${year}-${month}-${day}`; // Return formatted string
  }
  const docWindow = (path) => {
    let url = new URL(path, `${apiendpoints.equityUrl}`);
    window.open(
      url,
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };
  const handleApprove = async (id, email, remarks) => {
    try {
      const response = await fetch(
        `${apiendpoints.equityUrl}/api/ApprovePayment?_equityPaymentStaus=2`,
        // https://localhost:7021/api/ApprovePayment?_equityPaymentStaus=1
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id, toEmail: email, remarks: remarks }),
        }
      );
      if (!response.ok) {
        toast.error("Failed to Approve Payment");
      } else {
        toast.success("Success");
      }
      setEnquiries((prev) => prev.filter((p) => p.id !== id));
    } catch (error) {}
  };

  const handleReject = async (id, email, remarks) => {
    try {
      const response = await fetch(
        `${apiendpoints.equityUrl}/api/ApprovePayment?_equityPaymentStaus=3`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            action: "reject",
            userId: id,
          },
          body: JSON.stringify({ toEmail: email, id: id, remarks: remarks }),
        }
      );
      if (!response.ok) {
        toast.error("Failed To Reject");
      } else {
        toast.success("Successfully Rejected");
      }
      setEnquiries((prev) => prev.filter((p) => p.id !== id));
    } catch (error) {}
  };
  const handleAction = async (opt, data) => {
    setremarkScreen(true);
    setRemarkData({ opt, ...data });

    // console.log(remarkData);
  };
  const fetchfilteredPayments = async (e) => {
    setLoading(true);
    let val = e.target.value;
    try {
      const response = await fetch(
        `${apiendpoints.equityUrl}/api/ApprovePayment?_equityPaymentStaus=${val}`,
        {
          headers: {
            "Content-Type": "application/json",
            entityId: "equity",
          },
        }
      );
      if (!response.ok) {
        toast.error("Failed to fetch KYCs");
      }

      const data = await response.json();
      setEnquiries(data);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
    <h4 className=" mt-2 text-center font-bold pl-6">Payments Pending for Approval</h4>
      {/* <div className="mb-4 ml-2 mt-10 lg:mt-1 flex justify-end">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by email, organization, or username"
          className="px-4 py-2 border border-gray-300 rounded max-w-md w-full"
        />
        <button className="bg-green-600 py-1 px-4 mx-4 rounded-lg text-white text-sm font-semibold uppercase tracking-[1px]">
          Delete
        </button>
        <a href="" download={"complaints"} ref={excelDownloadRef} hidden></a>
        <button
          className="bg-green-600 py-1 px-4 mx-4 rounded-lg text-white  text-sm font-semibold uppercase tracking-[1px]"
          onClick={handleExcelDownload}
        >
          Download CSV
        </button>
      </div> */}

      {!!remarkScreen && (
        <section className="w-full min-h-screen bg-black/50 absolute left-0 top-0 grid place-items-center  overflow-hidden">
          <PurchaseScreen
            setremarkScreen={setremarkScreen}
            approve={handleApprove}
            reject={handleReject}
            data={remarkData}
            approvemessage={"Purchase Approved"}
            rejectmessage={"Purchase Rejected"}
            // screen="purchase"
          />
        </section>
      )}
      <div className=" h-[70vh] xl:h-[80vh]  overflow-y-scroll scollbar p-2 rounded-md mt-5">
        <div className="my-1">
          <span>Filter</span>
          <select
            onChange={fetchfilteredPayments}
            className="px-3 py-1 mx-1 border rounded"
          >
            <option value={1}>Pending</option>
            <option value={2}>Approved</option>
            <option value={3}>Rejected</option>
            <option value={4}>All</option>
          </select>
        </div>
        <table className="min-w-full text-sm  bg-white border border-gray-200">
          <thead className="bg-gray-100  uppercase font-semibold text-gray-600">
            <tr>
              {/* <th className="py-3 px-4 border border-gray-200">Select</th> */}
              <th className="py-3 px-4 border border-gray-200">User ID</th>
              <th className="py-3 px-4 border border-gray-200">Name</th>
              <th className="py-3 px-4 border border-gray-200">Mobile</th>
              <th className="py-3 px-4 border border-gray-200">Email</th>
              <th className="py-3 px-4 border border-gray-200">Pan No.</th>
              <th className="py-3 px-4 border border-gray-200">Service</th>
              <th className="py-3 px-4 border border-gray-200">Purchase On</th>
              <th className="py-3 px-4 border border-gray-200">
                Payment Proof
              </th>
              <th className="py-3 px-4 border border-gray-200">
                Transaction Id
              </th>
              <th className="py-3 px-4 border border-gray-200">
                Account Holder Name
              </th>
              <th className="py-3 px-4 border border-gray-200">KYC Status</th>
              <th className="py-3 px-4 border border-gray-200">
                Payment Status
              </th>
            </tr>
          </thead>
          <tbody className="text-xs text-gray-700">
            {!!loading && <div className="loader "></div>}
            {currentEnquiries.map((enquiry) => (
              <tr key={enquiry.id} className="hover:bg-gray-50">
                {/* <td className="py-2 px-4 border border-gray-200 h-max">
                    <input type="checkbox" />
                  </td> */}
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.id}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.name}
                </td>

                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.phoneNo}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.email}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.panno}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.service}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {formatDate(enquiry.createdAt)}
                </td>

                <td className="py-2 px-4 border border-gray-200 h-max ">
                  <p className="flex flex-row  items-center gap-2">
                    Payment Proof
                    <span
                      className="hover:text-primary-light cursor-pointer text-lg"
                      onClick={() => docWindow(enquiry.paymentProof)}
                    >
                      <IoEye />
                    </span>
                  </p>
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.transactionId}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.accountHolderName}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry._isKYC ? "Active" : "Pending"}
                </td>
                <td className=" border border-gray-200 h-max">
                  <select
                    className="w-max h-full"
                    onChange={(e) => handleAction(e.target.value, enquiry)}
                  >
                    <option value="0">In-progress</option>
                    {/* <option value="2">Reject</option> */}
                    <option value="1">Approve</option>
                    <option value="2">Reject</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`px-3 py-1 mx-1 border rounded ${
            currentPage === 1 ? "bg-gray-200 cursor-not-allowed" : "bg-white"
          }`}
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 mx-1 border rounded ${
            currentPage === totalPages
              ? "bg-gray-200 cursor-not-allowed"
              : "bg-white"
          }`}
        >
          Next
        </button>
        <select onChange={(e) => setitemsPerPage(Number(e.target.value))}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>
    </>
  );
};

export default PurchaseApproval;
