const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Works for any file type
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  
  export default async function convertPDFToBase64(e) {
    const file = e.target.files[0];
    if (file) {
      try {
        const base64 = await fileToBase64(file);
        //console.log(base64); // This will correctly log the base64 string for both images and PDFs
        return base64; // Return the base64 string
      } catch (error) {
        console.error("Error converting file to base64: ", error);
      }
    }
    return null; // If no file is provided
  }
  