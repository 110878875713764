import React, { useState } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import Stepper from "./Stepper";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import ProcessStep from "./ProcessStep";
import { TbNumber1, TbNumber2, TbNumber3 } from "react-icons/tb";

const Grievance = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = ["", "", ""];

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const indexVariants = {
    initial: (custom) => ({
      scale: 0.8,
      opacity: 0,
    }),
    animate: (i) => ({
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.4,
        delay: 0.5,
        ease: "easeIn",
      },
    }),
    exit: (i) => ({
      scale: 0.8,
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: "easeIn",
      },
    }),
  };
  const processes = [
    {
      description:
        " All the clients having complaint regarding any service or otherwise shall inform compliance department in writing. To mentioned mail at info@equityresearchmart.in or contact us +91 70044 93381. To ensure timely recording and recognition of the grievance, the respective executive shall revert within 5 working days w.r.t redressal of such complaint.",
      icon: <TbNumber1 />,
    },
    {
      description:
        "If the client still wants to escalate the complaint, he/she can approach at info@equityresearchmart.in  He being the highest authority at M/s Equity Research Mart can redress the complaint in the best possible manner. He shall reply the client within 15 working days. ",
      icon: <TbNumber2 />,
    },
    {
      description:
        " In case the client is still not satisfied he/she can escalate the matter with the regulators at www.sebi.gov.in Please note that in case client marks a copy of mail to all the levels, it will not be considered as escalated matter, it shall in any case start from level 1 and only in case of a separate mail after expiration or reply from the previous level shall the complaint be considered at next level.",
      icon: <TbNumber3 />,
    },
  ];
  return (
    <section className="bg-gradient-to-b from-[#01295c]  via-slate-900 to-[#000000]">
      <div className=" max-w-7xl mx-auto p-4 w-full     text-white">
        <h1 className=" font-semibold text-center mt-10">Grievance Redressal</h1>
        <p>Welcome to <strong>Equity Research Mart</strong> </p>

        <p className="">
          At Equity Research Mart, we strive to offer you seamless access to
          information, services, and a straightforward process for addressing
          any concerns. Our goal is to resolve your issues to your satisfaction
          with the first point of contact. However, if our response does not
          meet your expectations, please follow the steps outlined below.{" "}
        </p>

        <section className=" w-[90%]  mx-auto my-5">
          {processes.map((process, i) => {
           return <div
              className=" max-w-7xl my-10  relative flex items-center justify-center mx-auto text-black"
              key={i}
            >
              <div className="bg-white text-lg w-11/12 md:max-w-7xl p-6 rounded font-medium relative z-0  shadow-inner break-words text-wrap " style={{wordBreak:"break-word"}}>
                <p
                  dangerouslySetInnerHTML={{ __html: process.description }}
                ></p>
                <br />
                <span className="font-extrabold text-5xl md:text-6xl b-0 p-0   absolute bottom-0 right-0 z-50 text-accent bg-[#01295c] rounded-tl-md ">
                  {process.icon}
                </span>
              </div>
            </div>;
          })}
        </section>

        {/* <ul className="list-disc">
          <li><strong></strong>Republish material from Equity Research</li>
          <li>Sell, rent or sub-license material from Equity Research</li>
          <li>Reproduce, duplicate or copy material from Equity Research</li>
          <li>Redistribute content from Equity Research </li>
        </ul> */}
        <p className="">
          In case you are not satisfied with our response you can lodge your
          grievance with SEBI at{" "}
          <a href="https://scores.sebi.gov.in/scores-home" target="_blank">
            https://scores.sebi.gov.in/scores-home
          </a>{" "}
          or you may also write to any of the offices of SEBI. SCORES may be
          accessed thorough SCORES mobile application as well, same can be
          downloaded from below link:
          <br />
          <a
            href="https://play.google.com/store/apps/details?id=com.ionicframework.sebi236330"
            target="_blank"
          >
            https://play.google.com/store/apps/details?id=com.ionicframework.sebi236330
          </a>
        </p>
        <p className="">
          ODR Portal could be accessed, if unsatisfied with the response. Your
          attention is drawn to the SEBI circular no.
          SEBI/HO/OIAE/OIAE_IAD-1/P/CIR/2023/131 dated July 31, 2023, on “Online
          Resolution of Disputes in the Indian Securities Market”. A common
          Online Dispute Resolution Portal (“ODR Portal”) which harnesses
          conciliation and online arbitration for resolution of disputes arising
          in the Indian Securities Market has been established. ODR Portal can
          be accessed via the following link -{" "}
          <a href="https://smartodr.in/" target="_blank">
            https://smartodr.in/
          </a>
        </p>
      </div>
    </section>
  );
};

export default Grievance;
