import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import Login from '../components/Admin Pages/Login'
import Warning from '../components/Warning'

const LoginPage = () => {
  return (
    <>
    <section className="hidden lg:block">
       <Navbar />
      <Warning/>

     </section>
     <section className="block lg:hidden">
       <Sidebar />
     </section>
     <Login/>
     <Footer/>

   </>
  )
}

export default LoginPage