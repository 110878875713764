import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import logo from "../assets/_equitylogo.png";
import uplogo from "../assets/Upscaled_logo.png";
import stock from "../assets/stock-service.webp";
import Footer from "./Footer";
import { motion } from "framer-motion";
import { useEffect } from "react";
import WhoWeAre from "./Aboout Us  Comp/WhoWeAre";
import OurVisionMission from "./Aboout Us  Comp/OurVisionMission";
import WhyChooseUS from "./Aboout Us  Comp/WhyChooseUS";
import Warning from "./Warning";
// import { type } from "os";

const AboutUs = () => {
  const imgVariants = {
    initial: {
      opacity: 0,
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="hidden lg:block">
        <Navbar />
        <Warning/>
      </section>
      <section className="block lg:hidden">
        <Sidebar />
      </section>
      <section className="w-full ">
        <WhoWeAre />
        <motion.img
          // initital="initial"
          // whileInView="animate"
          // variants={imgVariants}
          src={uplogo}
          className="aspect-square mx-auto w-1/2 md:w-1/4 mix-blend-multiply"
          loading="lazy"
        />
        <OurVisionMission />
        <img
          initital="initial"
          whileInView="animate"
          variants={imgVariants}
          src={stock}
          className="aspect-square mx-auto w-1/2 md:w-1/4"
          loading="lazy"
        />

        <WhyChooseUS />

        <div className="sm:w-3/4 md:4/5  lg:w-full mx-auto h-max md:min-h-[40%]  rounded flex flex-col justify-center items-center  ">
          <div className="">
            <div className="relative">
              <h1 className=" font-semibold text-center">
                <span className="text-primary">Our </span>
                Registration Details
              </h1>
            </div>
          </div>

          <table className="w-full  lg:w-7/12 h-full mx-auto  details-table table-fixed my-5">
            <thead className="text-sm text-pretty text-primary">
              <tr>
                <td>Category</td>

                <td>Details</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Registered Name</td>
                <td>RAJ RISHI </td>
              </tr>

              <tr>
                <td>Registration Number</td>
                <td>INH000016700</td>
              </tr>

              <tr>
                <td>Type of Registration</td>
                <td>Individual</td>
              </tr>
              <tr>
                <td>Validity</td>
                <td>June 2024 - Perpetual</td>
              </tr>

              <tr>
                <td>Contact Person</td>
                <td>RAJ RISHI </td>
              </tr>

              <tr>
                <td>Contact Email</td>
                <td>info@equityresearchmart.in</td>
              </tr>
              <tr>
                <td>Contact Phone</td>
                <td>+91 70044 93381</td>
              </tr>
              <tr>
                <td>Compliance Email</td>
                <td className="text-wrap break-words whitespace-break-spaces">
                  compliance@equityresearchmart.in
                </td>
              </tr>

              <tr>
                <td>Office Addresses</td>
                <td>
                  Avantika Cinema Road, Bari Dariyapur Jamalpur, Bihar Sharif,
                  Bihar-811214
                </td>
              </tr>
              <tr>
                <td> Address</td>
                <td>
                  Avantika Cinema Road, Bari Dariyapur Jamalpur, Bihar Sharif,
                  Bihar-811214
                </td>
              </tr>
              <tr>
                <td>SEBI regional/local office address</td>
                <td>
                  3rd Floor, Udyog Bhavan, Gandhi Maidan East, Patna, Bihar
                  800001
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
