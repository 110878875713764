import React from "react";
import { Link, useParams } from "react-router-dom";
import { Tilt } from 'react-tilt'
const defaultOptions = {
	reverse:        false,  // reverse the tilt direction
	max:            25,     // max tilt rotation (degrees)
	perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
	scale:          1.1,    // 2 = 200%, 1.5 = 150%, etc..
	speed:          1000,   // Speed of the enter/exit transition
	transition:     true,   // Set a transition on enter/exit.
	axis:           "X",   // What axis should be disabled. Can be X or Y.
	reset:          true,    // If the tilt effect has to be reset on exit.
	easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
}
const ServicesCard = ({heading,description,src,serviceId}) => {
// const serviceId = useParams()

  return (
    <><Tilt
    options={defaultOptions}
      className="hidden lg:block group text-left p-6  bg-gradient-to-br from-[#01295c] via-slate-900 to-[#000000] text-white rounded-md object-contain   transition-all duration-100  mt-6 space-y-12"
      style={{ backgroundImage: { src } }}
    >
      <h3 className="group-hover:scale-95 font-bold  text-accent ">{heading}</h3>
      <p className="group-hover:scale-115 text-lg font-semibold " >{description.split('.')[0] + '.'}</p>
      <Link to={`/equity-service/${serviceId}`} className="group-hover:scale-115 text-accent group-hover:underline group-hover:text-accent transition-all duration-200 "> Learn More </Link>
    </Tilt>
        <div
          className="block lg:hidden group text-left p-6  bg-gradient-to-br from-[#01295c] via-slate-900 to-[#000000] text-white rounded-md object-contain   transition-all duration-100  mt-6 space-y-12"
          style={{ backgroundImage: { src } }}
        >
          <h3 className="group-hover:scale-95 font-bold  text-accent ">{heading}</h3>
          <p className="group-hover:scale-115 text-lg font-semibold " >{description.split('.')[0] + '.'}</p>
          <Link to={`/equity-service/${serviceId}`} className="group-hover:scale-115 text-accent group-hover:underline group-hover:text-accent transition-all duration-200 "> Learn More </Link>
        </div></>
    
  );
};

export default ServicesCard;
