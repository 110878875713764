import React from 'react'
import Grievance from '../components/Grievance'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Warning from '../components/Warning'

const GrievancePage = () => {
  return (
    <>
      <section className="hidden lg:block">
        <Navbar />
      <Warning/>

      </section>
      <section className="block lg:hidden">
        <Sidebar />
      </section>
      <Grievance />
      <Footer/>
    </>
  )
}

export default GrievancePage