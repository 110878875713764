import React from "react";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Warning from "./Warning";

const Packages = () => { 
  const prices = [
    {
      heading: "ERM Holding Pack",
      id: "EHP",
      description:
        "A product designed for short term investors who are looking for a passive investment strategy. It follows the Classic Value Investing Strategy. Value investing is the process of doing detective work to find these secret sales on stocks and buying them at a discount compared to how the market values them. In return for buying and holding these value stocks for the long term, thereby delivering meaningful results over the long run.",
      src: "",
      prices: [
        { monthly: null },
        { quarterly: null },
        { halfYearly: null },
        { yearly: "25000" },
      ],
      features: [
        "Positional Research report in Equity Cash Segment",
        "5-6 Stocks WITH MISPRICED OPPORTUNITY BASED ON VALUE INVESTING.",
        "Holding period can be 6 months to 12 months.",
        "Follow up on Quarterly updates via communication channels.",
        "Our team periodically monitors these stocks given and portfolio rebalancing applied wherever required. (Early entry or exit can be possible depending on market and pricing scenario).",
      ],
      investmentRules: [
        "Always invest with equal amount of investment. Don't put all your eggs in one basket.",
        "Don't average in any stock because it will double your risk. Follow proper risk management.",
        "Don't be emotional or greedy while trading. Always take profit home whenever you see good profit and don't panic in case of reverse situation because ups and downs are part of market. Hold your position with proper stop loss.",
        "Don't force yourself to be a trader for doing more number of trades because it may spoil quality of trades. We always wait for proper indication to trade and we trade in stable market.",
        "Always take risk of your spare capital only. Don't take loan or borrow money from anyone and trade in stock market.",
        "This is not a primary source of income. Stock market trading or investments are subject to market risk, so don't depend on this earning.",
      ],
    },
    {
      heading: "ERM WEALTH",
      id: "EW",
      description:
        "A product designed for medium to long term investors who are looking for a strategy which can beat index returns over time.\n While our expert analysts dive into the fundamentals and technical analytics to find the hidden gems in the market, all you need is to sit back and watch your investments grow manifold.",
      src: "",
      prices: [
        { monthly: null },
        { quarterly: "31000" },
        { halfYearly: null },
        { yearly: "70000" },
      ],
      features: [
        "Invest in a highly personalized and concentrated Stock basket of 20-25 stocks.",
        "Combination of mid-caps and large caps Stocks.",
        "Stock selection done on Strong past track record for a combination of short term, mid term and long term which can be for 3-5 Years.",
        "Follow up on Quarterly updates via communication channels.",
        "Experts periodically monitor these stocks given and change applied wherever required. (Early entry or exit can be possible depending on market and pricing scenario).",
      ],
      investmentRules: [],
    },
    {
      heading: "ERM SWING PACK",
      id: "ESP",
      description:
        "A product dedicated for traders who intend to grab potential trades from Intraday Cash market with swing movement with 2-3 days holding. Traders employ technical analysis to determine when conditions are right to enter either long or short, and then to exit. Short term investors who are looking for an opportunity in a volatile market. It follows the momentum stocks which can give movement by holding good stocks in cash segment.",
      src: "",
      prices: [
        { monthly: null },
        { quarterly: "21000" },
        { halfYearly: "35000" },
        { yearly: null },
      ],
      features: [
        "Only Cash Segment Intraday recommendations in Stocks.",
        "Total Recommendations – Weekly 2-3.",
        "Service Mode- WHATSAPP/SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
    },
    {
      heading: "ERM Equity & Derivative Combo",
      id: "EEDC",
      description:
        "A product designed for traders who wish to trade with a combination of Equity and Derivative Segment on their Cash investments. ERM Equity Derivative Combo is an exclusive product for speculators who want to trade in the whole equity segment.",
      src: "",
      prices: [
        { monthly: "14000" },
        { quarterly: "34000" },
        { halfYearly: "49999" },
        { yearly: null },
      ],
      features: [
        "Intraday Equity trading, Recommendations in Equity Cash, Futures, and Options.",
        "Designed to provide personalized 1-3 Equity recommendations on a daily basis.",
        "Purely intraday calls.",
        "Total Recommendations – 1-3 Daily.",
        "Service Mode- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
    },
    {
      heading: "ERM Stock OPTION PACK",
      id: "ESOP",
      description:
        "A product dedicated for traders who wish to trade only in Stock Options derivative segment. Options trading tips rely heavily on Options Greeks i.e. Delta, Gamma, Vega, & Theta which are integral to derivative trading. Call & Put tips are aspects of Bullish & Bearish market which helps rise in premium price of Option.",
      src: "",
      prices: [
        { monthly: "12999" },
        { quarterly: "33000" },
        { halfYearly: "59000" },
        { yearly: null },
      ],
      features: [
        "Only Options Buying recommendations Intraday/Positional recommendations in Stocks Options.",
        "Traders who trade in this capacity are generally classified as speculators.",
        "Total Recommendations – 1-2 Daily.",
        "Service Mode- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
    },
    {
      heading: "ERM Index (Future & Option)",
      id: "EIFO",
      description:
        "A product dedicated for traders who wish to trade only on Bank Nifty and Nifty Future and Options Weekly/Monthly Expiry special calls. Options trading tips rely heavily on Options Greeks i.e. Delta, Gamma, Vega, & Theta which are integral to derivative trading. Call & Put tips are aspects of Bullish & Bearish market which helps rise in premium price of Option.",
      src: "",
      prices: [
        { monthly: null },
        { quarterly: "35000" },
        { halfYearly: "61000" },
        { yearly: null },
      ],
      features: [
        "Recommendations – 1-3 daily.",
        "Service Mode- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
    },
    {
      heading: "ERM COMMODITY (Base Metal & Energy)",
      id: "ECBME",
      description:
        "A product for traders who aim to trade in the Commodity market. Intraday based recommendation in MCX commodity in the segment of Base Metals and Natural Gas with occasional holding. Ideal for clients who want to trade in the MCX Segment.",
      src: "",
      prices: [
        { monthly: "9000" },
        { quarterly: "21000" },
        { halfYearly: "35000" },
        { yearly: null },
      ],
      features: [
        "Recommendation Frequency- 20-22 monthly.",
        "Mode of recommendation- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
    },
    {
      heading: "ERM Full MCX Pack",
      id: "EFMP",
      description:
        "A product designed for traders who aim to grab high potential trades from the Commodity market. Intraday based recommendation in MCX commodity in the segment of Precious metals, Base Metals, and Natural Gas with occasional holding. Ideal for clients who want to trade in MCX Commodities.",
      src: "",
      prices: [
        { monthly: "15000" },
        { quarterly: "40000" },
        { halfYearly: "70000" },
        { yearly: null },
      ],
      features: [
        "Recommendation Frequency- 1-2 Trades Daily.",
        "Mode of recommendation- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
    },
    {
      heading: "ERM INDEX Option PACK",
      id: "EIOP",
      description:
        "A product dedicated for traders who wish to trade only on Bank Nifty, Nifty, Finnifty Weekly Monthly Expiry special calls. Only Options Buying recommendations. Intraday recommendations in Index Options.",
      src: "",
      prices: [
        { monthly: "9000" },
        { quarterly: "22000" },
        { halfYearly: "35000" },
        { yearly: null },
      ],
      features: [
        "Only Options Buying recommendations.",
        "Intraday recommendations in Index Options.",
        "Total Recommendations – 1-2 recommendations.",
        "Service Mode- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
    },
  ];
  const packageVariants = {
    initial: {
      opacity: 0,
      y: -1000,
    },
    animate: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3,
        duration: 1,
      },
    }),
  };
  const [expanded, setExpanded] = React.useState(0);
  const navigate = useNavigate();
  return (
    <>
      {
        // popup && <Popup close={setPopup}  value={ popup }/>
      }
      <Navbar />
      <Warning />
      <section className="block lg:hidden">
        <Sidebar />
      </section>
      <section className="grid place-items-center w-full bg-slate-200">
        <div className="p-4 h-[50vh] flex items-center justify-center flex-col ">
          <h1 className="font-extrabold text-center my-4 ">
            {" "}
            <span className="text-primary">Our</span> Packages
          </h1>
          <p className="text-center text-xl text-slate-700 max-w-2xl self-center ">
            Unlock your trading potential with our expertly crafted research services. Gain access to precise market insights and personalized
            guidance for unparalleled success.
          </p>
        </div>
        <section className="bg-white w-full h-full my-10 bg-gradient-to-b from-[#01295c]  via-slate-900 to-[#000000] min-h-screen p-4 space-y-4">
          <div className=" group/card  gap-4  w-full grid grid-cols-1 md:grid-cols-3  max-w-7xl mx-auto my-5">
            {prices.map((item, i) => {
              return (
                <motion.div
                  key={i}
                  custom={i}
                  initial="initial"
                  animate="animate"
                  variants={packageVariants}
                  className="  rounded-md p-4 flex flex-col  relative  my-2 bg-primary/40 text-white select-none"
                >
                  <h1 className=" text-white font-semibold text-xl  ">
                    {item.heading}
                  </h1>

                  <table className="table-fixed w-full border-2 rounded ">
                    <tr>
                      <th>Monthly</th>
                      <td>
                        {item.prices[0].monthly ? item.prices[0].monthly : "-"}
                      </td>
                      <td>
                        {item.prices[0].monthly ? (
                          <button
                            onClick={() =>
                              navigate(
                                `/equity-package-payment?service=${item.heading}&price=${item.prices[0].monthly}&duration=1`
                              )
                            }
                            className="border border-gray-600 rounded p-1 md:px-2 py-1 hover:bg-primary/60 hover:text-gray-200 bg-accent-dark"

                          >
                            Buy Now
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Quarterly</th>
                      <td>
                        {item.prices[1].quarterly
                          ? item.prices[1].quarterly
                          : "-"}
                      </td>
                      <td>
                        {item.prices[1].quarterly ? (
                          <button
                            onClick={() =>
                              navigate(
                                `/equity-package-payment?service=${item.heading}&price=${item.prices[1].quarterly}&duration=3`
                              )
                            }
                            className="border border-gray-600 rounded p-1 md:px-2 py-1 hover:bg-primary/60 hover:text-gray-200 bg-accent-dark"
                          >
                            Buy Now
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Half-Yearly</th>
                      <td>
                        {item.prices[2].halfYearly
                          ? item.prices[2].halfYearly
                          : "-"}
                      </td>
                      <td>
                        {item.prices[2].halfYearly ? (
                          <button
                            onClick={() =>
                              navigate(
                                `/equity-package-payment?service=${item.heading}&price=${item.prices[2].halfYearly}&duration=6`
                              )
                            }
                           className="border border-gray-600 rounded p-1 md:px-2 py-1 hover:bg-primary/60 hover:text-gray-200 bg-accent-dark"
                          >
                            Buy Now
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Yearly</th>
                      <td>
                        {item.prices[3].yearly ? item.prices[3].yearly : "-"}
                      </td>
                      <td>
                        {item.prices[3].yearly ? (
                          <button
                            onClick={() =>
                              navigate(
                                `/equity-package-payment?service=${item.heading}&price=${item.prices[3].yearly}&duration=12`
                              )
                            }
                           className="border border-gray-600 rounded p-1 md:px-2 py-1 hover:bg-primary/60 hover:text-white bg-accent-dark  "
                          >
                            Buy Now
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                    {/* </>

            ))} */}
                  </table>
                
                </motion.div>
              );
            })}
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default Packages;
