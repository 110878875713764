import React, { useEffect, useRef, useState } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import Stepper from "./Stepper";
import KYCStep1 from "./KYCSteps/KYCStep1";
import KYCStep2 from "./KYCSteps/KYCStep2";
import KYCStep3 from "./KYCSteps/KYCStep3";
import apiendpoints from "../apiendpoints.json";
import { toast, ToastContainer } from "react-toastify";
import PDFViewver from "./PDFViewer";
// import ProcessHtmlFile from "../service/PDFReader";
const KYC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [fname, setfname] = useState("");
  const [mname, setmname] = useState("");
  const [lname, setlname] = useState("");
  const [fatherName, setfatherName] = useState("");
  const [dob, setdob] = useState("");
  const [altNumber, setaltNumber] = useState("");
  const [gender, setgender] = useState("");
  const [nationality, setnationality] = useState("");
  const [age, setAge] = useState(0);
  const [occupation, setoccupation] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [pan, setpan] = useState("");
  const [adhaar, setadhaar] = useState("");
  const [address, setadress] = useState("");
  const [panDoc, setpanDoc] = useState("");
  const [adhaarDoc, setadhaarDoc] = useState("");
  const [addressDoc, setaddressDoc] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [zipcode, setzipcode] = useState("");
  // const [agreement, setagreement] = useState(false);
  const [verifyOtp, setverifyOtp] = useState("");
  const [isVerified, setisVerified] = useState(false);
  const [agreement64, setagreement64] = useState("");
  const agreementRef = useRef(null);
  const addressDocRef = useRef(null);
  const panDocRef = useRef(null);
  const aadhaarDocRef = useRef(null);
  const SADButtonRef = useRef(null);
  const SARFuttonRef = useRef(null);
  const handleClick = () => {
    if (SADButtonRef.current) {
      SADButtonRef.current.click();
    }
  };
  const steps = [
    "Demat",
    "Verify",
    "Personal Info",
    "Document Upload",
    "Agreement",
  ];
  const dematRef = useRef(null);

  // handlenext handles validation based on the current step of form
  const handleNext = () => {
    if (currentStep === 0) {
      if (!dematRef.current.checked) {
        toast.error("Please confirm if you have a demat account");
        return;
      }
    } else if (currentStep === 1) {
      if (!fname.trim()) {
        toast.error("First Name is required");
        return;
      }

      if (!lname.trim()) {
        toast.error("Last Name is required");
        return;
      }
      if (!email.trim()) {
        toast.error("Email is required");
        return;
      }
      if (!phone.trim() || phone.length !== 10) {
        toast.error("Valid Phone number is required (10 digits)");
        return;
      }
      // if (!isVerified) {
      //   toast.error("OTP is not verified");
      //   return;
      // }
    } else if (currentStep === 2) {
      if (!fname.trim()) {
        toast.error("First Name is required");
        return;
      }
      if (!lname.trim()) {
        toast.error("Last Name is required");
        return;
      }
      if (!email.trim()) {
        toast.error("Email is required");
        return;
      }
      if (!phone.trim() || phone.length !== 10) {
        toast.error("Valid Phone number is required (10 digits)");
        return;
      }
    } else if (currentStep === 3) {
      if (!pan.trim()) {
        toast.error("Pan Number is required");
        return;
      }

      if (!adhaar.trim() || adhaar.length !== 12) {
        toast.error("Aadhaar Number is required");
        return;
      }
      if (!address.trim()) {
        toast.error("Address is required");
        return;
      }
      if (!zipcode.trim()) {
        toast.error("Zipcode is required");
        return;
      }
      if (!state.trim()) {
        toast.error("State is required");
        return;
      }
      if (!city.trim()) {
        toast.error("City is required");
        return;
      }
      if (!addressDocRef.current.value) {
        toast.error("Address Proof is required");
        addressDocRef.current.value = ""
        return;
      }
      if (!panDocRef.current.value) {
        toast.error("Pan Proof is required");
        panDocRef.current.value = ""
        return;
      }
      if (!aadhaarDocRef.current.value) {
        toast.error("Aadhaar is required");
        aadhaarDocRef.current.value = ""
        return;
      }
    } else {
      if (!agreementRef.current.checked) {
        toast.error("Please Accept The Agrrement First");
        return;
      }
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleOtp = async () => {
    try {
      let response = await fetch(
        `${apiendpoints.equityUrl}/Mail/SendEmailOtp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ toEmail: email, _otpReason: 2 }),
        }
      );
      let data = response;
      //console.log(data);
      if (response.ok) {
        toast.success("OTP Sent Successfully");
      }
    } catch (error) {
      //console.log(error);
      if (error) {
        toast.error("Could Not Send OTP");
      }
    }
  };

  const handleOtpVerification = async () => {
    try {
      let response = await fetch(`${apiendpoints.equityUrl}/Mail/OtpVerified`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ toEmail: email, otp: verifyOtp }),
      });
      let data = response;
      //console.log(data);
      if (response.ok) {
        toast.success("OTP Verified Successfully");
        setisVerified(true);
      } else {
        toast.error("Could Not Verify OTP");
      }
    } catch (error) {
      //console.log(error);

      toast.error("Could Not Verify OTP");

      setisVerified(false);
    }
  };
  const date = new Date();
  function formatDate(date) {
    if (!date) return ""; // Return empty string if date is null or undefined

    const d = new Date(date); // Create a Date object from the input
    let year = d.getFullYear(); // Get full year
    let month = (d.getMonth() + 1).toString().padStart(2, "0"); // Get month and pad it to 2 digits
    let day = d.getDate().toString().padStart(2, "0"); // Get day and pad it to 2 digits

    return `${year}-${month}-${day}`; // Return formatted string
  }
  const handleAge = (v) => {
    //console.log(v);
    setAge(v);
  };

  const handleAgreement = () => {
    SARFuttonRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const formData = {
        firstName: fname,
        middleName: mname,
        lastName: lname,
        emailId: email,
        mobileNumber: phone,
        aadharId: adhaar,
        panno: pan,
        aadharPath: addressDoc,
        panpath: panDoc,
        address: address,
        addressProf: addressDoc,
        gender: gender,
        nationality: nationality,
        isActive: 1,
        submitdDate: new Date(),
        kYCAgreementPath: agreement64,
      };

      //console.log(formData);
      if (!agreementRef.current.checked) {
        toast.error("Please Accept The Agrrement First");
        setLoader(false);

        return;
      }
      let response = await fetch(`${apiendpoints.equityUrl}/api/KYCRegister`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Ensure the content type is set to JSON
        },
        body: JSON.stringify(formData),
      });
      let data = response;
      //console.log(data);

      if (!!response.ok) {
        toast.success("KYC Submitted Succesfully");
      }
      setLoader(false)

      if (response.ok) {
        setfname("");
        setlname("");
        setmname("");
        setadhaar("");
        setAge(0);
        setpan("");
        setzipcode("");
        setstate("");
        setcity("");
        setdob("");
        setemail("");
        setadress("");
        setoccupation("");
        setnationality("");
        setaltNumber("");
        setfatherName("");
        dematRef.current.checked = false;
        agreementRef.current.checked = false;
        if (addressDocRef.current) {
          addressDocRef.current.value = "";
        }
        if (panDocRef.current) {
          panDocRef.current.value = "";
        }
        if (aadhaarDocRef.current) {
          aadhaarDocRef.current.value = "";
        }
        if (agreement64.current) {
          aadhaarDocRef.current.value = "";
        }
        setCurrentStep(0);
      }
      setLoader(false);
    } catch (error) {
      //console.log(error);
      toast.error("There Was A Problem While Submmiting Your KYC");
    }
    setLoader(false);
  };
  useEffect(() => { }, []);
  return (
    <div className="w-full  p-4 bg-gradient-to-b from-[#01295c]  via-slate-900 to-[#000000] relative z-10  ">
      <div
        className="absolute w-full h-full bg-accent top-0 left-0  -z-10"
        style={{ clipPath: "polygon(0 44%, 0% 100%, 100% 100%)" }}
      ></div>
      <div
        className="absolute w-full h-full bg-primary top-0 left-0  -z-10 shadow-md"
        style={{ clipPath: "polygon(100% 22%, 0% 100%, 100% 100%)" }}
      ></div>
      <h1 className="font-bold my-8 text-center text-white">
        Let's get
        <span className="text-primary"> started</span>
      </h1>
      <p className="text-accent text-lg text-center">
        Complete Your KYC Today to Get Started
      </p>
      <div className="h-0 w-0 overflow-hidden">
        <PDFViewver
          name={fname + mname + lname}
          address={address}
          ref={{ SARFuttonRef, SADButtonRef }}
          setagreement64={setagreement64}
        />
      </div>
      <div className="mx-auto shadow-md max-w-4xl min-h-[60vh] md:min-h-[80vh] my-10 p-4 rounded-md flex flex-col justify-stretch items-center bg-white z-40 relative">
        {/* <div className="mx-auto flex justify-center items-center px-4 mb-4"> */}
        <Stepper
          steps={steps}
          currentStep={currentStep}
          setStep={setCurrentStep}
          touchEnabled={false}
        />
        {/* </div> */}

        <form
          className=" *:mx-auto grid grid-cols-1  w-full gap-4"
          onSubmit={(e) => e.preventDefault()}
        // enctype="multipart/form-data"
        >
          <section
            className={`w-full col-span-1  ${currentStep === 0 ? "block" : "hidden"
              } grid justify-center items-center gap-2`}
          >
            <p className="text-2xl mb-4  text-center">
              Please Ensure That You Have A Demat Account And Trading Account
              Before Moving Forward.
            </p>
            <div className="mb-4  w-full font-bold text-center flex justify-center items-center gap-2">
              <span>I have a demat account</span>
              <input
                type="checkbox"
                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500    placeholder:italic placeholder:font-normal focus:outline-none"
                ref={dematRef}
                required
              />
            </div>
          </section>
          <section
            className={`w-full col-span-1  ${currentStep === 1 ? "block" : "hidden"
              } grid grid-cols-1 md:grid-cols-3 gap-2 `}
          >
            <KYCStep1
              fname={fname}
              mname={mname}
              lname={lname}
              email={email}
              handleOtp={handleOtp}
              handleOtpVerification={handleOtpVerification}
              phone={phone}
              setemail={setemail}
              setfname={setfname}
              setmname={setmname}
              setlname={setlname}
              setphone={setphone}
              isVerified={isVerified}
              verifyOtp={verifyOtp}
              setverifyOtp={setverifyOtp}
            />
          </section>
          <section
            className={`w-full col-span-1  ${currentStep === 2 ? "block" : "hidden"
              } grid md:grid-cols-3  gap-2 `}
          >
            <KYCStep2
              age={age}
              setAge={handleAge}
              altNumber={altNumber}
              dob={dob}
              setdob={setdob}
              fatherName={fatherName}
              name={fname + " " + mname + " " + lname}
              occupation={occupation}
              setaltNumber={setaltNumber}
              setfatherName={setfatherName}
              setgender={setgender}
              setnationality={setnationality}
              setoccupation={setoccupation}
            />
          </section>
          <section
            className={`w-full col-span-1  ${currentStep === 3 ? "block" : "hidden"
              } grid md:grid-cols-3  gap-2 `}
          >
            <KYCStep3
              address={address}
              setaddressDoc={setaddressDoc}
              addressDoc={addressDoc}
              adhaar={adhaar}
              adhaarDoc={adhaarDoc}
              city={city}
              pan={pan}
              panDoc={panDoc}
              setadhaar={setadhaar}
              setadhaarDoc={setadhaarDoc}
              setadress={setadress}
              setcity={setcity}
              setpan={setpan}
              setpanDoc={setpanDoc}
              setstate={setstate}
              setzipcode={setzipcode}
              state={state}
              zipcode={zipcode}
              ref={{ addressDocRef, panDocRef, aadhaarDocRef }}
            />
          </section>
          <section
            className={`w-full col-span-1 my-5 ${currentStep === 4 ? "block" : "hidden"
              } grid md:grid-cols-3  gap-2 `}
          >
            <div className="col-span-3 h-[50vh] border-2 overflow-hidden overflow-y-scroll ">
              <div id="">
                <div className="p-2 px-4 md:p-6 lg:p-2 space-y-5 mx-auto w-full grid place-content-center text-left mt-5 bg-white text-black rounded ">
                  <h1 style={{ textAlign: "center" }}>SERVICE AGREEMENT</h1>

                  <p>
                    This Service Agreement ("Agreement") is made on{" "}
                    {formatDate(date)} by and between:{ }
                  </p>

                  <div className="party" style={{ marginBottom: "20px" }}>
                    <strong>
                      <span className="capitalize">
                        {fname + " " + mname + " " + lname}
                      </span>
                    </strong>
                    <br />
                    Address: {address}
                    <br />
                    (hereinafter referred to as "Client")
                  </div>

                  <div className="party" style={{ marginBottom: "20px" }}>
                    <strong>Raj Rishi</strong>
                    <br />
                    Address: Sample Address
                    <br />
                    Registration No.: INH000016700
                    <br />
                    (hereinafter referred to as "Research Analyst")
                  </div>

                  <p>
                    WHEREAS, the Research Analyst is registered with the Securities and Exchange Board of India Registration No. INH000016700
                    (SEBI) under the Research Analyst Regulations and is engaged in providing research and analysis
                    services pertaining to the share and commodity markets.
                  </p>

                  <h2 style={{ textAlign: 'center' }}>NOW, THEREFORE</h2>
                  <p>
                    <span className="text-lg font-semibold text-md">NOW, THEREFORE, </span>In consideration of the mutual covenants contained herein, the parties agree as follows:
                  </p>

                  <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                    1. Scope of Services
                  </div>
                  <p>The Research Analyst shall provide the following services to the Client:</p>
                  <ul style={{ marginLeft: '20px' }} className="list-disc">
                    <li>Research reports on shares and commodities.</li>
                    <li>Research recommendations / Calls / Tips will be based on thorough analysis.</li>
                    <li>Market updates and forecasts.</li>
                    <li>Customized Research services as per Client's requirements.</li>
                    <li>Mode of Services will be SMS.</li>
                  </ul>

                  <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                    2. Fees and Payment Terms
                  </div>

                  <ul style={{ marginLeft: '20px' }} className="list-disc">
                    <li>
                      The Client agrees to pay the Research Analyst a fee/Service Charge for the services rendered
                      purely and exclusively for research services.
                    </li>
                    <li>
                      Client is aware about that, research analyst only gives research services and collecting the fee only.
                      All the service provided by the research analyst described in Annexure A-1 is understood and in knowledge
                      of client and agreed to avail the services .All the Service description  attached in Annexure A-1 is a part
                      and parcel of this agreement.
                    </li>
                    <li>
                      All the consideration paid to the Equity Research mart by the client is for the
                      purely and exclusively for the Research services only.
                    </li>
                    <li>
                      Client is well aware that equity research mart is only Research analyst provides
                      research services they are not providing any other services to the clients like Demat/Ac,
                      Portfolio Management, Mutual Fund, Insurance, Fixed Deposit or Any Investment Schemes.
                    </li>
                    <li>
                      Equity research mart appoints the best people in the research team who are experienced and trained. They study the market minutely and then provide the research.
                      We give the research recommendation through a procedure and analysis. Subscribing with us is purely client’s own decision.
                      That is why once client make a decision of joining us we do not have any cancellation, holding or refund policy of research service charges/fees paid by the client to us.
                    </li>
                  </ul>

                  <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                    3. Other Terms and Conditions of research searvice
                  </div>

                  <ul style={{ marginLeft: '20px' }} className="list-disc">
                    <li>
                      Equity research mart does not provide any profit warranty or
                      guarantee in any of our Product/ Services offered & Subscribed by the client
                    </li>
                    <li>
                      All the email communication from Equity research mart is done through official mail IDs which are having domain name as www.equityresearchmart.in and message services through server ID as “………………..” only . Please do not accept or communicate on emails or sms which are not from www.equityresearchmart.in domain.
                    </li>
                    <li>
                      Past Performance is not an indicator of future Returns. The entire analyst commentary and track sheet provided on www.equityresearchmart.in is provided for information purposes only.
                    </li>
                    <li>
                      Client understands and acknowledges that there is a high degree of risk involved in trading securities.
                    </li>
                    <li>
                      Past results of any trader published on our website are not an indicator of future returns by that trader, and are not an indicator of future returns which be realized by you, investment in the market is subject to Market Risk. Our clients are extremely significant to us hence in order to serve you better, we require our clients to understand each and every details regarding research analyst.
                    </li>
                    <li>
                      Research services will be deliver to client through SMS and you will receive the sms by sender name "Equity Research Mart" .In case of any changes in sender ID we will communicate to you by email.
                    </li>
                    <li>Investment in markets is subjected to market risk and is to be borne by client only</li>
                    <li>
                      Please be fully informed regarding the risks and cost associated with trading in the financial markets, it is one of the riskiest investment forms possible
                    </li>
                    <li>
                      (Equity Cash / Futures / Options /Commodity and currency derivatives) trading on margin involve high risk, and not suitable for all investors you may lose the more amount initially invested by you.
                    </li>
                    <li>
                      Client understands and agreed that, the fees charged by Equity Research Mart in lieu of services / products provided to me are "fair & reasonable".  Client declares that, all the subscription/service charges  paid to the Equity Research Mart by him exclusively and purely for  Research services only and It is paid from  his/her owned Bank Account / Cards / Wallets / only. No any third party is involved in it and the same I will follow in future transactions. Kindly accept all the payments on utmost good faith.
                    </li>
                    <li>
                      Client confirms that prior to sign this agreement he is gone through all the services and the charges and read all the terms and conditions, privacy policy and disclaimer along with the details of products/service on the website of www.equityresearchmart.in; and by signing this agreement client confirms that he /she understood them fully and agreed on.
                    </li>
                    <li>The language of this document is understood to client. All the difficulties and interpretations are translated and verbally communicated to the client and the client understood all of them and agreed on.</li>
                  </ul>

                  <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                    4. Limitation of Liability
                  </div>
                  <ul style={{ marginLeft: '20px' }} className="list-disc">
                    <li>
                      The Research Analyst shall not be liable for any direct, indirect, incidental, or consequential damages
                      arising from the Client’s use of the research and recommendations provided.
                    </li>
                  </ul>

                  <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                    5. Governing Law
                  </div>
                  <ul style={{ marginLeft: '20px' }} className="list-disc">
                    <li>This Agreement shall be governed by and construed in accordance with the laws of India.</li>
                  </ul>

                  <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                    6. Dispute Resolution
                  </div>
                  <ul style={{ marginLeft: '20px' }} className="list-disc">
                    <li> Any disputes arising out of or in connection with this Agreement shall be resolved through arbitration
                      in accordance with the Arbitration and Conciliation Act.</li>
                  </ul>

                  <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                    7. Entire Agreement
                  </div>
                  <ul style={{ marginLeft: '20px' }} className="list-disc">
                    <li>This Agreement constitutes the entire understanding between the parties and supersedes all prior
                      discussions, agreements, or understandings.</li>
                    <li>the parties hereto have executed this Service Agreement as of the date first above written.</li>
                  </ul>

                  <div className="signatures">
                    <div>
                      <strong>Client: </strong>
                      <br />
                      <span className="capitalize">
                        {fname + " " + mname + " " + lname}
                      </span>
                      <br />
                      Date: {formatDate(date)}
                    </div>
                    <div>
                      <strong>Research Analyst:</strong>
                      <br />
                      Raj Rishi
                      <br />
                      Date: {formatDate(date)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={handleClick}
              className="border rounded bg-accent-light mx-auto p-1 w-full "
            >
              Download Agreement
            </button>

            <p className="col-span-3 ">
              <input
                type="checkbox"
                className="m-auto inset-0 p-0  "
                onChange={handleAgreement}
                ref={agreementRef}
              />
              <span className="pl-2">
                I hereby declare that I have read, understood and personally
                accomplished entire Agreement & Risk Profiling Questionnaire and
                that the answers I have given are accurate. I understand the
                risk involved in investing in equities. I will exercise my own
                independent judgment in subscribing the suitable package/s (if
                any) as per my Risk Profile Score.
              </span>
            </p>
          </section>
        </form>

        <div className="w-full flex justify-between items-center mt-4  bottom-0 p-2  ">
          <button
            className={`p-[1px] pr-[2px] md:p-2 border border-primary text-primary rounded flex items-center gap-2 hover:border-accent hover:text-accent ${currentStep === 0
              ? "border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 cursor-not-allowed"
              : "border-primary text-primary"
              }`}
            onClick={handleBack}
            disabled={currentStep === 0}
          >
            <IoArrowBack /> Back
          </button>
          {currentStep === steps.length - 1 ? (
            <button
              className=" p-[1px] pl-[2px] md:p-2 border border-primary text-primary rounded flex items-center gap-2  hover:border-accent hover:text-accent"
              onClick={handleSubmit}
            //  disabled={currentStep === steps.length - 1}
            >
              {!loader ? (
                <span>Submit</span>
              ) : (
                <span className="loader submit "></span>
              )}
            </button>
          ) : (
            <button
              className=" p-[1px] pl-[2px] md:p-2 border border-primary text-primary rounded flex items-center gap-2  hover:border-accent hover:text-accent"
              onClick={handleNext}
              disabled={currentStep === steps.length - 1}
            >
              Next <IoArrowForward />
            </button>
          )}
        </div>
      </div>
      <ToastContainer position="top-right"
        autoClose={3000}
        limit={2}
        hideProgressBar={true}
        newestOnTop={true} />
    </div>
  );
};

export default KYC;
