import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";

const Remarks = ({ setremarkScreen, approve, reject, data ,approvemessage,rejectmessage}) => {
  // console.log("remarks ", data);
  const [remarks, setremarks] = useState("");

  const handleAction =async (opt) => {
   
    if (opt == 1) {
      // console.log(opt)   
    await  approve(data.id, data.email || data.emailId, remarks);
      toast.success(approvemessage);
      setremarkScreen(false);
    }
    if (opt == 2) {
      // console.log(opt)   

    await  reject(data.id, data.email || data.emailId, remarks);
      toast.success(rejectmessage);
      setremarkScreen(false);
    }
  };

  return (
    <div className="mt-5 md:mt-0 p-4  z-30 w-11/12 md:w-4/5 rounded border shrink-0 mx-auto bg-white ">
      <div className="flex w-full justify-between">
        <h4>Add Remarks</h4>
        <span onClick={() => setremarkScreen(false)} className="cursor-pointer">
          <IoClose size={30} />
        </span>
      </div>

    
      <textarea
        className="bg-slate-100 text-black rounded border w-full h-full overflow-y-scroll p-2"
        value={remarks}
        rows={5}
        onChange={(e) => setremarks(e.target.value)}
      ></textarea>
      <button
        onClick={() => handleAction(data.opt)}
        className="rounded border p-1 text-lg bg-primary text-white"
      >
        Submit
      </button>
    </div>
  );
};

export default Remarks;
