import React, { forwardRef } from "react";
import convertTo64 from "../../service/converToBase64";
import { fileTypeVaidation } from "../../service/fileTypeValidation";
import { toast, ToastContainer } from "react-toastify";
const KYCStep3 = forwardRef(
  (
    {
      setpan,
      pan,
      adhaar,
      setadhaar,
      address,
      setadress,
      panDoc,
      setpanDoc,
      addressDoc,
      setaddressDoc,
      adhaarDoc,
      setadhaarDoc,
      state,
      city,
      zipcode,
      setcity,
      setzipcode,
      setstate,
    },
    ref
  ) => {
    return (
      <>
        <div className="mb-4 w-full md:max-w-md">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Pan <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={pan}
              onChange={(e) => setpan(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your PAN"
              pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full md:max-w-md">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Aadhaar <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={adhaar}
              onChange={(e) => {
                const regex = /^[0-9]*$/;
                const value = e.target?.value;
                if (!regex.test(value)) {
                  toast.error("Please Enter Numeric Digits Only");
                } else if (value.length <= 12) {
                  setadhaar(value);
                } else {
                  toast.error("Please Enter a maximum of 12 digits");
                }
              }}
              // onChange={(e) => setadhaar(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Aadhaar"
              required
            />
          </label>
        </div>

        <div className="mb-4 w-full md:max-w-md">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Address <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={address}
              onChange={(e) => setadress(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Address"
              required
            />
          </label>
        </div>

        <div className="mb-4 w-full md:max-w-md">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Pan <sup className="text-red-500">*</sup>
            <input
              type="file"
              ref={ref.panDocRef}
              // accept="application/pdf "
              // value={panDoc}
              onChange={async (e) => {
                setpanDoc(await fileTypeVaidation(e, "pdf"));
              }}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Demat"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full md:max-w-md">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Address Proof <sup className="text-red-500">*</sup>
            <input
              type="file"
              ref={ref.addressDocRef}
              // accept="application/pdf"
              // value={addressDoc}
              onChange={async (e) => {
                setaddressDoc(await fileTypeVaidation(e, "pdf"));
              }}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Address"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full md:max-w-md">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Aadhaar <sup className="text-red-500">*</sup>
            <input
              type="file"
              ref={ref.aadhaarDocRef}
              // accept="application/pdf"
              // value={adhaarDoc}
              onChange={async (e) => {
                setadhaarDoc(await fileTypeVaidation(e, "pdf"));
              }}
              // Now it will log the correct base64 value

              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Aadhaar"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full md:max-w-md">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            State <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={state}
              onChange={(e) => setstate(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="State"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full md:max-w-md">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            City <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={city}
              onChange={(e) => setcity(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Address"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full md:max-w-md">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Zipcode <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={zipcode}
              onChange={(e) => setzipcode(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="000000"
              required
            />
          </label>
        </div>
      </>
    );
  }
);
export default KYCStep3;
// {firstName: "roshan", middleName: "", lastName: "bhat", emailId: "bhatroshan@gmail.com",…}
// aadharId
// :
// "123456789097"
// aadharPath
// :
// "data:application/pdf;base64,JVBERi0xLjMKJbrfrOAKM
// address
// :
// "delhi"
// addressProf
// :
// "data:application/pdf;base64,JVBERi0xLjMKJbrfrOAKM
// emailId
// :
// "bhatroshan@gmail.com"
// firstName
// :
// "roshan"
// gender
// :
// "m"
// isActive
// :
// 1
// kYCAgreementPath
// :
// ""
// lastName
// :
// "bhat"
// middleName
// :
// ""
// mobileNumber
// :
// "0987654321"
// nationality
// :
// "indian"
// panno
// :
// "Pan675436"
// panpath
// :
// "data:application/pdf;base64,JVBERi0xLjMKJbrfrOAKM
// submitdDate
// :
// "2024-10-16T17:57:09.182Z"
