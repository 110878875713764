import React, { forwardRef } from "react";
import html2pdf from "html2pdf.js";

const GeneratePdf = forwardRef(({ name, address, setagreement64 }, ref) => {
  const generatePDF = (action) => {
    const content = document.getElementById("pdf-content"); 

    const options = {
      margin: 0,
      filename: `${name}-service-agreement.pdf`,
      html2canvas: { scale: 2 }, // High-quality rendering
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    switch (action) {
      case "download":
        html2pdf()
          .from(content)
          .set(options)
          .save(); // Generate and download the PDF
        break;
    
      case "generate": // Corrected the spelling of "generate"
      console.log("generating")
        html2pdf()
          .from(content)
          .set(options)
          .outputPdf("blob")
          .then((pdfBlob) => {
            // Convert the Blob to Base64 string
            convertToBase64(pdfBlob).then((base64String) => {
              console.log("Generated Base64 PDF:", base64String); 
              setagreement64(base64String);  // Set Base64 string state
            }).catch(error => {
              console.error("Error converting to Base64:", error);
            });
          })
          .catch(error => {
            console.error("Error generating PDF:", error);
          });
        break;
    
      default:
        console.error("Invalid action type:", action); // Optional: Add a default case for invalid actions
    }
    
  };

  // Function to convert Blob to Base64
  const convertToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // Remove base64 metadata
      reader.onerror = reject;
      reader.readAsDataURL(blob); // Read the Blob as a Data URL (Base64)
    });
  };

  const date = new Date();
  function formatDate(date) {
    if (!date) return ""; // Return empty string if date is null or undefined

    const d = new Date(date); // Create a Date object from the input
    let year = d.getFullYear(); // Get full year
    let month = (d.getMonth() + 1).toString().padStart(2, "0"); // Get month and pad it to 2 digits
    let day = d.getDate().toString().padStart(2, "0"); // Get day and pad it to 2 digits

    return `${year}-${month}-${day}`; // Return formatted string
  }
  return (
    <div>
      <div id="pdf-content" style={{ padding: "10px 60px" }}>
        <div className="  mx-auto w-full grid place-content-center text-left  bg-white text-black rounded ">
          <h1 style={{ textAlign: "center" }}>SERVICE AGREEMENT</h1>

          <p>
            This Service Agreement ("Agreement") is made on {formatDate(date)}{" "}
            by and between:{}
          </p>

          <div className="party" style={{ marginBottom: "20px" }}>
            <strong>
              <span className="capitalize">{name}</span>
            </strong>
            <br />
            Address: {address}
            <br />
            (hereinafter referred to as "Client")
          </div>

          <div className="party" style={{ marginBottom: "20px" }}>
            <strong>Raj Rishi</strong>
            <br />
            Address: Sample Address
            <br />
            Registration No.: INH000016700
            <br />
            (hereinafter referred to as "Research Analyst")
          </div>

          <p>
          WHEREAS, the Research Analyst is registered with the Securities and Exchange Board of India Registration No. INH000016700.(SEBI) under the Research Analyst Regulations and is engaged in providing research and analysis services pertaining to the share and commodity markets.
          </p>

          <h2 style={{ textAlign: "center" }}>NOW, THEREFORE</h2>
          <p>
            In consideration of the mutual covenants contained herein, the
            parties agree as follows:
          </p>

          <div
            className="section-title"
            style={{
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            1. Scope of Services
          </div>

          <ul style={{ marginLeft: "20px" }}>
            <p>The Research Analyst shall provide the following services to the Client :</p>
            <li>Research reports on shares and commodities.</li>
            <li>
              Research recommendations / Calls / Tips will be based on thorough
              analysis.
            </li>
            <li>Market updates and forecasts.</li>
            <li>Customized advisory services as per Client's requirements.</li>
            <li>Mode of Services will be SMS.</li>
          </ul>

          <div
            className="section-title"
            style={{
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            2. Fees and Payment Terms
          </div>

          <ul style={{ marginLeft: "20px" }}>
            <li>
            	The Client agrees to pay the Research Analyst a fee/Service Charge for the services rendered purely and exclusively for research services. 
            </li>
            <li>
              The Client is aware about that, research analyst only gives research services and collecting the fee only. All the service provided by the research analyst described in Annexure A-1 is understood and in knowledge of client and agreed to avail the services .All the Service description  attached in Annexure A-1 is a part and parcel of this agreement.  
            </li>
            <li>
              All the consideration paid to the Equity Research mart by the client is for the purely and exclusively for the Research services only. 
            </li>
            <li>
              Client is well aware that equity research mart is only Research analyst <br /><br /> provides research services they are not providing any other services to the clients like Demat/Ac, Portfolio Management, Mutual Fund, Insurance, Fixed Deposit or Any Investment Schemes.
            </li>
            <li>Equity research mart appoints the best people in the research team who are experienced and trained. They study the market minutely and then provide the research. We give the research recommendation through a procedure and analysis. Subscribing with us is purely client’s own decision. That is why once client make a decision of joining us we do not have any cancellation, holding or refund policy of research service charges/fees paid by the client to us.  </li>
          </ul>
          <div
            className="section-title"
            style={{
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            3. Other Terms and Conditions
          </div>

          <ul style={{ marginLeft: "20px" }}>
            <li>
            	Equity research mart does not provide any profit warranty or guarantee in any of our Product/ Services offered & Subscribed by the client
            </li>
            <li>
            	All the email communication from Equity research mart is done through official mail IDs which are having domain name as www.equityresearchmart.in and message services through server ID as “………………..” only . Please do not accept or communicate on emails or sms which are not from www.equityresearchmart.in domain.
            </li>

            <li>
            Past Performance is not an indicator of future Returns. The entire analyst commentary and track sheet provided on www.equityresearchmart.in is provided for information purposes only.
            </li>
            <li>
              Client understands and acknowledges that there is a high degree of risk involved in trading securities.
            </li>
            <li>
            Past results of any trader published on our website are not an indicator of future returns by that trader, and are not an indicator of future returns which be realized by you, investment in the market is subject to Market Risk. Our clients are extremely significant to us hence in order to serve you better, we require our clients to understand each and every details regarding research analyst.
            </li>
            <li>
            	Research services will be deliver to client through SMS and you will receive the sms by sender name "Equity Research Mart" .In case of any changes in sender ID we will communicate to you by email.
            </li>
            <li>
              Investment in markets is subjected to market risk and is to be borne by client only
            </li>
            <li>
            	Please be fully informed regarding the risks and cost associated with trading in the financial markets, it is one of the riskiest investment forms possible
            </li>
            <li>
            	(Equity Cash / Futures / Options /Commodity and currency derivatives) trading on margin involve high risk, and not suitable for all investors you may lose the more amount initially invested by you.  
            </li>
            <li>
              Client understands and agreed that, the fees charged by Equity Research Mart in lieu of services / products provided to me are "fair & reasonable".  Client declares that, all the subscription/service charges  paid to the Equity Research Mart by him exclusively and purely for  Research services only and It is paid from  his/her owned Bank Account / Cards / Wallets / only. No any third party is involved in it and <br />
              <br /><br></br>the same I will follow in future transactions. Kindly accept all the payments on utmost good faith.
            </li>
            <li>
            	Client confirms that prior to sign this agreement he is gone through all the services and the charges and read all the terms and conditions, privacy policy and disclaimer along with the details of products/service on the website of www.equityresearchmart.in; and by signing this agreement client confirms that he or she understood them fully and agreed on.
            </li>
            <li>
            	The language of this document is understood to client. All the difficulties and interpretations are translated and verbally communicated to the client and the client understood all of them and agreed on.
            </li>
          </ul>
          <div
            className="section-title"
            style={{
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            4. Limitation of Liability
          </div>
          <p>
          	The Research Analyst shall not be liable for any direct, indirect, incidental, or consequential damages arising from the Client’s use of the research and recommendations provided.
          </p>

          <div
            className="section-title"
            style={{
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            5. Governing Law
          </div>
          <p>
            This Agreement shall be governed by and construed in accordance with
            the laws of India.
          </p>

          <div
            className="section-title"
            style={{
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            6. Dispute Resolution
          </div>
          <p>
            Any disputes arising out of or in connection with this Agreement
            shall be resolved through arbitration in accordance with the
            Arbitration and Conciliation Act.the parties hereto have executed this Service Agreement as of the date first above written.
          </p>

          <div
            className="section-title"
            style={{
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            7. Entire Agreement
          </div>
          <p>
            This Agreement constitutes the entire understanding between the
            parties and supersedes all prior discussions, agreements, or
            understandings.
          </p>

          <div className="signatures">
            <div>
              <strong>Client: </strong>
              <br />
              <span className="capitalize">{name}</span>
              <br />
              Date: {formatDate(date)}
            </div>
            <div>
              <strong>Research Analyst:</strong>
              <br />
              Raj Rishi
              <br />
              Date: {formatDate(date)}
            </div>
          </div>
        </div>
      </div>
      <button onClick={()=>generatePDF("generate")} ref={ref.SARFuttonRef} style={{"display":"none"}}>
        Generate PDF
      </button>
      <button onClick={()=>generatePDF("download")} ref={ref.SADButtonRef} hidden>
        download PDF
      </button>
    </div>
  );
});

export default GeneratePdf;
