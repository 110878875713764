const imageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default async function convertTo64(e) {
  const file = e.target.files[0];
  if (file) {
    try {
      const base64 = await imageToBase64(file);
      //console.log(base64); // This will correctly log the base64 string
      return base64; // Return the base64 string
    } catch (error) {
      console.error("Error converting image to base64: ", error);
    }
  }
  return null; // If no file is provided
}
