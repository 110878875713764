import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHAComponent from "./GoogelRecaptcha/googleCaptcha";
import { motion } from "framer-motion";
import apiendpoints from "../apiendpoints.json";
// import { Toast } from "react-toastify/dist/components";

interface FormData {
  nameUser: string;
  mobile: string;
  message: string;
}

const modalVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
  exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
};

const ModalContent: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [formData, setFormData] = useState<FormData>({
    nameUser: "",
    mobile: "",
    message: "",
  });
  const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(false);
  const handleCaptchaChange = (value: any) => {
    setIsCaptchaValid(!!value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(apiendpoints.enquiryUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Request sent successfully!");
        onClose();
      } else {
        toast.error("Failed to send request. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="bg-black/40 flex items-start   justify-center  overflow-y-auto overflow-x-hidden top-0 absolute    right-0 z-50 left-0  w-full md:inset-0 mx-auto">
      <motion.div
        className="absolute p-2 w-full max-w-lg max-h-full"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="relative bg-white rounded-lg ">
          <div className="flex items-center justify-between p-3 md:p-3 rounded-t border-b">
            <h3 className=" font-semibold text-gray-900 underline underline-offset-4 decoration-[#2db928]">
              Request a call?
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-red-500 hover:text-white rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close </span>
            </button>
          </div>
          <form onSubmit={handleSubmit} className="p-4 md:p-5">
            <div className="mb-4">
              <label
                htmlFor="nameUser"
                className="block text-sm font-semibold text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="nameUser"
                name="nameUser"
                placeholder="Mention your Full Name"
                value={formData.nameUser}
                onChange={handleChange}
                className="mt-1 p-2 w-full border border-gray-300 bg-gray-200/70 placeholder:items-center placeholder:text-sm placeholder:font-light rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="mobile"
                className="block text-sm font-semibold text-gray-700"
              >
                Mobile Number
              </label>
              <input
                id="mobile"
                name="mobile"
                placeholder="Mention your Contact Number"
                value={formData.mobile}
                type="tel"
                onChange={(e) => {
                  const regex = /^[0-9]*$/;
                  const value: any = e.target?.value;
                  if (!regex.test(value)) {
                    // toast.error("Please Enter Numbers Only");
                    return;
                  } else if (value.length <= 10) {
                    handleChange(e); // update form data if valid
                    //console.log(value);
                  } else {
                    // toast.error("Please Enter a maximum of 10 digits");
                    return
                  }
                }}
                className="mt-1 bg-gray-200/70 placeholder:items-center placeholder:text-sm placeholder:font-light p-2 w-full border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-semibold text-gray-700"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Write a message/comments.."
                className="mt-1 bg-gray-200/70 placeholder:items-center placeholder:text-sm placeholder:font-light p-2 w-full border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                rows={4}
                required
              ></textarea>
            </div>
            <p className="text-gray-600 mt-4 text-xs font-medium mb-2 px-1">
              Kindly fill this captcha to submit!
            </p>
            <ReCAPTCHAComponent onChange={handleCaptchaChange} />

            <button
              type="submit"
              className={` text-white py-2 px-4 mt-3 rounded-lg ${
                isCaptchaValid
                  ? "bg-[#2db928] hover:bg-blue-600"
                  : "bg-[#2db928]/90"
              }`}
              disabled={!isCaptchaValid}
            >
              Submit
            </button>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default ModalContent;
