import React, { useEffect, useRef, useState } from "react";
import { IoClose, IoHandLeft, IoInformation, IoLogOut, IoMail, IoMenu, IoPeople } from "react-icons/io5";
import { FaUserPlus } from "react-icons/fa";
import Navbar from "../Navbar";
import { Link, useNavigate } from "react-router-dom";
import { PiMoneyDuotone } from "react-icons/pi";

const AdminSidebar = ({ setactiveTab }) => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    localStorage.removeItem("user");
    navigate("/");
  };
  const ref = useRef(null)
  
  return (
    <>
      <span className="text-black p-2 absolute z-30 cursor-pointer " onClick={()=>ref.current.style.left = "0"}>

            <IoMenu size={30}/>
      </span>
      <div ref={ref} className=" md:w-[25%]  bg-gray-700 h-screen absolute top-0 left-0  text-white text-xl  *:cursor-pointer px-2 z-50" >
        <ul  className="transition-all duration-200 ">
          <li className="pt-4" onClick={()=>ref.current.style.left = "-100%"}>
            <IoClose size={30} />
          </li>
          <li
            onClick={() => {
             ref.current.style.left = "-100%"
            }}

          >
            <Link to="/equity-admin-panel/enquiries"
            className="flex gap-2 items-center p-2 hover:bg-slate-500 hover:font-semibold hover:tracking-wide text-white mt-5"
            >
            {" "}
            <IoMail />
            <span>Enquiries</span>
            </Link>
          </li>
          <li
            onClick={() => {
             ref.current.style.left = "-100%"
            }}
          >
            <Link to="/equity-admin-panel/admin-registration"
            className="flex gap-2 items-center p-2 hover:bg-slate-500 hover:font-semibold hover:tracking-wide text-white"
            >

            <span >
              <FaUserPlus />
            </span>
            <span>Register Admin</span>
            </Link>
          </li>
          <li
            onClick={() => {
             ref.current.style.left = "-100%"
            }}
          >
            {" "}
            <Link to="/equity-admin-panel/complaints"
            className="flex  gap-2 items-center p-2 hover:bg-slate-500 hover:font-semibold hover:tracking-wide text-white"
            >

            <span>
              <IoHandLeft />
            </span>
            <span>Complaints</span>
            </Link>
          </li>
          <li
            onClick={() => {
              ref.current.style.left = "-100%"
            }}
          >
            {" "}
            <Link to="/equity-admin-panel/kyc-approval"
            className="flex gap-2   items-center p-2 hover:bg-slate-500 hover:font-semibold hover:tracking-wide text-white"
            >

            <span>
              <IoInformation />
            </span>
            <span>KYC</span>
            </Link>
          </li>
          <li
            onClick={() => {
             ref.current.style.left = "-100%"
            }}
          >
            {" "}
            <Link to="/equity-admin-panel/pending-purchase-approval"
            className="flex  gap-2 items-center p-2 hover:bg-slate-500 hover:font-semibold hover:tracking-wide text-white"
            >
            
            <span>
              <PiMoneyDuotone />
            </span>
            <span>Confirm Payments</span>
            </Link>
          </li>
          <li
            onClick={() => {
              ref.current.style.left = "-100%"
            }}
          >
            {" "}
            <Link to="/equity-admin-panel/customers"
            className="flex  gap-2 items-center p-2 hover:bg-slate-500 hover:font-semibold hover:tracking-wide text-white" 
            >
            
            <span>
              <IoPeople />
            </span>
            <span>Customers</span>
            </Link>
          </li>
          <li
            className="flex  gap-2 items-center p-2 hover:bg-slate-500 hover:font-semibold hover:tracking-wide"
            onClick={handleLogout}
          >
            {" "}
            <span>
              <IoLogOut />
            </span>
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AdminSidebar;
