import React from "react";
import { motion } from "framer-motion";

const WhoWeAre = () => {
  return (
    <div className="w-full min-h-[60vh]  px-5 bg-gradient-to-b from-[#01295c] via-slate-900 to-[#000000] text-white flex flex-col justify-center items-center">
      {/* Title Section */}
      <motion.div
        className="relative mb-8"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        <h1 className="text-4xl lg:text-5xl font-bold text-center mb-2">
          <span className="text-primary">Who{' ' } </span>
          We{' ' }  
          <span className="underline underline-offset-8 decoration-[#2db928] ml-3">
             Are {' ' }
          </span>
        </h1>
      </motion.div>

      {/* Description Section */}
      <motion.p
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
        className="max-w-7xl text-center lg:text-left text-lg lg:text-xl leading-relaxed px-2 md:px-0"
      >
        Welcome to Equity Research Mart, emerging SEBI-registered insights with
        advanced research. Empowering you with actionable recommendations,
        comprehensive solutions, and personalized support. Our commitment to
        ethics and a client-first approach guides you towards financial success.
      </motion.p>
    </div>
  );
};

export default WhoWeAre;
