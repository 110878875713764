import React, { useEffect, useRef, useState } from "react";
import { IoArrowBack, IoDownload } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import apiendpoints from "../../apiendpoints.json";
import { fileTypeVaidation } from "../../service/fileTypeValidation";
import PDFViewver from "../PDFViewer";

const CustomerKYC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { id } = useParams();

  const [fname, setfname] = useState("");
  const [mname, setmname] = useState("");
  const [lname, setlname] = useState("");
  const [fatherName, setfatherName] = useState("");
  const [dob, setdob] = useState("");
  const [altNumber, setaltNumber] = useState("");
  const [gender, setgender] = useState("");
  const [nationality, setnationality] = useState("");
  const [age, setAge] = useState(0);
  const [occupation, setoccupation] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [pan, setpan] = useState("");
  const [adhaar, setadhaar] = useState("");
  const [address, setadress] = useState("");
  const [panDoc, setpanDoc] = useState("");
  const [adhaarDoc, setadhaarDoc] = useState("");
  const [addressDoc, setaddressDoc] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [agreement, setagreement] = useState(false);
  const SADButtonRef = useRef(null);
  const fileRef = useRef(null);
  const addressDocRef = useRef(null);
  const panDocRef = useRef(null);
  const aadhaarDocRef = useRef(null);
  const handleDobChange = (e) => {
    const dobValue = e.target.value;
    setdob(dobValue);

    const birthDate = new Date(dobValue);
    const currentYear = new Date().getFullYear();

    // Calculate the difference in years
    const age = currentYear - birthDate.getFullYear();

    setAge(age);
  };

  useEffect(() => {
    const fetchPayment = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${apiendpoints.equityUrl}/api/GetPayments/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        // const data = await response
        const res = await response.json();
        setData(res[0]);
        try {
          const [fname, ...rest] = res[0]?.name.split(" ");
          const lname = rest && rest.pop(); // Get the last element from the rest array
          const mname = rest && rest.join(" "); // Join the remaining elements as middle names
          setfname(fname);
          setlname(lname);
          setmname(mname);
          setemail(res[0]?.email)
          setphone(res[0].phoneNo)
          setpan(res[0].panno)
        } catch (err) {
          console.log(err);
        }
        console.log("payment", data);
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchPayment();
  }, []);
  const handleKYC = async (e) => {
    console.log("kyc started")
    e.preventDefault();
    try {
      if (!fname.trim()) {
        toast.error("First Name is required");
        return;
      }
      if (!lname.trim()) {
        toast.error("Last Name is required");
        return;
      }
      if (!email.trim() ) {
        toast.error("Email is required");
        return;
      }
      if (!phone.trim() || phone.length !== 10) {
        toast.error("Valid Phone number is required (10 digits)");
        return;
      }
      if (!adhaar.trim()) {
        toast.error("Aadhar is required");
        return;
      }
      if (!pan.trim()) {
        toast.error("PAN is required");
        return;
      }
      if (!address.trim()) {
        toast.error("Address is required");
        return;
      }
      if (!addressDoc.trim()) {
        toast.error("Address Document is required");
        return;
      }
      if (!panDoc.trim()) {
        toast.error("PAN Document is required");
        return;
      }
      if (!adhaarDoc.trim()) {
        toast.error("Aadhaar Document is required");
        return;
      }
      if (!dob.trim()) {
        toast.error("Date of Birth is required");
        return;
      }
      if (!gender.trim()) {
        toast.error("Gender is required");
        return;
      }
      if (!nationality.trim()) {
        toast.error("Nationality is required");
        return;
      }
      if (!agreement) {
        toast.error("Agreement is required");
        return;
      }

      const formData = {
        firstName: fname,
        middleName: mname,
        lastName: lname,
        emailId: email,
        mobileNumber: phone,
        aadharId: adhaar,
        panno: pan,
        aadharPath: adhaarDoc,
        panpath: panDoc,
        address: address,
        addressProf: addressDoc,
        gender: gender,
        
        nationality: nationality,
        isActive: 1,
        submitdDate: new Date(),
      };

      let response = await fetch(`${apiendpoints.equityUrl}/api/KYCRegister`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Ensure the content type is set to JSON
        },
        body: JSON.stringify(formData),
      });
      let data = response;
      console.log(data);
      if (!response.ok) {
        toast.error("Something Went Wrong");
      }
      if (!!response.ok) {
        toast.success("KYC Submitted Succesfully");
      }
      if (response.ok) {
        setfname("");
        setlname("");
        setmname("");
        setaddressDoc("");
        setadhaar("");
        setAge(0);
        setadhaarDoc("");
        setpan("");
        setpanDoc("");
        setzipcode("");
        setstate("");
        setcity("");
        setdob("");
        setemail("");
        setadress("");
        setoccupation("");
        setnationality("");
        setaltNumber("");
        setfatherName("");
      }
    } catch (error) {
      //console.log(error);
      toast.error("There Was A Problem While Submmiting Your KYC");
    }
  };
  const handleClick = () => {
    if (SADButtonRef.current) {
      SADButtonRef.current.click();
    }
  };
  const uploadSignedAgreement = async (e, id) => {
    let agree = await fileTypeVaidation(e, "pdf");
    let response = await fetch(`${apiendpoints.equityUrl}/api/KYCUpdate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        entityId: "equity",
      },
      body: JSON.stringify({ signedKYCAgreementPath: agree, id: id }),
    });
    setagreement(true);
    if (!response.ok) {
      toast.error("Could Not Upload ");
    }
  };
  return (
    <section className="w-full border mt-10 max-w-7xl mx-auto rounded">
      <div className="h-0 w-0 overflow-hidden">
        <PDFViewver
          name={fname +" "+ mname +" "+ lname}
          address={address}
          ref={{SADButtonRef}}
        />
      </div>
      <header className="flex gap-10 items-center justify-start  w-full border border-b-2 p-2  ">
        <span
          className="text-black hover:scale-110 cursor-pointer w-max  "
          onClick={() =>
            navigate("/equity-admin-panel/pending-purchase-approval")
          }
        >
          <IoArrowBack size={30} />
        </span>
        <h4 className="underline">Customer KYC</h4>
      </header>

      <form className="p-5 md:p-10 flex flex-col md:flex-row flex-wrap gap-5" onSubmit={handleKYC}>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            First Name <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={fname }
              onChange={(e) => setfname(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your First Name"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Middle Name
            <input
              type="text"
              value={mname }
              onChange={(e) => setmname(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Middle Name"
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Last Name <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={lname }
              onChange={(e) => setlname(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Last Name"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Email <sup className="text-red-500">*</sup>
            <input
              type="email"
              value={ email}
              onChange={(e) => setemail(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Email"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm ">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Phone <sup className="text-red-500">*</sup>
            <input
              type="tel"
              value={phone}
              pattern="^(0|[1-9][0-9]*)$"
              onChange={(e) => {
                const regex = /^[0-9]*$/;
                const value = e.target?.value;
                if (!regex.test(value)) {
                  // toast.error("Please Enter Numbers Only");
                  return;
                } else if (value.length <= 10) {
                  setphone(value);
                } else {
                  // toast.error("Please Enter a maximum of 10 digits");
                  return;
                }
              }}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Phone"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Father's Name <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={fatherName}
              onChange={(e) => setfatherName(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Father's Full Name"
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Alternate Number <sup className="text-red-500">*</sup>
            <input
              type="tel"
              value={altNumber}
              onChange={(e) => {
                const regex = /^[0-9]*$/;
                const value = e.target?.value;
                if (!regex.test(value)) {
                  // toast.error("Please Enter Numbers Only");
                  return;
                } else if (value.length <= 10) {
                  setaltNumber(value); // update form data if valid
                  //console.log(value);
                } else {
                  // toast.error("Please Enter a maximum of 10 digits");
                  return;
                }
              }}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Full Name"
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm ">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Nationality <sup className="text-red-500">*</sup>
            <select
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              onChange={(e) => setnationality(e.target.value)}
            >
              <option value={""}>Choose Nationality</option>

              <option value={"indian"}>Indian</option>
              <option value={"other"}>Other</option>
            </select>
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Pan <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={pan}
              onChange={(e) => setpan(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your PAN"
              // pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Aadhaar <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={adhaar}
              onChange={(e) => {
                const regex = /^[0-9]*$/;
                const value = e.target?.value;
                if (!regex.test(value)) {
                  toast.error("Please Enter Numeric Digits Only");
                } else if (value.length <= 12) {
                  setadhaar(value);
                } else {
                  toast.error("Please Enter a maximum of 12 digits");
                }
              }}
              // onChange={(e) => setadhaar(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Aadhaar"
              required
            />
          </label>
        </div>
 
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Address <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={address}
              onChange={(e) => setadress(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Address"
              required
            />
          </label>
        </div>

        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Pan <sup className="text-red-500">*</sup>
            <input
              type="file"
              ref={panDocRef}
              // accept="application/pdf "
              // value={panDoc}
              onChange={async (e) => {
                setpanDoc(await fileTypeVaidation(e, "pdf"));
              }}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Demat"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Address Proof <sup className="text-red-500">*</sup>
            <input
              type="file"
              ref={addressDocRef}
              // accept="application/pdf"
              // value={addressDoc}
              onChange={async (e) => {
                setaddressDoc(await fileTypeVaidation(e, "pdf"));
              }}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Address"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Aadhaar <sup className="text-red-500">*</sup>
            <input
              type="file"
              ref={aadhaarDocRef}
              // accept="application/pdf"
              // value={adhaarDoc}
              onChange={async (e) => {
                setadhaarDoc(await fileTypeVaidation(e, "pdf"));
              }}
              // Now it will log the correct base64 value

              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Aadhaar"
              required
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Gender <sup className="text-red-500">*</sup>
            <select
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              onChange={(e) => setgender(e.target.value)}
            >
              <option value={""}>Choose Gender</option>

              <option value={"m"}>Male</option>
              <option value={"f"}>Female</option>
              <option value={"o"}>Other</option>
            </select>
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            DOB <sup className="text-red-500">*</sup>
            <input
              type="date"
              value={dob}
              onChange={handleDobChange}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Age <sup className="text-red-500">*</sup>
            <input
              type="numer"
              value={age}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              readOnly
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm ">
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Occupation <sup className="text-red-500">*</sup>
            <input
              type="text"
              value={occupation}
              onChange={(e) => setoccupation(e.target.value)}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
              placeholder="Your Occupation"
            />
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm ">
          <label className="block mb-2 text-sm font-semibold text-gray-900 ">
            Download Agreement <sup className="text-red-500">*</sup>
            <button
              onClick={handleClick}
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-1"
            >
              Download Agreement
            </button>
          </label>
        </div>
        <div className="mb-4 w-full max-w-sm ">
          <label className="block mb-2 text-sm font-semibold text-gray-900 ">
            Upload Agreement <sup className="text-red-500">*</sup>
            <input
              type="file"
              onChange={(e) => {
                uploadSignedAgreement(e, data.id);
              }}
              ref={fileRef}
              required
              hidden
            />
            <button
              className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 mt-1"
              onClick={() => fileRef.current.click()}
            >
              <abbr title="Upload Signed Document">
                Upload Signed Agreement
              </abbr>
            </button>
          </label>
        </div>
        <div className="mb-4 w-full flex items-center ">
          <button
            type="submit"
            disabled={!agreement}
            class={`w-max px-2 py-1 bg-blue-700 text-white rounded-lg mx-auto place-self-center  hover:bg-blue-400 ${
              !agreement && "bg-gray-300"
            }`}
          >
            Save
          </button>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        limit={2}
        hideProgressBar={true}
        newestOnTop={true}
      />
    </section>
  );
};

export default CustomerKYC;
