import React from "react";

const ComplaintsMobile = () => {
  return (
    <section className=" text-center my-10 px-4">
      {/* this month */}
      <h4>Data for the month ending - August 2024</h4>
      <div className="overflow-x-scroll">
        <table className=" max-w-7xl  space-y-5 mx-auto p-2  border my-5  details-table ">
          <tbody className="">
            <tr>
              <th>Sr. No.</th>
              <td>0</td>
            </tr>

            <tr>
              <th>Received from</th>
              <td></td>
            </tr>
            <tr>
              <th>Pending at the end of last month</th>
              <td>August</td>
            </tr>
            <tr>
              <th>Received</th>
              <td>0</td>
            </tr>
            <tr>
              <th>Resolved</th>
              <td>0</td>
            </tr>
            <tr>
              <th>Total Pending</th>
              <td>0</td>
            </tr>
            <tr>
              <th>Pending complaints 3months</th>
              <td>0</td>
            </tr>
            <tr>
              <th>Average Resolution time (in days)</th>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* thisyear */}
      <h4>Trend of monthly disposal of complaints</h4>
      <div className="overflow-x-scroll">
        <table className=" max-w-7xl  space-y-5 mx-auto p-2  border my-5 details-table">
          <tbody>
            <tr>
              <th>Sr. No.</th>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <th>Month</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Carried forward from previous month</th>
              <td>May</td>

              <td>August</td>
            </tr>
            <tr>
              <th>Received</th>
              <td>0</td>

              <td>0</td>
            </tr>
            <tr>
              <th>Resolved</th>
              <td>0</td>

              <td>0</td>
            </tr>
            <tr>
              <th>Pending</th>
              <td>0</td>

              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* previous */}
      <h4>Trend of annual disposal of complaints</h4>
      <div className="overflow-x-scroll">
        <table className="overflow-x-scroll max-w-7xl  space-y-5 mx-auto p-2  border my-5 details-table">
          <tbody>
            <tr>
              <th>Year</th>
              <td>2023-2024</td>
            </tr>
            <tr>
              <th>Carried forward from previous annual</th>
              <td>0</td>
            </tr>
            <tr>
              <th>Received</th>
              <td>0</td>
            </tr>
            <tr>
              <th>Resolved</th>
              <td>0</td>
            </tr>
            <tr>
              <th>Pending</th>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ComplaintsMobile;
