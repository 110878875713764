

const LegalDisclaimer = () => {
  return (
    <div className="w-full bg-white min-h-screen py-10">
      <div className="space-y-5 mx-auto p-2 px-4 md:p-10 lg:p-2 w-full grid place-content-center text-left   py-10 max-w-7xl">
        <h1 className=" font-semibold text-center">Legal Disclaimer</h1>
        <p>
          <strong>Equity Research Mart</strong> is SEBI registered as a Research
          Entity in terms of SEBI (Research Analyst) Regulations, 2014. SEBI
          Research Analyst No: Registration Number: INH000016700. Equity
          Research Mart or its associates have not been debarred/ suspended by
          SEBI or any other regulatory authority for accessing/dealing in the
          securities market.
        </p>

        <p>
          The information and views in the reports, our website, and all
          services (“Research Information”) are believed to be reliable, but we
          do not represent or warrant its accuracy or completeness. Investors
          and clients are advised to independently evaluate the market
          conditions/risks involved before making any trading or investment
          decisions.
        </p>

        <p>
          The Research Information is not intended to be an exhaustive statement
          on the financial instruments, issuers, markets, or developments
          referred to therein. Reasonable care has been taken to ensure that the
          Research Information is not misleading or untrue at the time of
          publication. Any opinions expressed in the Research Information are
          subject to change without notice. The analysis contained is based on
          numerous assumptions, which may result in materially different
          outcomes.
        </p>

        <p>
          Information in the specific research reports is for private use only
          and should not form the basis of any contract or commitment. It does
          not constitute an offer or solicitation to buy or sell any securities.{" "}
          <a href="https://www.equityresearchmart.in">Equity Research Mart</a>{" "}
          is not responsible for errors of fact or opinion. Investment in the
          securities market carries risks, and investors should use their own
          judgment when making decisions.
        </p>

        <p>
          Users have no right to forward or share our reports, calls, SMS, or
          any information provided by us, directly or indirectly. Serious legal
          action can be taken if this occurs.
        </p>

        <p>
          By accessing{" "}
          <a href="https://www.equityresearchmart.in">
            https://www.equityresearchmart.in
          </a>
          , you agree to the terms and conditions mentioned herein. The site may
          contain views of individual contributors, which do not necessarily
          represent the views of the management. Linked sites are not under our
          control, and we are not responsible for their contents or updates.
        </p>

        <p>
          All services provided are at the user's own risk, and Equity Research
          Mart does not accept any liability for errors, omissions, or failures
          in services, including SMS delivery delays due to technical reasons.
        </p>

        <p>
          We expressly disclaim any warranties implied by law. By using this
          website, you agree to submit any legal disputes to the jurisdiction of
          Munger/Patna, Bihar, India.
        </p>

        <p>
          All rights reserved. Unauthorized reproduction, copying, or
          distribution of the content is prohibited without prior permission
          from Equity Research Mart. We reserve the right to terminate accounts
          of those who violate proprietary rights and take legal action where
          necessary.
        </p>

        <p>
          By filling out forms on this website, you consent to receive calls or
          SMS from Equity Research Mart, even if your number is on the National
          'Do Not Disturb' registry. All disputes will be first subject to
          mediation, and if necessary, arbitration under Indian Law.
        </p>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default LegalDisclaimer;
