import { useState, useEffect } from "react";
// import Toast from "./Toast";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHAComponent from "../GoogelRecaptcha/googleCaptcha";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import apiendpoints from "../../apiendpoints.json";

const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = () => toast("Invalid Credentials!!");
  const handleCaptchaChange = (value) => {
    setIsCaptchaValid(!!value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      EamilId: loginEmail,
      Password: loginPassword,
      entityID:2
    };

    try {
      const response = await fetch(
        `${apiendpoints.equityUrl}/User/Login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "entity":"equity"
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      localStorage.setItem("user", JSON.stringify(data));
      toast.success("Login Successfull");
      navigate("/equity-admin-panel/enquiries");
      //   login(data);

      setLoginEmail("");
      setLoginPassword("");
      setLoading(false);
    } catch (error) {
      //   setToast({ message: "Login failed", type: "error" });
      // notify()
      toast.error("Invalid Details");
      setLoading(false);

    }
  };
useEffect(()=>{
window.scrollTo(0,0)
},[])
  return (
    <section className="bg-white">
      <div className="w-full mx-auto max-w-xl flex flex-col justify-center  relative p-4 ">
        <div className="mt-6">
          <div>
            <form
              className="w-full divide-neutral-200 rounded-3xl bg-white shadow-2xl border p-8 lg:p-10"
              onSubmit={handleSubmit}
            >
              <div className="py-2 space-y-3">
                <label
                  htmlFor="login_email"
                  className="block text-sm text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="login_email"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                  className="block w-full h-12 px-4 py-3 placeholder-gray-500 bg-gray-100 border-0 rounded-lg appearance-none text-primary focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 focus:ring-inset focus:ring-2 font-bold"
                  placeholder="Enter your email"
                  required
                />
                {!loginEmail && (
                  <p className="text-red-500 text-xs mt-1">Email is required</p>
                )}
              </div>
              <div className="py-2 space-y-3">
                <label
                  htmlFor="login_password"
                  className="block text-sm text-gray-700"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="login_password"
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                    className="block w-full h-12 px-4 py-3 placeholder-gray-500 bg-gray-100 border-0 rounded-lg appearance-none text-primary focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 focus:ring-2 focus:ring-inset font-bold"
                    placeholder="Enter your password"
                    required
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-4 text-xs text-gray-400 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </span>
                </div>
                <div className="flex justify-between">
                  <Link
                    to={`/`}
                    className="text-gray-500 text-xs mt-1 lg:text-pretty"
                  >
                    Forgot Password?{" "}
                    <span className="underline underline-offset-4 text-blue-600 font-semibold">
                      Reset
                    </span>
                  </Link>
                </div>
              </div>
              <div className="mt-4 max-w-fit">
                <ReCAPTCHAComponent onChange={handleCaptchaChange} />
              </div>

              <button
                type="submit"
                className={`rounded-full  px-8 py-2 h-12 mt-3 text-sm font-semibold text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 w-full ${
                  isCaptchaValid
                    ? "bg-blue-600   hover:bg-blue-500"
                    : " bg-blue-400"
                }`}
                disabled={!isCaptchaValid}
              >
                {!!loading ? (
                  // <div>
                     <div class="loader"></div> 
                  // </div>
                ) : (
                  "Login"
                )}
              </button>
            </form>
            {/* {toast && (
              <Toast
                message={toast.message}
                type={toast.type}
                onClose={() => setToast(null)}
              />
            )} */}
          </div>
        </div>
      </div>
      <ToastContainer    position="top-right"
      autoClose={3000}
      limit={2}
      hideProgressBar={true}
      newestOnTop={true}/>
    </section>
  );
};

export default Login;
