import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import Complaints from '../components/Complaints'
import ComplaintsMobile from '../components/ComplaintsMobile'
import Warning from '../components/Warning'

const ComplaintsPage = () => {
  return (
    <>
    <section className="hidden lg:block">
    <Navbar />
    <Warning/>

  </section>
  <section className="block lg:hidden">
    <Sidebar />
  </section>
  <section className="hidden lg:block">
    <Complaints />
  </section>
  <section className="block lg:hidden">
    <ComplaintsMobile />
  </section>
  <Footer />

    </>
  )
}

export default ComplaintsPage