import { useLocation, useNavigate, useParams } from "react-router-dom";
import ServicesCard from "./ServicesCard";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useEffect } from "react";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const serviceDetails = [
    {
      heading: "ERM Holding Pack",
      id: "EHP",
      description:
        "A product designed for short term investors who are looking for a passive research report. It follows the Classic Value Investing Strategy. Value investing is the process of doing detective work to find these secret sales on stocks and buying them at a discount compared to how the market values them. In return for buying and holding these value stocks for the long term, thereby delivering meaningful results over the long run.",
      src: "",
      prices: [
        { monthly: null },
        { quarterly: null },
        { halfYearly: null },
        { yearly: "25000" },
      ],
      features: [
        "Positional Research report in Equity Cash Segment",
        "5-6 Stocks WITH MISPRICED OPPORTUNITY BASED ON VALUE INVESTING.",
        "Holding period can be 6 months to 12 months.",
        "Follow up on Quarterly updates via communication channels.",
        "Our team periodically monitors these stocks given and applied changes wherever required. (Early entry or exit can be possible depending on market and pricing scenario).",
      ],
      investmentRules: [
        "Always invest with equal amount of investment. Don't put all your eggs in one basket.",
        "Don't average in any stock because it will double your risk. Follow proper risk management.",
        "Don't be emotional or greedy while trading. Always take profit home whenever you see good profit and don't panic in case of reverse situation because ups and downs are part of market. Hold your position with proper stop loss.",
        "Don't force yourself to be a trader for doing more number of trades because it may spoil quality of trades. We always wait for proper indication to trade and we trade in stable market.",
        "Always take risk of your spare capital only. Don't take loan or borrow money from anyone and trade in stock market.",
        "This is not a primary source of income. Stock market trading or investments are subject to market risk, so don't depend on this earning.",
      ],
      callPattern: null,sample:""
    },
    {
      heading: "ERM WEALTH",
      id: "EW",
      description:
        "A product designed for medium to long term investors who are looking for a strategy which can beat index returns over time.\nWhile our expert analysts dive into the fundamentals and technical analytics to find the hidden gems in the market.",
      src: "",
      prices: [
        { monthly: null },
        { quarterly: "31000" },
        { halfYearly: null },
        { yearly: "70000" },
      ],
      features: [
        "Invest in a highly personalized and concentrated Stock basket of 20-25 stocks.",
        "Combination of mid-caps and large caps Stocks.",
        "Stock selection done on Strong past track record for a combination of short term, mid term and long term which can be for 3-5 Years.",
        "Follow up on Quarterly updates via communication channels.",
        "Experts periodically monitor these stocks given and change applied wherever required. (Early entry or exit can be possible depending on market and pricing scenario).",
      ],
      investmentRules: [],
      callPattern: null,
      sample:""
    },
    {
      heading: "ERM SWING PACK",
      id: "ESP",
      description:
        "A product dedicated for traders who intend to grab potential trades from Cash market with swing movement within 2-3 days holding. Traders employ technical analysis to determine when conditions are right to enter either long or short, and then to exit. Short term investors who are looking for an opportunity in a volatile market. It follows the momentum stocks which can give movement by holding good stocks in cash segment.",
      src: "",
      prices: [
        { monthly: null },
        { quarterly: "21000" },
        { halfYearly: "35000" },
        { yearly: null },
      ],
      features: [
        "Only Cash Segment Intraday recommendations in Stocks.",
        "Total Recommendations – Weekly 2-3.",
        "Service Mode- WHATSAPP/SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
      callPattern: [
        "BUY POWERGRID CASH Above 260 WITH SL OF 250 TGT 280-290",
        " The securities quoted are for illustration only and are not recommendatory.",
      ],
      sample:""
    },
    {
      heading: "ERM Equity & Derivative Combo",
      id: "EEDC",
      description:
        "A product designed for traders who wish to trade with a combination of Equity and Derivative Segment . ERM Equity Derivative Combo is an exclusive product for traders who want to trade in the whole equity segment.",
      src: "",
      prices: [
        { monthly: "14000" },
        { quarterly: "34000" },
        { halfYearly: "49999" },
        { yearly: null },
      ],
      features: [
        "Intraday Equity trading, Recommendations in Equity Cash, Futures, and Options.",
        "Designed to provide personalized 1-3 Equity recommendations on a daily basis.",
        "Purely intraday calls.",
        "Total Recommendations – 1-3 Daily.",
        "Service Mode- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
      callPattern: [
        "BUY NIFTY 21700 1ST FEB CE ABOVE 80 SL 40 TGT 130-180 CMP 80 9.28AM",
        "BUY HINDPETRO 470 CE MARCH ABOVE 8 CMP 8.10 10.42AM TGT 12-15 SL 5 MARKET LOT 2700 INVEST REQ 23000",
        "The securities quoted are for illustration only and are not recommendatory.",
      ],
      sample:""
    },
    {
      heading: "ERM Stock OPTION PACK",
      id: "ESOP",
      description:
        "A product dedicated for traders who wish to trade only in Stock Options derivative segment. Options trading tips rely heavily on Options Greeks i.e. Delta, Gamma, Vega, & Theta which are integral to derivative trading. Call & Put tips are aspects of Bullish & Bearish market which helps rise in premium price of Option.",
      src: "",
      prices: [
        { monthly: "12999" },
        { quarterly: "33000" },
        { halfYearly: "59000" },
        { yearly: null },
      ],
      features: [
        "Only Options Buying recommendations Intraday/Positional recommendations in Stocks Options.",
        "Traders who trade in this capacity are generally classified as speculators.",
        "Total Recommendations – 1-2 Daily.",
        "Service Mode- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
      callPattern: [
        "BUY TITAN 3600 AUGUST CE ABOVE 60 SL 50 TGT 70-80 LOT SIZE 175",
        "The securities quoted are for illustration only and are not recommendatory",
      ],sample:""
    },
    {
      heading: "ERM Index (Future & Option)",
      id: "EIFO",
      description:
        "A product dedicated for traders who wish to trade only on Bank Nifty and Nifty Future and Options Weekly/Monthly Expiry special calls. Options trading tips rely heavily on Options Greeks i.e. Delta, Gamma, Vega, & Theta which are integral to derivative trading. Call & Put tips are aspects of Bullish & Bearish market which helps rise in premium price of Option.",
      src: "",
      prices: [
        { monthly: null },
        { quarterly: "35000" },
        { halfYearly: "61000" },
        { yearly: null },
      ],
      features: [
        "Recommendations – 1-3 daily.",
        "Service Mode- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
      callPattern: [
        "* BUY BANKNIFTY 26 MAY 36800 PE @455-460 TARGET 600 SL 300",
      ],sample:""
    },
    {
      heading: "ERM COMMODITY (Base Metal & Energy)",
      id: "ECBME",
      description:
        "A product for traders who aim to trade in the Commodity market. Intraday based recommendation in MCX commodity in the segment of Base Metals and Natural Gas with occasional holding. Ideal for clients who want to trade in the MCX Segment.",
      src: "",
      prices: [
        { monthly: "9000" },
        { quarterly: "21000" },
        { halfYearly: "35000" },
        { yearly: null },
      ],
      features: [
        "Intraday based recommendation in MCX commodity in the segment of Base Metals, Natural gas with occasional holding.",
        "Intra-day/Short-term Traders in Commodity Market.",
        "Ideal for clients who want to trade in MCX Segment.",
        "Designed by keeping active traders in mind; it provides you 20-22 recommendations in month.",
        "Recommendation Frequency- 20-22 monthly.",
        "Mode of recommendation- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
      callPattern: null,
      sample:
        "BUY 1 LOT OF ZINC MCX JAN FUTS IN THE RANGE OF 212.50-212.20 WITH THE SL OF 211.80 TGT 213.20",
    },
    {
      heading: "ERM Full MCX Pack",
      id: "EFMP",
      description:
        "A product designed for traders who aim to grab the opportunities from the Commodity market. Intraday based recommendation in MCX commodity in the segment of Precious metals, Base Metals, and Natural Gas with occasional holding. Ideal for clients who want to trade in MCX Commodities.",
      src: "",
      prices: [
        { monthly: "15000" },
        { quarterly: "40000" },
        { halfYearly: "70000" },
        { yearly: null },
      ],
      features: [
        "What: Intraday based recommendation in MCX commodity in the segment of Precious metals, Base Metals, Natural Gas with occasional holding.",
        "Intra-day/Short-term Traders in Commodity Market",
        "Ideal for clients who want to trade in MCX Commodities.",
        "Product Description: Designed by keeping active traders in mind; it provides you 1-2 recommendations daily",
        "Recommendation Frequency- 1-2 Trades Daily.",
        "Mode of recommendation- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
      callPattern: null,
      sample:"BUY SILVER C MCX JAN FUTS IN THE RANGE OF 64000 WITH THE SL OF 211.80 TGT 213.20"
    },
    {
      heading: "ERM INDEX Option PACK",
      id: "EIOP",
      description:
        "A product dedicated for traders who wish to trade only on Bank Nifty, Nifty, Finnifty, Weekly and Monthly Expiry special calls. Only Options Buying recommendations. Intraday recommendations in Index Options.",
      src: "",
      prices: [
        { monthly: "9000" },
        { quarterly: "22000" },
        { halfYearly: "35000" },
        { yearly: null },
      ],
      features: [
        "Only Options Buying recommendations.",
        "Intraday recommendations in Index Options.",
        "Total – 1-2 recommendations.",
        "Service Mode- SMS.",
        "Follow-Up- Yes.",
      ],
      investmentRules: [],
      callPattern: [
        "BUY BANKNIFTY 26 MAY 46800 PE ABOVE 500 TARGET 560-620 SL 440",
        "The securities quoted are for illustration only and are not recommendatory."
      ],
      sample:""
    },
  ];
  const navigate = useNavigate();
  const { serviceId } = useParams();

  if (serviceId) {
    const service = serviceDetails.find((service) => service.id === serviceId);
    //console.log(service);
    return (
      <>
        <div className="min-h-screen grid sm:items-center  services relative services my-5 md:my-16 max-w-6xl mx-auto space-y-5 text-black p-6 md:0 bg-white">
          <h1 className="font-bold">{service.heading}</h1>
          <div className="grid md:grid-cols-2 place-content-center place-items-stretch gap-2 md:pl-10">
            <p className=" break-words whitespace-break-spaces text-pretty w-full">
              {service.description}
            </p>
            {/* <p>{service.description.split(".")[1]}</p> */}
            <table className="table-fixed w-full border-2  ">
              {/* {service.prices.map((price, i) => (
              <> */}
              <tr>
                <th>Monthly</th>
                <td>
                  {service.prices[0].monthly ? service.prices[0].monthly : "-"}
                </td>
                <td>
                  {service.prices[0].monthly ? (
                    <button
                      onClick={() =>
                        navigate(
                          `/equity-package-payment?service=${service.heading}&price=${service.prices[0].monthly}`
                        )
                      }
                      className="border rounded px-2 py-1 hover:bg-primary/20 hover:text-slate-600 bg-accent"
                    >
                      Buy Now
                    </button>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <th>Quarterly</th>
                <td>
                  {service.prices[1].quarterly
                    ? service.prices[1].quarterly
                    : "-"}
                </td>
                <td>
                  {service.prices[1].quarterly ? (
                    <button
                      onClick={() =>
                        navigate(
                          `/equity-package-payment?service=${service.heading}&price=${service.prices[1].quarterly}`
                        )
                      }
                      className="border rounded px-2 py-1 hover:bg-primary/20 hover:text-slate-600 bg-accent"
                    >
                      Buy Now
                    </button>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <th>Half-Yearly</th>
                <td>
                  {service.prices[2].halfYearly
                    ? service.prices[2].halfYearly
                    : "-"}
                </td>
                <td>
                  {service.prices[2].halfYearly ? (
                    <button
                      onClick={() =>
                        navigate(
                          `/equity-package-payment?service=${service.heading}&price=${service.prices[2].halfYearly}`
                        )
                      }
                      className="border rounded px-2 py-1 hover:bg-primary/20 hover:text-slate-600 bg-accent"
                    >
                      Buy Now
                    </button>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <th>Yearly</th>
                <td>
                  {service.prices[3].yearly ? service.prices[3].yearly : "-"}
                </td>
                <td>
                  {service.prices[3].yearly ? (
                    <button
                      onClick={() =>
                        navigate(
                          `/equity-package-payment?service=${service.heading}&price=${service.prices[3].yearly}`
                        )
                      }
                      className="border rounded px-2 py-1 hover:bg-primary/20 hover:text-slate-600 bg-accent "
                    >
                      Buy Now
                    </button>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              {/* </>

            ))} */}
            </table>
          </div>
          <div>
            <h3 className="text-primary">Features</h3>
            {service.features.map((feat, i) => (
              <p key={i} className="md:pl-5  before:content-['-'] ">
                {feat}
              </p>
            ))}
          </div>
          <div>
            {service?.investmentRules.length !== 0 ? (
              <h3 className="text-accent">Investment Rule</h3>
            ) : (
              ""
            )}
            {service?.investmentRules.map((feat, i) => (
              <p key={i} className="md:pl-5 before:content-['-']">
                {feat}
              </p>
            ))}
          </div>
          <div>
            {service?.callPattern !== null ? <h3 className="text-primary">Call Pattern</h3> : ""}
            {service?.callPattern !== null &&
              service?.callPattern.map((feat, i) => (
                <p key={i} className="md:pl-5 ">
                  {feat}
                </p>
              ))}
          </div>
          <div>
            {service?.sample && <p className=""> <span className="font-bold text-accent">Sample: </span>{service?.sample}</p>}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="min-h-screen grid sm:items-center  services relative services my-5">
      <motion.h1
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl md:text-6xl font-bold text-center overflow-hidden first-letter:text-accent"
      >
        {" "}
        Our Services{" "}
      </motion.h1>
      <div className="w-full  grid grid-cols-1 gap-5 lg:gap-8 px-4 md:grid-cols-2 lg:grid-cols-3  md:max-w-7xl mx-auto  ">
        {serviceDetails.map((service, i) => {
          return (
            <ServicesCard
              key={i}
              heading={service.heading}
              description={service.description}
              serviceId={service.id}
            />
          );
        })}
      </div>
      <p className="text-lg leading-8 font-light text-wrap m-1 md:max-w-[50vw] place-self-center text-left sm:text-center bg-slate-100 p-2 rounded my-10">
      These services are Specially crafted as per expected Market Movement and Your trading needs.
      </p>
      <motion.div className="absolute"></motion.div>
    </div>
  );
};

export default Services;
