import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import Services from '../components/Services'
import Warning from '../components/Warning'

const ServicesPage = () => {
  return (
    <>
    <section className="hidden lg:block">
      <Navbar />
      <Warning/>

    </section>
    <section className="block lg:hidden">
      <Sidebar />
    </section>
    <Services />
    <Footer />
  </>
  )
}

export default ServicesPage