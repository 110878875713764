import React from "react";
import LegalDisclaimer from "../components/Policy Pages/LegalDisclaimer";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Warning from "../components/Warning";

function LegalDisclaimerPage() {
  return (
    <>
      <section className="hidden lg:block">
        <Navbar />
      <Warning/>

      </section>
      <section className="block lg:hidden">
        <Sidebar />
      </section>
      <LegalDisclaimer />
      <Footer/>
    </>
  );
}

export default LegalDisclaimerPage;
