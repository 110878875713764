import React from "react";

const Stepper = ({ steps, currentStep, setStep, touchEnabled = true }) => {
  return (
    <div className="flex  md:flex-wrap max-w-xl mx-auto md:justify-evenly items-around">
      {steps.map((step, index) => (
        <div
          key={index}
          className="flex flex-col items-center   sm:1/4 md:w-1/6 lg:w-1/8 p-2"
        >
          <div
            className={`flex items-center justify-center w-10 h-10 text-center text-white rounded-full  cursor-pointer 
            ${currentStep === index ? "bg-accent" : "bg-primary-light/50"}`}
            onClick={() => {
              touchEnabled && setStep(index);
            }}
          >
            {index + 1}
          </div>
         { <div className={`mt-2 text-xs text-center text-black sm:text-sm md:text-base text-wrap hidden md:flex`}>
            {step}
          </div>}
        </div>
      ))}
    </div>
  );
};
export default Stepper;
