import React, { useEffect, useRef, useState } from "react";
// import apiendpo
const EnquiryPanel = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState(10);
  // const itemsPerPage = 10;

  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await fetch(
          "https://edmcorpservices.edumitram.co.in/Home/GetEnquiry/-1",
          // "https://edmcorpservices.edumitram.co.in/User/userRegistration",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              entity: "equity",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch enquiries");
        }

        const data = await response.json();
        setEnquiries(data);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchEnquiries();
  }, []);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(enquiries.length / itemsPerPage);

  const filteredEnquiries = enquiries.filter((enquiry) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (enquiry.email?.toLowerCase().includes(searchLower) ?? false) ||
      (enquiry.username?.toLowerCase().includes(searchLower) ?? false)
    );
  });
  const excelDownloadRef = useRef(null);

  const currentEnquiries = filteredEnquiries.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  //console.log("current:", itemsPerPage);
  // if (loading) {
  //   return <div className="text-sm">Loading...</div>;
  // }

  // if (error) {
  //   return <div className="text-sm text-red-500">Error: {error}</div>;
  // }
  const handleExcelDownload = async () => {
    if (excelDownloadRef.current) {
      excelDownloadRef.current.click();
    }
  };
  return (
    <>
        <h4 className=" mt-2 text-center font-bold">Enquiries</h4>

      {/* <div className="mb-4 ml-2 mt-10 lg:mt-1 flex justify-end">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by email, organization, or username"
          className="px-4 py-2 border border-gray-300 rounded max-w-md w-full"
        />
        <button className="bg-green-600 py-1 px-4 mx-4 rounded-lg text-white text-sm font-semibold uppercase tracking-[1px]">
          Delete
        </button>
        <a href="" download={"complaints"} ref={excelDownloadRef} hidden></a>
        <button
          className="bg-green-600 py-1 px-4 mx-4 rounded-lg text-white  text-sm font-semibold uppercase tracking-[1px]"
          onClick={handleExcelDownload}
        >
          Download CSV
        </button>
      </div> */}

      <div className=" h-[70vh] xl:h-[80vh] overflow-scroll scollbar p-2 rounded-md">
        <table className="min-w-full  bg-white border border-gray-200">
          <thead className="bg-gray-100 text-md uppercase font-semibold text-gray-600">
            <tr>
              <th className="py-3 px-4 border border-gray-200">Select</th>
              <th className="py-3 px-4 border border-gray-200">Enquiry ID</th>
              <th className="py-3 px-4 border border-gray-200">Name</th>
              <th className="py-3 px-4 border border-gray-200">Mobile</th>
              <th className="py-3 px-4 border border-gray-200">Email</th>
              {/* <th className="py-3 px-4 border border-gray-200">Address</th> */}
              {/* <th className="py-3 px-4 border border-gray-200">Contact Person</th> */}
              <th className="py-3 px-4 border border-gray-200">Subject</th>
              <th className="py-3 px-4 border border-gray-200">Message</th>
              {/* <th className="py-3 px-4 border border-gray-200">Enquiry Types</th> */}
            </tr>
          </thead>
          <tbody className="text-xs text-gray-700">
            {
              !!loading &&   <div className="loader "></div>  
            }
            {currentEnquiries.map((enquiry) => (
              <tr key={enquiry.enquiryid} className="hover:bg-gray-50">
                <td className="py-2 px-4 border border-gray-200 h-max">
                  <input type="checkbox" />
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.enquiryid}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.username}
                </td>

                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.mobile}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.email}
                </td>

                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.subject}
                </td>
                <td className="py-2 px-4 border border-gray-200 h-max">
                  {enquiry.message}
                </td>
              </tr>
            ))}
          </tbody>
        
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`px-3 py-1 mx-1 border rounded ${
            currentPage === 1 ? "bg-gray-200 cursor-not-allowed" : "bg-white"
          }`}
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 mx-1 border rounded ${
            currentPage === totalPages
              ? "bg-gray-200 cursor-not-allowed"
              : "bg-white"
          }`}
        >
          Next
        </button>
        <select onChange={(e) => setitemsPerPage(Number(e.target.value))}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>
    </>
  );
};

export default EnquiryPanel;
