import React from "react";

const RefundPolicy = () => {
  return (
    <div className="w-full bg-white min-h-screen py-10">
      <div className="p-2 px-4 md:p-10 lg:p-2 max-w-7xl  mx-auto w-full grid place-content-center text-left     py-10">
        <h1 className="font-semibold text-center">Refund Policy</h1>
        <p className="">
          <p className="">
            We do not provide free trials in general, and all services are paid
            only. On a client’s specific request, we provide one trade as a
            trial to evaluate our services and support system. We expect that
            the client is satisfied before making the payment, therefore we,
            Equity Research Mart, have no refund policy at all.
          </p>

          <p className="">
            By making the payment for our services, we assume that the client
            has been convinced in the initial communication or has availed of
            the free trial, read the disclosure, and made the payment only after
            being satisfied with the quality of services and support from <strong>Equity Research Mart</strong> .
          </p>

          <p className="">
            We provide our best efforts and are confident about the quality of
            services that we provide. We aim to offer the same level of services
            during the trial trade as well as after that, therefore there is no
            refund policy or cancellation of payment.
          </p>

          <p className="">
            Before deciding to subscribe to our services, please thoroughly
            review our website. Ensure that all your credit cards and passwords
            are secure and not disclosed to anyone. No one can make a payment
            for your account, and your membership is secured.
          </p>
        </p>
      </div>
      <footer />
    </div>
  );
};

export default RefundPolicy;
