import React, { useState } from "react";
import AdminSidebar from "./AdminSidebar";
import Navbar from "../Navbar";
import AdminUserRegistration from "./AdminUserRegistration";
import EnquiryPanel from "./EnquiryPanel";
import KycApproval from "./KycApproval";
import Complaints from "./ComplaintPanel";
import PurchaseApproval from "./PurchaseApproval";
import Customers from "./Customers";
import { Outlet } from "react-router-dom";

const   AdminPanel = () => {
  const [activeTab, setactiveTab] = useState(0);
  return (
    <div className="h-full w-full bg-white">
      <Navbar />
      <section className="flex h-full relative">
        <AdminSidebar setactiveTab={setactiveTab} />
        <section className="w-full   ">
          <Outlet />
        </section>
      </section>
    </div>
  );
};

export default AdminPanel;
