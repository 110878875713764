import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import FormPopup from "./FormPopup";
import support from "../assets/customer.png";

function ContactButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section>
      <motion.button
        className="fixed bottom-12 right-10 z-40 bg-green-600 text-white border-none rounded-full w-14 h-14 flex justify-center items-center cursor-pointer transition-colors duration-300 ease-in-out"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleOpenModal}
      >
        <img src={support} alt="Contact" className="w-10 h-10 text-white" />
      </motion.button>

      <AnimatePresence>
        {isModalOpen && <FormPopup onClose={handleCloseModal} />}
      </AnimatePresence>
    </section>
  );
}

export default ContactButton;
