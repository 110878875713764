import React, { useEffect, useRef, useState } from 'react'
import apiendpoints from "../../apiendpoints.json";
import { Link } from 'react-router-dom';

const Customers = () => {
    const [enquiries, setEnquiries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemsPerPage, setitemsPerPage] = useState(10)

    const [viewDeatils, setviewDeatils] = useState(false)
    const [viewDeatilsData, setviewDeatilsData] = useState({})
    // const itemsPerPage = 10;
  
    useEffect(() => {
      const fetchEnquiries = async () => {
        try {
          const response = await fetch(
            `${apiendpoints.equityUrl}/api/Customer/-1`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
  
          if (!response.ok) {
            throw new Error("Failed to fetch enquiries");
          }
  
          const data = await response.json();
          setEnquiries(data);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message);
          } else {
            setError("An unknown error occurred");
          }
        } finally {
          setLoading(false);
        }
      };
  
      fetchEnquiries();
    }, []);
  
    const handleNextPage = () => {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };
  
    const handlePreviousPage = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
  
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value.toLowerCase());
      setCurrentPage(1);
    };
  
    const totalPages = Math.ceil(enquiries.length / itemsPerPage);
  
    const filteredEnquiries = enquiries.filter((enquiry) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (enquiry.emailId?.toLowerCase().includes(searchLower) ?? false) ||
        (enquiry.firstName?.toLowerCase().includes(searchLower) ?? false)
      );
    });
    const excelDownloadRef= useRef(null) 
  
    const currentEnquiries = filteredEnquiries.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  //console.log("current:",itemsPerPage);
    // if (loading) {
    //   return <div className="text-sm">Loading...</div>;
    // }
  
    // if (error) {
    //   return <div className="text-sm text-red-500">Error: {error}</div>;
    // }
    const handleExcelDownload =async ()=>{
      if(excelDownloadRef.current){
        excelDownloadRef.current.click()
      }
    }
    function formatDate(date) {
      if (!date) return ''; // Return empty string if date is null or undefined
  
      const d = new Date(date); // Create a Date object from the input
      let year = d.getFullYear(); // Get full year
      let month = (d.getMonth() + 1).toString().padStart(2, '0'); // Get month and pad it to 2 digits
      let day = d.getDate().toString().padStart(2, '0'); // Get day and pad it to 2 digits
  
      return `${year}-${month}-${day}`; // Return formatted string
  }
    return (
      <>
  
        {/* <div className="mb-4 ml-2 mt-10 lg:mt-1 flex justify-end">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by email, organization, or username"
            className="px-4 py-2 border border-gray-300 rounded max-w-md w-full"
          />
          <button className="bg-green-600 py-1 px-4 mx-4 rounded-lg text-white text-sm font-semibold uppercase tracking-[1px]">
            Delete
          </button>
          <a href="" download={"complaints"}  ref={excelDownloadRef} hidden></a>
          <button className="bg-green-600 py-1 px-4 mx-4 rounded-lg text-white  text-sm font-semibold uppercase tracking-[1px]" onClick={handleExcelDownload} >
            Download CSV
          </button>
        </div>
   */}
  <h4 className=" mt-2 text-center font-bold">Customers</h4>
        <div className=" h-[70vh] xl:h-[80vh] overflow-y-scroll scollbar  mx-auto p-2 rounded-md mt-5"
        >
       
          <table className="min-w-full  bg-white border border-gray-200 ">
            <thead className="bg-gray-100 text-sm uppercase font-semibold text-gray-600">
              <tr>
                {/* <th className="py-3 px-4 border border-gray-200">Select</th> */}
                <th className="py-3 px-4 border border-gray-200">User ID</th>
                <th className="py-3 px-4 border border-gray-200">Name</th>
                <th className="py-3 px-4 border border-gray-200">Mobile</th>
                <th className="py-3 px-4 border border-gray-200">Email</th>
                <th className="py-3 px-4 border border-gray-200">Pan No</th>
                <th className="py-3 px-4 border border-gray-200">Aadhaar No</th>
                <th className="py-3 px-4 border border-gray-200">Services</th>
               
                <th className="py-3 px-4 border border-gray-200">Details</th>

              </tr>
            </thead>
            <tbody className="text-xs text-gray-700">
            {
              !!loading &&   <div className="loader "></div>  
            }
              {currentEnquiries.map((enquiry) => (
                <tr key={enquiry.id} className="hover:bg-gray-50">
                  {/* <td className="py-2 px-4 border border-gray-200 h-max">
                    <input type="checkbox" />
                  </td> */}
                  <td className="py-2 px-4 border border-gray-200 h-max" style={{wordBreak:"break-word"}}>
                    {enquiry.id}
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max" style={{wordBreak:"break-word"}}>
                    {enquiry.firstName + " "+ enquiry.middleName + " "+ enquiry.lastName}
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max" style={{wordBreak:"break-word"}}>
                    {enquiry.mobileNumber}
                  </td>
  
                  <td className="py-2 px-4 border border-gray-200 h-max" style={{wordBreak:"break-word"}}>
                    {enquiry.emailId}
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max" style={{wordBreak:"break-word"}}>
                    {enquiry.panno}
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max" style={{wordBreak:"break-word"}}>
                    {enquiry.panno}
                  </td>
                  <td className="py-2 px-4 border border-gray-200 h-max" style={{wordBreak:"break-word"}}>
                    {enquiry.servicecount}
                  </td>
                
                  <td className="p-1 border border-gray-200 h-max">
                    <Link to={`/equity-admin-panel/customers/${enquiry.id}`} className="bg-green-600 py-1 px-4 mx-2 rounded-lg text-white  text-xs font-semibold uppercase " >
                    View Details
                    </Link>
                  </td>
                  {/* <td className=" border border-gray-200 h-max">
                  <select
                    className="w-max h-full"
                  >
                    <option value="0">Pending</option>
                    <option value="1">Recevied</option>
                    <option value="2">Approve</option>
                    <option value="2"></option>
                  </select>
                </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        <div className="flex justify-center mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`px-3 py-1 mx-1 border rounded ${
              currentPage === 1 ? "bg-gray-200 cursor-not-allowed" : "bg-white"
            }`}
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 mx-1 border rounded ${
              currentPage === totalPages
                ? "bg-gray-200 cursor-not-allowed"
                : "bg-white"
            }`}
          >
            Next
          </button>
          <select onChange={(e)=>setitemsPerPage(Number(e.target.value))}>
  
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          
        </div>
      </>
    );
  };
export default Customers