

const ServiceAgreement = () => {
  return (
    // <div  className='p-2 px-4 md:p-10 lg:p-2 max-w-7xl  space-y-5 mx-auto  w-full grid place-content-center text-left my-10 bg-white rounded' >
    <div className='bg-white w-full min-h-screen py-10'>

    <div className="p-2 px-4 md:p-6 lg:p-2 max-w-7xl  mx-auto w-full grid place-content-center text-left  py-10">

      <h1 style={{ textAlign: 'center' }}>SERVICE AGREEMENT</h1>

      <p>This Service Agreement ("Agreement") is made on [Date] by and between:</p>

      <div className="party" style={{ marginBottom: '20px' }}>
        <strong>1. [Client's Name]</strong><br />
        Address: [Client's Address]<br />
        (hereinafter referred to as "Client")
      </div>

      <div className="party" style={{ marginBottom: '20px' }}>
        <strong>2. [Research Analyst's Name]</strong><br />
        Address: [Research Analyst's Address]<br />
        Registration No.: [SEBI Registration No.]<br />
        (hereinafter referred to as "Research Analyst")
      </div>

      <p>
        WHEREAS, the Research Analyst is registered with the Securities and Exchange Board of India Registration No. INH000016700
        (SEBI) under the Research Analyst Regulations and is engaged in providing research and analysis
        services pertaining to the share and commodity markets.
      </p>

      <h2 style={{ textAlign: 'center' }}>NOW, THEREFORE</h2>
      <p>
        <span className="text-lg font-semibold text-md">NOW, THEREFORE, </span>In consideration of the mutual covenants contained herein, the parties agree as follows:
      </p>

      <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
        1. Scope of Services
      </div>
      <p>The Research Analyst shall provide the following services to the Client:</p>
      <ul style={{ marginLeft: '20px' }} className="list-disc">
        <li>Research reports on shares and commodities.</li>
        <li>Research recommendations / Calls / Tips will be based on thorough analysis.</li>
        <li>Market updates and forecasts.</li>
        <li>Customized Research services as per Client's requirements.</li>
        <li>Mode of Services will be SMS.</li>
      </ul>

      <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
        2. Fees and Payment Terms
      </div>

      <ul style={{ marginLeft: '20px' }} className="list-disc">
        <li>
          The Client agrees to pay the Research Analyst a fee/Service Charge for the services rendered
          purely and exclusively for research services.
        </li>
        <li>
          Client is aware about that, research analyst only gives research services and collecting the fee only. 
          All the service provided by the research analyst described in Annexure A-1 is understood and in knowledge 
          of client and agreed to avail the services .All the Service description  attached in Annexure A-1 is a part 
          and parcel of this agreement.  
        </li>
        <li>
          All the consideration paid to the Equity Research mart by the client is for the
          purely and exclusively for the Research services only. 
        </li>
        <li>
          Client is well aware that equity research mart is only Research analyst provides 
          research services they are not providing any other services to the clients like Demat/Ac, 
          Portfolio Management, Mutual Fund, Insurance, Fixed Deposit or Any Investment Schemes.
        </li>
        <li>
          Equity research mart appoints the best people in the research team who are experienced and trained. They study the market minutely and then provide the research. 
          We give the research recommendation through a procedure and analysis. Subscribing with us is purely client’s own decision. 
          That is why once client make a decision of joining us we do not have any cancellation, holding or refund policy of research service charges/fees paid by the client to us.  
        </li>
      </ul>

      <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
        3. Other Terms and Conditions of research searvice
      </div>

      <ul style={{ marginLeft: '20px' }} className="list-disc">
        <li>
         Equity research mart does not provide any profit warranty or
         guarantee in any of our Product/ Services offered & Subscribed by the client
        </li>
        <li>
          All the email communication from Equity research mart is done through official mail IDs which are having domain name as www.equityresearchmart.in and message services through server ID as “………………..” only . Please do not accept or communicate on emails or sms which are not from www.equityresearchmart.in domain.
        </li>
        <li>
          Past Performance is not an indicator of future Returns. The entire analyst commentary and track sheet provided on www.equityresearchmart.in is provided for information purposes only.
        </li>
        <li>
          Client understands and acknowledges that there is a high degree of risk involved in trading securities.
        </li>
        <li>
         Past results of any trader published on our website are not an indicator of future returns by that trader, and are not an indicator of future returns which be realized by you, investment in the market is subject to Market Risk. Our clients are extremely significant to us hence in order to serve you better, we require our clients to understand each and every details regarding research analyst.
        </li>
        <li>
          Research services will be deliver to client through SMS and you will receive the sms by sender name "Equity Research Mart" .In case of any changes in sender ID we will communicate to you by email.
        </li>
        <li>Investment in markets is subjected to market risk and is to be borne by client only</li>
        <li>
        	Please be fully informed regarding the risks and cost associated with trading in the financial markets, it is one of the riskiest investment forms possible
        </li>
        <li>
        	(Equity Cash / Futures / Options /Commodity and currency derivatives) trading on margin involve high risk, and not suitable for all investors you may lose the more amount initially invested by you.  
        </li>
        <li>
        	Client understands and agreed that, the fees charged by Equity Research Mart in lieu of services / products provided to me are "fair & reasonable".  Client declares that, all the subscription/service charges  paid to the Equity Research Mart by him exclusively and purely for  Research services only and It is paid from  his/her owned Bank Account / Cards / Wallets / only. No any third party is involved in it and the same I will follow in future transactions. Kindly accept all the payments on utmost good faith.
        </li>
        <li>
        	Client confirms that prior to sign this agreement he is gone through all the services and the charges and read all the terms and conditions, privacy policy and disclaimer along with the details of products/service on the website of www.equityresearchmart.in; and by signing this agreement client confirms that he /she understood them fully and agreed on.
        </li>
        <li>The language of this document is understood to client. All the difficulties and interpretations are translated and verbally communicated to the client and the client understood all of them and agreed on.</li>
      </ul>

      <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
        4. Limitation of Liability
      </div>
      <ul style={{ marginLeft: '20px' }} className="list-disc">
        <li>
        The Research Analyst shall not be liable for any direct, indirect, incidental, or consequential damages
        arising from the Client’s use of the research and recommendations provided.
        </li>
      </ul>

      <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
        5. Governing Law
      </div>
      <ul style={{ marginLeft: '20px' }} className="list-disc">
       <li>This Agreement shall be governed by and construed in accordance with the laws of India.</li> 
      </ul>

      <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
        6. Dispute Resolution
      </div>
      <ul style={{ marginLeft: '20px' }} className="list-disc">
       <li> Any disputes arising out of or in connection with this Agreement shall be resolved through arbitration
        in accordance with the Arbitration and Conciliation Act.</li>
      </ul>

      <div className="section-title" style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
        7. Entire Agreement
      </div>
      <ul style={{ marginLeft: '20px' }} className="list-disc">
        <li>This Agreement constitutes the entire understanding between the parties and supersedes all prior
        discussions, agreements, or understandings.</li>
       <li>the parties hereto have executed this Service Agreement as of the date first above written.</li>
      </ul>

      <div className="signatures">
        <div>
          <strong>Client:</strong><br />
          [Client's Name]<br />
          Date: ________________
        </div>
        <div>
          <strong>Research Analyst:</strong><br />
          [Research Analyst's Name]<br />
          Date: ________________
        </div>
      </div>
    </div>
    </div>

  );
};

export default ServiceAgreement;
