import Navbar from "../components/Navbar";
import Career from "../components/Career";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Warning from "../components/Warning";

function CareersPage() {
  return (
    <>
      <section className="hidden lg:block">
        <Navbar />
      <Warning/>

      </section>
      <section className="block lg:hidden">
        <Sidebar />
      </section>
      <Career />
      <Footer />
    </>
  );
}

export default CareersPage;
