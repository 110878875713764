import React from "react";
import { motion } from "framer-motion";

const OurVisionMission = () => {
  return (
    <section className="bg-gradient-to-b from-[#01295c] via-slate-900 to-[#000000] overflow-hidden min-h-[60vh] ">
      <div className="w-full py-10 px-5  text-white flex flex-col lg:flex-row items-center justify-between space-y-8 lg:space-y-0 lg:space-x-10 max-w-7xl mx-auto">
        {/* Mission Section */}
        <motion.div
          initial={{ x: "-100%", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="flex flex-col items-center lg:items-start text-center lg:text-left space-y-4 max-w-lg"
        >
          <h1 className="text-3xl lg:text-4xl font-bold">
            <span className="text-primary">Our </span> Mission
          </h1>
          <p className="text-lg lg:text-xl leading-relaxed">
            Our mission is to simplify financial markets, offer transparent
            solutions, and help you achieve financial success. By providing
            robust research, personalized service, and cutting-edge technology,
            we aim to be your trusted partner in the journey to financial
            prosperity.
          </p>
        </motion.div>

        {/* Vision Section */}
        <motion.div
          initial={{ x: "100%", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="flex flex-col items-center lg:items-start text-center lg:text-left space-y-4 max-w-lg"
        >
          <h1 className="text-3xl lg:text-4xl font-bold">
            <span className="text-primary">Our </span> Vision
          </h1>
          <p className="text-lg lg:text-xl leading-relaxed">
            Our vision is to prioritize and strengthen customer relationships,
            recognizing their invaluable partnership in driving our work. We
            strive to set new benchmarks in the financial sector through
            practical, efficient, and cost-effective models.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default OurVisionMission;
