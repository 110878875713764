import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const AdminUserRegistration = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setLoginPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  //   const { login, isAuthenticated } = useAuth();
  //   const router = useRouter();
//   const [isCaptchaValid, setIsCaptchaValid] = useState(false);

//   const handleCaptchaChange = (value) => {
//     setIsCaptchaValid(!!value);
//   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      Email: email,
      Password: password,
      Name: name,
      Mobile: mobile,
      isApproval:true,
      entityID:2,
    };

    try {
        const response = await fetch(
          "https://edmcorpservices.edumitram.co.in/User/userRegistration",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          toast.error("Network response was not ok");
        }

    toast.success("Admin Created Succesfully")
    

      setEmail("");
      setLoginPassword("");
      setMobile("");
      setName("");
    } catch (error) {
       toast.error("Could Not Register Admin")
    }
  };

  return (
    <section className="bg-white h-full">
      <h4 className=" mt-2 text-center font-bold">New Admin Registration </h4>
      <div className="w-full mx-auto max-w-2xl flex flex-col justify-center  relative p-4">
        <div className="mt-6">
          <div>
            <form
              className="w-full divide-neutral-200 rounded-3xl bg-white shadow-2xl border p-8 lg:p-6"
              onSubmit={handleSubmit}
            >
                 <div className="py-2 space-y-3">
                <label
                  htmlFor="name"
                  className="block text-sm text-gray-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="block w-full h-12 px-4 py-3 placeholder-gray-500 bg-gray-100 border-0 rounded-lg appearance-none text-blue-500 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 focus:ring-inset focus:ring-2 text-xs"
                  placeholder="Enter your email"
                  required
                />
                {!name && (
                  <p className="text-red-500 text-xs mt-1">Name is required</p>
                )}
              </div>
              <div className="py-2 space-y-3">
                <label
                  htmlFor="mobile"
                  className="block text-sm text-gray-700"
                >
                  Mobile
                </label>
                <input
                  type="text"
                  id="mobile"
                  value={mobile}
                  onChange={(e) => {
                    const regex = /^[0-9]*$/;
                    const value = e.target?.value;
                    if (!regex.test(value)) {
                      toast.error("Please Enter Numbers Only");
                    } else if (value.length <= 10) {
                      setMobile(value); // update form data if valid
                      //console.log(value);
                    } else {
                      toast.error("Please Enter a maximum of 10 digits");
                    }
                  }}
                  className="block w-full h-12 px-4 py-3 placeholder-gray-500 bg-gray-100 border-0 rounded-lg appearance-none text-blue-500 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 focus:ring-inset focus:ring-2 text-xs"
                  placeholder="Enter your email"
                  required
                />
                {!email && (
                  <p className="text-red-500 text-xs mt-1">Email is required</p>
                )}
              </div>
              <div className="py-2 space-y-3">
                <label
                  htmlFor="login_email"
                  className="block text-sm text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="login_email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full h-12 px-4 py-3 placeholder-gray-500 bg-gray-100 border-0 rounded-lg appearance-none text-blue-500 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 focus:ring-inset focus:ring-2 text-xs"
                  placeholder="Enter your email"
                  required
                />
                {!email && (
                  <p className="text-red-500 text-xs mt-1">Email is required</p>
                )}
              </div>
              <div className="py-2 space-y-3">
                <label
                  htmlFor="login_password"
                  className="block text-sm text-gray-700"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="login_password"
                    value={password}
                    onChange={(e) => setLoginPassword(e.target.value)}
                    className="block w-full h-12 px-4 py-3 placeholder-gray-500 bg-gray-100 border-0 rounded-lg appearance-none text-blue-500 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 focus:ring-2 focus:ring-inset text-xs"
                    placeholder="Enter your password"
                    required
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-4 text-xs text-gray-400 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </span>
                </div>

              </div>
              <div className="mt-4">
                {/* <ReCAPTCHAComponent onChange={handleCaptchaChange} /> */}

                <button
                  type="submit"
                  className={`rounded-full  px-8 py-2 h-12 mt-3 text-sm font-semibold text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 w-full ${
                   
                      "bg-blue-600   hover:bg-blue-500"
                     
                  }`}
                >
                  Create Admin
                </button>
              </div>
            </form>
            {/* {toast && (
                  <Toast
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast(null)}
                  />
                )} */}
          </div>
                <ToastContainer   position="top-right"
      autoClose={3000}
      limit={2}
      hideProgressBar={true}
      newestOnTop={true}/>
        </div>
      </div>
    </section>
  );
};

export default AdminUserRegistration;
