import { Link } from "react-router-dom";
import React from "react";
import { motion } from "framer-motion";
import {
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoFacebook,
  IoLogoYoutube,
  IoLogoLinkedin,
} from "react-icons/io5";
// import image1 from "../assets/howwork.png";

function Footer() {
  const emailAddress = "info@equityresearchmart.in";

  const handleEmailClick = (mail) => {
    window.location.href = `mailto:${mail}`;
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className=" relative bg-gradient-to-br from-[#01295c] via-slate-900 to-[#000000] pt-4 py-4 border-t-4 border-[#2db928]">
      {/* Footer */}
      <footer className=" max-w-8xl mx-auto  pt-8">
        <div className="container space-y-4 mx-auto px-4">
          <div className="flex flex-wrap text-left lg:text-left">
            <div className="w-full lg:w-6/12 px-4 space-y-6">
              <h4 className="text-4xl font-bold text-white">
                Keep in <span className="text-accent-light">touch</span>
              </h4>
        
              <p className="text-md mt-0 mb-2 text-sm font-normal text-white">
                Registered Address: Avantika Cinema Road, Bari Dariyapur,
                Jamalpur, Bihar Sharif, Bihar-811214 <br />
                <span
                  onClick={() => handleEmailClick(emailAddress)}
                  className="underline underline-offset-2 cursor-pointer"
                >
                  Email: {emailAddress}
                </span>
                <br />
                <p
                  onClick={() =>
                    handleEmailClick("compliance@equityresearchmart.in")
                  }
                  className="underline underline-offset-2 cursor-pointer mt-2"
                >
                  Compliance Email: compliance@equityresearchmart.in
                </p>
              </p>
              <div className="mt-6 flex space-x-4">
                <span className="hover:text-white text-white hover:shadow-lg hover:shadow-[#FDA512] rounded-full">
                  <IoLogoTwitter />
                </span>
                <span className="text-white hover:shadow-lg hover:shadow-[#FDA512] rounded-full">
                  <IoLogoInstagram />
                </span>
                <span className="text-white hover:shadow-lg hover:shadow-[#FDA512] rounded-full">
                <a href="https://www.facebook.com/profile.php?id=61565023105667&mibextid=ZbWKwL" className="text-white " target="_blank">
                    <IoLogoFacebook />
                  </a>
                </span>
                <span className="text-white hover:shadow-lg hover:shadow-[#FDA512] rounded-full">
                  <IoLogoYoutube />
                </span>
                <span className="text-white hover:shadow-lg hover:shadow-[#FDA512] rounded-full">
                  <IoLogoLinkedin />
                </span>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6 mt-2">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-white tracking-[1px] text-md font-semibold">
                    Useful Links
                  </span>
                  <br />
                  <ul className="space-y-2">
                    {/* 
                   */}
                    <li>
                      <Link to={"/equity-our-process"} onClick={scrollToTop}>
                        <p className="text-white hover:text-blueGray-800 font-normal block md:pb-2 text-sm">
                          Our Process
                        </p>
                      </Link>
                    </li>
                      
                    <li>
                      <Link
                        onClick={scrollToTop}
                        to="/equity-careers"
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm"
                      >
                        Careers
                      </Link>
                    </li>

                    <li>
                      <Link
                        onClick={scrollToTop}
                        to="/equity-kyc"
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm"
                      >
                        KYC
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollToTop}
                        to={"/grievance"}
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm"
                      >
                        {/* <p className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm"> */}
                        Grievance
                        {/* </p> */}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollToTop}
                        to={"/equity-service-agreement"}
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm"
                      >
                        Service Agreement
                      </Link>
                    </li>
                   
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4 mt-5 md:mt-0 ">
                  <span className="block uppercase text-white tracking-[1px] text-md font-semibold ">
                    Other Resources
                  </span>
                  <br />
                  <ul className="space-y-2">
                  <li>
                      <Link to={"/equity-aboutus"} onClick={scrollToTop}>
                        <p className="text-white hover:text-blueGray-800 font-normal block md:pb-2 text-sm">
                          About Us
                        </p>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/equity-policy/termsandcondition`}
                        onClick={scrollToTop}
                      >
                        <p className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm ">
                          Terms &amp; Conditions
                        </p>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/equity-policy/refund-policy"}
                        onClick={scrollToTop}
                      >
                        <p className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm">
                          Refund Policy
                        </p>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/equity-policy/legal-disclaimer"}
                        onClick={scrollToTop}
                      >
                        <p className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm">
                          Legal Disclaimer
                        </p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/equity-process" onClick={scrollToTop}>
                        <p className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm">
                          Contact Us
                        </p>
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollToTop}
                        to={"/equity-complaints"}
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-sm"
                      >
                        Complaint
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className=" opacity-50" />
          <div className="flex flex-wrap justify-center  md:justify-between items-center px-2">
            <div className="text-sm text-white  px-2">
              <Link
                to={`/equity-policy/termsandcondition`}
                onClick={scrollToTop}
                className="text-white text-center"
              >
                Terms & Condition |{" "}
              </Link>
              <Link
                to={`/equity-policy/legal-disclaimer`}
                className="text-white"
                onClick={scrollToTop}
              >
                Legal Disclaimer{" "}
              </Link>
            </div>
            <div className="w-full md:w-4/12 px-2 text-center cursor-pointer text-gray-200 hover:text-gray-800">
              <div className="text-sm text-gray-200 font-semibold py-1">
                Copyright ©{" "}
                <span id="get-current-year text-gray-500">2024</span>
                <a href="" className=" " target="_blank" /> All rights reserved
                by Equity Research Mart.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
